import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const DishUpSlipGroupMenusGetMenus = gql`
    query DishUpSlipGroupMenusGetMenus($dishUpSlipGroupId: Int!) {
  dishUpSlipGroup(
    where: {serial: {_eq: $dishUpSlipGroupId}, shop: {archivedAt: {_is_null: true}}}
  ) {
    dishUpSlipGroupShopMenus(
      where: {menu: {_and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}, shopMenu: {archivedAt: {_is_null: true}}}
    ) {
      id
      dishUpSlipGroupId
      menuId
      _menuId
      menu {
        id
        menuId
        name
        categoryMenus(
          where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
        ) {
          categoryId: _categoryId
          category {
            name
          }
        }
      }
    }
    id: serial
    dishUpSlipGroupId
    name
    shop {
      companyId
      shopId
    }
  }
}
    `;
export const DishUpSlipGroupMenusGetCategories = gql`
    query DishUpSlipGroupMenusGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const DishUpSlipGroupDeleteDishUpSlipGroupMenusMenu = gql`
    mutation DishUpSlipGroupDeleteDishUpSlipGroupMenusMenu($menuId: Int!, $dishUpSlipGroupId: uuid!) {
  delete_dishUpSlipGroupShopMenus(
    where: {_menuId: {_eq: $menuId}, dishUpSlipGroupId: {_eq: $dishUpSlipGroupId}, shopMenu: {archivedAt: {_is_null: true}}}
  ) {
    affected_rows
    returning {
      dishUpSlipGroupId
      menuId: _menuId
    }
  }
}
    `;
export type DishUpSlipGroupMenusGetMenusQueryVariables = Types.Exact<{
  dishUpSlipGroupId: Types.Scalars['Int'];
}>;


export type DishUpSlipGroupMenusGetMenusQuery = (
  { __typename?: 'query_root' }
  & { dishUpSlipGroup: Array<(
    { __typename?: 'dishUpSlipGroup' }
    & Pick<Types.DishUpSlipGroup, 'dishUpSlipGroupId' | 'name'>
    & { id: Types.DishUpSlipGroup['serial'] }
    & { dishUpSlipGroupShopMenus: Array<(
      { __typename?: 'dishUpSlipGroupShopMenus' }
      & Pick<Types.DishUpSlipGroupShopMenus, 'id' | 'dishUpSlipGroupId' | 'menuId' | '_menuId'>
      & { menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'id' | 'menuId' | 'name'>
        & { categoryMenus: Array<(
          { __typename?: 'categoryMenu' }
          & { categoryId: Types.CategoryMenu['_categoryId'] }
          & { category: (
            { __typename?: 'category' }
            & Pick<Types.Category, 'name'>
          ) }
        )> }
      ) }
    )>, shop: (
      { __typename?: 'shop' }
      & Pick<Types.Shop, 'companyId' | 'shopId'>
    ) }
  )> }
);

export type DishUpSlipGroupMenusGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type DishUpSlipGroupMenusGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutationVariables = Types.Exact<{
  menuId: Types.Scalars['Int'];
  dishUpSlipGroupId: Types.Scalars['uuid'];
}>;


export type DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation = (
  { __typename?: 'mutation_root' }
  & { delete_dishUpSlipGroupShopMenus?: Types.Maybe<(
    { __typename?: 'dishUpSlipGroupShopMenus_mutation_response' }
    & Pick<Types.DishUpSlipGroupShopMenusMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'dishUpSlipGroupShopMenus' }
      & Pick<Types.DishUpSlipGroupShopMenus, 'dishUpSlipGroupId'>
      & { menuId: Types.DishUpSlipGroupShopMenus['_menuId'] }
    )> }
  )> }
);


export const DishUpSlipGroupMenusGetMenusDocument = gql`
    query DishUpSlipGroupMenusGetMenus($dishUpSlipGroupId: Int!) {
  dishUpSlipGroup(
    where: {serial: {_eq: $dishUpSlipGroupId}, shop: {archivedAt: {_is_null: true}}}
  ) {
    dishUpSlipGroupShopMenus(
      where: {menu: {_and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}, shopMenu: {archivedAt: {_is_null: true}}}
    ) {
      id
      dishUpSlipGroupId
      menuId
      _menuId
      menu {
        id
        menuId
        name
        categoryMenus(
          where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
        ) {
          categoryId: _categoryId
          category {
            name
          }
        }
      }
    }
    id: serial
    dishUpSlipGroupId
    name
    shop {
      companyId
      shopId
    }
  }
}
    `;

/**
 * __useDishUpSlipGroupMenusGetMenusQuery__
 *
 * To run a query within a React component, call `useDishUpSlipGroupMenusGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useDishUpSlipGroupMenusGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDishUpSlipGroupMenusGetMenusQuery({
 *   variables: {
 *      dishUpSlipGroupId: // value for 'dishUpSlipGroupId'
 *   },
 * });
 */
export function useDishUpSlipGroupMenusGetMenusQuery(baseOptions: Apollo.QueryHookOptions<DishUpSlipGroupMenusGetMenusQuery, DishUpSlipGroupMenusGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DishUpSlipGroupMenusGetMenusQuery, DishUpSlipGroupMenusGetMenusQueryVariables>(DishUpSlipGroupMenusGetMenusDocument, options);
      }
export function useDishUpSlipGroupMenusGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DishUpSlipGroupMenusGetMenusQuery, DishUpSlipGroupMenusGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DishUpSlipGroupMenusGetMenusQuery, DishUpSlipGroupMenusGetMenusQueryVariables>(DishUpSlipGroupMenusGetMenusDocument, options);
        }
export type DishUpSlipGroupMenusGetMenusQueryHookResult = ReturnType<typeof useDishUpSlipGroupMenusGetMenusQuery>;
export type DishUpSlipGroupMenusGetMenusLazyQueryHookResult = ReturnType<typeof useDishUpSlipGroupMenusGetMenusLazyQuery>;
export type DishUpSlipGroupMenusGetMenusQueryResult = Apollo.QueryResult<DishUpSlipGroupMenusGetMenusQuery, DishUpSlipGroupMenusGetMenusQueryVariables>;
export const DishUpSlipGroupMenusGetCategoriesDocument = gql`
    query DishUpSlipGroupMenusGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useDishUpSlipGroupMenusGetCategoriesQuery__
 *
 * To run a query within a React component, call `useDishUpSlipGroupMenusGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDishUpSlipGroupMenusGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDishUpSlipGroupMenusGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useDishUpSlipGroupMenusGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<DishUpSlipGroupMenusGetCategoriesQuery, DishUpSlipGroupMenusGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DishUpSlipGroupMenusGetCategoriesQuery, DishUpSlipGroupMenusGetCategoriesQueryVariables>(DishUpSlipGroupMenusGetCategoriesDocument, options);
      }
export function useDishUpSlipGroupMenusGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DishUpSlipGroupMenusGetCategoriesQuery, DishUpSlipGroupMenusGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DishUpSlipGroupMenusGetCategoriesQuery, DishUpSlipGroupMenusGetCategoriesQueryVariables>(DishUpSlipGroupMenusGetCategoriesDocument, options);
        }
export type DishUpSlipGroupMenusGetCategoriesQueryHookResult = ReturnType<typeof useDishUpSlipGroupMenusGetCategoriesQuery>;
export type DishUpSlipGroupMenusGetCategoriesLazyQueryHookResult = ReturnType<typeof useDishUpSlipGroupMenusGetCategoriesLazyQuery>;
export type DishUpSlipGroupMenusGetCategoriesQueryResult = Apollo.QueryResult<DishUpSlipGroupMenusGetCategoriesQuery, DishUpSlipGroupMenusGetCategoriesQueryVariables>;
export const DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuDocument = gql`
    mutation DishUpSlipGroupDeleteDishUpSlipGroupMenusMenu($menuId: Int!, $dishUpSlipGroupId: uuid!) {
  delete_dishUpSlipGroupShopMenus(
    where: {_menuId: {_eq: $menuId}, dishUpSlipGroupId: {_eq: $dishUpSlipGroupId}, shopMenu: {archivedAt: {_is_null: true}}}
  ) {
    affected_rows
    returning {
      dishUpSlipGroupId
      menuId: _menuId
    }
  }
}
    `;
export type DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutationFn = Apollo.MutationFunction<DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation, DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutationVariables>;

/**
 * __useDishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation__
 *
 * To run a mutation, you first call `useDishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation, { data, loading, error }] = useDishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      dishUpSlipGroupId: // value for 'dishUpSlipGroupId'
 *   },
 * });
 */
export function useDishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation(baseOptions?: Apollo.MutationHookOptions<DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation, DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation, DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutationVariables>(DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuDocument, options);
      }
export type DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutationHookResult = ReturnType<typeof useDishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation>;
export type DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutationResult = Apollo.MutationResult<DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation>;
export type DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutationOptions = Apollo.BaseMutationOptions<DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutation, DishUpSlipGroupDeleteDishUpSlipGroupMenusMenuMutationVariables>;