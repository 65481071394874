import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategory = gql`
    query EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategory($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    name
  }
  adyenPaymentStoreMerchantCategory(
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    shopId
    merchantCategory
  }
  adyenTerminalPaymentShopConfig(where: {shopId: {_eq: $shopId}}, limit: 1) {
    adyenTerminalAssignmentHistories(
      where: {releasedAt: {_is_null: true}}
      order_by: [{assignedAt: desc}]
      limit: 1
    ) {
      terminalId
    }
  }
}
    `;
export const EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategory = gql`
    mutation EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategory($input: AdyenPaymentCreateStoreMerchantCategoryInput!) {
  onlinePaymentRoot {
    createAdyenPaymentStoreMerchantCategory(input: $input) {
      result
    }
  }
}
    `;
export const EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminal = gql`
    mutation EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminal($input: AdyenPaymentAssignTerminalInput!) {
  onlinePaymentRoot {
    assignAdyenPaymentTerminal(input: $input) {
      result
    }
  }
}
    `;
export const EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminal = gql`
    mutation EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminal($input: AdyenPaymentReleaseTerminalInput!) {
  onlinePaymentRoot {
    releaseAdyenPaymentTerminal(input: $input) {
      result
    }
  }
}
    `;
export type EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )>, adyenPaymentStoreMerchantCategory: Array<(
    { __typename?: 'adyenPaymentStoreMerchantCategory' }
    & Pick<Types.AdyenPaymentStoreMerchantCategory, 'shopId' | 'merchantCategory'>
  )>, adyenTerminalPaymentShopConfig: Array<(
    { __typename?: 'adyenTerminalPaymentShopConfig' }
    & { adyenTerminalAssignmentHistories: Array<(
      { __typename?: 'adyenTerminalAssignmentHistory' }
      & Pick<Types.AdyenTerminalAssignmentHistory, 'terminalId'>
    )> }
  )> }
);

export type EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationVariables = Types.Exact<{
  input: Types.AdyenPaymentCreateStoreMerchantCategoryInput;
}>;


export type EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { onlinePaymentRoot?: Types.Maybe<(
    { __typename?: 'onlinePaymentRootMutation' }
    & { createAdyenPaymentStoreMerchantCategory: (
      { __typename?: 'AdyenPaymentCreateStoreMerchantCategoryResult' }
      & Pick<Types.AdyenPaymentCreateStoreMerchantCategoryResult, 'result'>
    ) }
  )> }
);

export type EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutationVariables = Types.Exact<{
  input: Types.AdyenPaymentAssignTerminalInput;
}>;


export type EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation = (
  { __typename?: 'mutation_root' }
  & { onlinePaymentRoot?: Types.Maybe<(
    { __typename?: 'onlinePaymentRootMutation' }
    & { assignAdyenPaymentTerminal: (
      { __typename?: 'AdyenPaymentAssignTerminalResult' }
      & Pick<Types.AdyenPaymentAssignTerminalResult, 'result'>
    ) }
  )> }
);

export type EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutationVariables = Types.Exact<{
  input: Types.AdyenPaymentReleaseTerminalInput;
}>;


export type EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutation = (
  { __typename?: 'mutation_root' }
  & { onlinePaymentRoot?: Types.Maybe<(
    { __typename?: 'onlinePaymentRootMutation' }
    & { releaseAdyenPaymentTerminal: (
      { __typename?: 'AdyenPaymentReleaseTerminalResult' }
      & Pick<Types.AdyenPaymentReleaseTerminalResult, 'result'>
    ) }
  )> }
);


export const EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryDocument = gql`
    query EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategory($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    name
  }
  adyenPaymentStoreMerchantCategory(
    where: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}}
    limit: 1
  ) {
    shopId
    merchantCategory
  }
  adyenTerminalPaymentShopConfig(where: {shopId: {_eq: $shopId}}, limit: 1) {
    adyenTerminalAssignmentHistories(
      where: {releasedAt: {_is_null: true}}
      order_by: [{assignedAt: desc}]
      limit: 1
    ) {
      terminalId
    }
  }
}
    `;

/**
 * __useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery__
 *
 * To run a query within a React component, call `useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery(baseOptions: Apollo.QueryHookOptions<EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery, EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery, EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryVariables>(EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryDocument, options);
      }
export function useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery, EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery, EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryVariables>(EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryDocument, options);
        }
export type EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryHookResult = ReturnType<typeof useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery>;
export type EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryLazyQueryHookResult = ReturnType<typeof useEditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryLazyQuery>;
export type EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryResult = Apollo.QueryResult<EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQuery, EditShopCashlessSettingProviderSettingGetAdyenPaymentStoreMerchantCategoryQueryVariables>;
export const EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryDocument = gql`
    mutation EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategory($input: AdyenPaymentCreateStoreMerchantCategoryInput!) {
  onlinePaymentRoot {
    createAdyenPaymentStoreMerchantCategory(input: $input) {
      result
    }
  }
}
    `;
export type EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationFn = Apollo.MutationFunction<EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation, EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationVariables>;

/**
 * __useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation__
 *
 * To run a mutation, you first call `useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation, { data, loading, error }] = useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation(baseOptions?: Apollo.MutationHookOptions<EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation, EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation, EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationVariables>(EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryDocument, options);
      }
export type EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationHookResult = ReturnType<typeof useEditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation>;
export type EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationResult = Apollo.MutationResult<EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation>;
export type EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationOptions = Apollo.BaseMutationOptions<EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutation, EditShopCashlessSettingProviderSettingCreateAdyenPaymentStoreMerchantCategoryMutationVariables>;
export const EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalDocument = gql`
    mutation EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminal($input: AdyenPaymentAssignTerminalInput!) {
  onlinePaymentRoot {
    assignAdyenPaymentTerminal(input: $input) {
      result
    }
  }
}
    `;
export type EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutationFn = Apollo.MutationFunction<EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation, EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutationVariables>;

/**
 * __useEditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation__
 *
 * To run a mutation, you first call `useEditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation, { data, loading, error }] = useEditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation(baseOptions?: Apollo.MutationHookOptions<EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation, EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation, EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutationVariables>(EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalDocument, options);
      }
export type EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutationHookResult = ReturnType<typeof useEditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation>;
export type EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutationResult = Apollo.MutationResult<EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation>;
export type EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutationOptions = Apollo.BaseMutationOptions<EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutation, EditShopCashlessSettingProviderSettingAssignAdyenPaymentTerminalMutationVariables>;
export const EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalDocument = gql`
    mutation EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminal($input: AdyenPaymentReleaseTerminalInput!) {
  onlinePaymentRoot {
    releaseAdyenPaymentTerminal(input: $input) {
      result
    }
  }
}
    `;
export type EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutationFn = Apollo.MutationFunction<EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutation, EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutationVariables>;

/**
 * __useEditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutation__
 *
 * To run a mutation, you first call `useEditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutation, { data, loading, error }] = useEditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutation(baseOptions?: Apollo.MutationHookOptions<EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutation, EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutation, EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutationVariables>(EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalDocument, options);
      }
export type EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutationHookResult = ReturnType<typeof useEditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutation>;
export type EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutationResult = Apollo.MutationResult<EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutation>;
export type EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutationOptions = Apollo.BaseMutationOptions<EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutation, EditShopCashlessSettingProviderSettingReleaseAdyenPaymentTerminalMutationVariables>;