import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const MessageDeliveryJobGetMessageDeliveryJob = gql`
    query MessageDeliveryJobGetMessageDeliveryJob($id: uuid!) {
  messageDeliveryJob_by_pk(id: $id) {
    messageDeliveryShopJobs(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      errorType
      targetLineMessagingPerShopCount
      succeededLineMessagingPerShopCount
      shop {
        name
      }
      executedAt
    }
    messageDelivery {
      id
      name
      companyId
      isSuspended
      scope
      customerSegmentId
      customerSegment {
        messageDeliveryCustomerSegmentVisitedDayCountCondition {
          messageDeliveryCustomerSegmentNumericCondition {
            lessThanOrEqual
            moreThanOrEqual
            equal
          }
        }
        messageDeliveryCustomerSegmentUnvisitedDayCountCondition {
          messageDeliveryCustomerSegmentNumericCondition {
            lessThanOrEqual
            moreThanOrEqual
            equal
          }
        }
        messageDeliveryCustomerSegmentBusinessOperationHoursConditions {
          businessOperationHourType
        }
        messageDeliveryCustomerSegmentQuestionnaireAnswerCondition {
          messageDeliveryQuestionnaireAnswerStatus
        }
        messageDeliveryCustomerSegmentDayOfWeekConditions {
          messageDeliveryDayOfWeek
        }
        messageDeliveryCustomerSegmentMenuConditions {
          menuId
          moreThanOrEqualQuantity
        }
        messageDeliveryCustomerSegmentShopConditions(
          where: {shop: {archivedAt: {_is_null: true}}}
        ) {
          shopId
        }
        messageDeliveryCustomerSegmentGenderCondition {
          messageDeliveryGender
        }
        messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition {
          messageDeliveryCustomerSegmentNumericCondition {
            lessThanOrEqual
            moreThanOrEqual
            equal
          }
        }
      }
      customerListId
      customerList {
        isFromCsv
        customerListCustomers {
          customer {
            id
            lineProfile {
              name
              profileImage
            }
          }
        }
      }
      isDraft
      deliverAt
      repeatDeliveryTime
      messageDeliveryMessages(order_by: {position: asc}) {
        id
        type
        position
        textTypeMeta {
          id
          text
        }
        imageTypeMeta {
          id
          imageUrl
          url
        }
        couponTypeMeta {
          id
          couponId
        }
        questionnaireTypeMeta {
          id
          questionnaireConfigId
        }
      }
    }
  }
}
    `;
export const MessageDeliveryJobGetCoupons = gql`
    query MessageDeliveryJobGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const MessageDeliveryJobGetQuestionnaireConfigs = gql`
    query MessageDeliveryJobGetQuestionnaireConfigs($companyId: uuid!) {
  questionnaireConfig(where: {companyId: {_eq: $companyId}}) {
    id
    imageUrl
    questionnaire {
      id
      name
    }
    coupon {
      id
      title
    }
  }
}
    `;
export const MessageDeliveryJobGetShopBusinessOperationHourTypes = gql`
    query MessageDeliveryJobGetShopBusinessOperationHourTypes($companyId: uuid!) {
  shopBusinessOperationHour(
    where: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
  ) {
    id
    businessOperationHourType
  }
}
    `;
export const MessageDeliveryJobGetShops = gql`
    query MessageDeliveryJobGetShops($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;
export const MessageDeliveryJobGetMenus = gql`
    query MessageDeliveryJobGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}
    order_by: [{name: asc}, {menuId: asc}]
  ) {
    id
    name
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        name
      }
    }
  }
}
    `;
export type MessageDeliveryJobGetMessageDeliveryJobQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type MessageDeliveryJobGetMessageDeliveryJobQuery = (
  { __typename?: 'query_root' }
  & { messageDeliveryJob_by_pk?: Types.Maybe<(
    { __typename?: 'messageDeliveryJob' }
    & { messageDeliveryShopJobs: Array<(
      { __typename?: 'messageDeliveryShopJob' }
      & Pick<Types.MessageDeliveryShopJob, 'id' | 'errorType' | 'targetLineMessagingPerShopCount' | 'succeededLineMessagingPerShopCount' | 'executedAt'>
      & { shop: (
        { __typename?: 'shop' }
        & Pick<Types.Shop, 'name'>
      ) }
    )>, messageDelivery: (
      { __typename?: 'messageDelivery' }
      & Pick<Types.MessageDelivery, 'id' | 'name' | 'companyId' | 'isSuspended' | 'scope' | 'customerSegmentId' | 'customerListId' | 'isDraft' | 'deliverAt' | 'repeatDeliveryTime'>
      & { customerSegment?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegment' }
        & { messageDeliveryCustomerSegmentVisitedDayCountCondition?: Types.Maybe<(
          { __typename?: 'messageDeliveryCustomerSegmentVisitedDayCountCondition' }
          & { messageDeliveryCustomerSegmentNumericCondition: (
            { __typename?: 'messageDeliveryCustomerSegmentNumericCondition' }
            & Pick<Types.MessageDeliveryCustomerSegmentNumericCondition, 'lessThanOrEqual' | 'moreThanOrEqual' | 'equal'>
          ) }
        )>, messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: Types.Maybe<(
          { __typename?: 'messageDeliveryCustomerSegmentUnvisitedDayCountCondition' }
          & { messageDeliveryCustomerSegmentNumericCondition: (
            { __typename?: 'messageDeliveryCustomerSegmentNumericCondition' }
            & Pick<Types.MessageDeliveryCustomerSegmentNumericCondition, 'lessThanOrEqual' | 'moreThanOrEqual' | 'equal'>
          ) }
        )>, messageDeliveryCustomerSegmentBusinessOperationHoursConditions: Array<(
          { __typename?: 'messageDeliveryCustomerSegmentBusinessOperationHoursCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentBusinessOperationHoursCondition, 'businessOperationHourType'>
        )>, messageDeliveryCustomerSegmentQuestionnaireAnswerCondition?: Types.Maybe<(
          { __typename?: 'messageDeliveryCustomerSegmentQuestionnaireAnswerCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition, 'messageDeliveryQuestionnaireAnswerStatus'>
        )>, messageDeliveryCustomerSegmentDayOfWeekConditions: Array<(
          { __typename?: 'messageDeliveryCustomerSegmentDayOfWeekCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentDayOfWeekCondition, 'messageDeliveryDayOfWeek'>
        )>, messageDeliveryCustomerSegmentMenuConditions: Array<(
          { __typename?: 'messageDeliveryCustomerSegmentMenuCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentMenuCondition, 'menuId' | 'moreThanOrEqualQuantity'>
        )>, messageDeliveryCustomerSegmentShopConditions: Array<(
          { __typename?: 'messageDeliveryCustomerSegmentShopCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentShopCondition, 'shopId'>
        )>, messageDeliveryCustomerSegmentGenderCondition?: Types.Maybe<(
          { __typename?: 'messageDeliveryCustomerSegmentGenderCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentGenderCondition, 'messageDeliveryGender'>
        )>, messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: Types.Maybe<(
          { __typename?: 'messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition' }
          & { messageDeliveryCustomerSegmentNumericCondition: (
            { __typename?: 'messageDeliveryCustomerSegmentNumericCondition' }
            & Pick<Types.MessageDeliveryCustomerSegmentNumericCondition, 'lessThanOrEqual' | 'moreThanOrEqual' | 'equal'>
          ) }
        )> }
      )>, customerList?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerList' }
        & Pick<Types.MessageDeliveryCustomerList, 'isFromCsv'>
        & { customerListCustomers: Array<(
          { __typename?: 'messageDeliveryCustomerListCustomer' }
          & { customer: (
            { __typename?: 'customer' }
            & Pick<Types.Customer, 'id'>
            & { lineProfile?: Types.Maybe<(
              { __typename?: 'lineProfile' }
              & Pick<Types.LineProfile, 'name' | 'profileImage'>
            )> }
          ) }
        )> }
      )>, messageDeliveryMessages: Array<(
        { __typename?: 'messageDeliveryMessage' }
        & Pick<Types.MessageDeliveryMessage, 'id' | 'type' | 'position'>
        & { textTypeMeta?: Types.Maybe<(
          { __typename?: 'messageDeliveryMessageTextTypeMeta' }
          & Pick<Types.MessageDeliveryMessageTextTypeMeta, 'id' | 'text'>
        )>, imageTypeMeta?: Types.Maybe<(
          { __typename?: 'messageDeliveryMessageImageTypeMeta' }
          & Pick<Types.MessageDeliveryMessageImageTypeMeta, 'id' | 'imageUrl' | 'url'>
        )>, couponTypeMeta?: Types.Maybe<(
          { __typename?: 'messageDeliveryMessageCouponTypeMeta' }
          & Pick<Types.MessageDeliveryMessageCouponTypeMeta, 'id' | 'couponId'>
        )>, questionnaireTypeMeta?: Types.Maybe<(
          { __typename?: 'messageDeliveryMessageQuestionnaireTypeMeta' }
          & Pick<Types.MessageDeliveryMessageQuestionnaireTypeMeta, 'id' | 'questionnaireConfigId'>
        )> }
      )> }
    ) }
  )> }
);

export type MessageDeliveryJobGetCouponsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MessageDeliveryJobGetCouponsQuery = (
  { __typename?: 'query_root' }
  & { coupon: Array<(
    { __typename?: 'coupon' }
    & Pick<Types.Coupon, 'id' | 'name'>
  )> }
);

export type MessageDeliveryJobGetQuestionnaireConfigsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MessageDeliveryJobGetQuestionnaireConfigsQuery = (
  { __typename?: 'query_root' }
  & { questionnaireConfig: Array<(
    { __typename?: 'questionnaireConfig' }
    & Pick<Types.QuestionnaireConfig, 'id' | 'imageUrl'>
    & { questionnaire: (
      { __typename?: 'questionnaire' }
      & Pick<Types.Questionnaire, 'id' | 'name'>
    ), coupon?: Types.Maybe<(
      { __typename?: 'coupon' }
      & Pick<Types.Coupon, 'id' | 'title'>
    )> }
  )> }
);

export type MessageDeliveryJobGetShopBusinessOperationHourTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MessageDeliveryJobGetShopBusinessOperationHourTypesQuery = (
  { __typename?: 'query_root' }
  & { shopBusinessOperationHour: Array<(
    { __typename?: 'shopBusinessOperationHour' }
    & Pick<Types.ShopBusinessOperationHour, 'id' | 'businessOperationHourType'>
  )> }
);

export type MessageDeliveryJobGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MessageDeliveryJobGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type MessageDeliveryJobGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type MessageDeliveryJobGetMenusQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'name'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { categoryId: Types.CategoryMenu['_categoryId'] }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'name'>
      ) }
    )> }
  )> }
);


export const MessageDeliveryJobGetMessageDeliveryJobDocument = gql`
    query MessageDeliveryJobGetMessageDeliveryJob($id: uuid!) {
  messageDeliveryJob_by_pk(id: $id) {
    messageDeliveryShopJobs(where: {shop: {archivedAt: {_is_null: true}}}) {
      id
      errorType
      targetLineMessagingPerShopCount
      succeededLineMessagingPerShopCount
      shop {
        name
      }
      executedAt
    }
    messageDelivery {
      id
      name
      companyId
      isSuspended
      scope
      customerSegmentId
      customerSegment {
        messageDeliveryCustomerSegmentVisitedDayCountCondition {
          messageDeliveryCustomerSegmentNumericCondition {
            lessThanOrEqual
            moreThanOrEqual
            equal
          }
        }
        messageDeliveryCustomerSegmentUnvisitedDayCountCondition {
          messageDeliveryCustomerSegmentNumericCondition {
            lessThanOrEqual
            moreThanOrEqual
            equal
          }
        }
        messageDeliveryCustomerSegmentBusinessOperationHoursConditions {
          businessOperationHourType
        }
        messageDeliveryCustomerSegmentQuestionnaireAnswerCondition {
          messageDeliveryQuestionnaireAnswerStatus
        }
        messageDeliveryCustomerSegmentDayOfWeekConditions {
          messageDeliveryDayOfWeek
        }
        messageDeliveryCustomerSegmentMenuConditions {
          menuId
          moreThanOrEqualQuantity
        }
        messageDeliveryCustomerSegmentShopConditions(
          where: {shop: {archivedAt: {_is_null: true}}}
        ) {
          shopId
        }
        messageDeliveryCustomerSegmentGenderCondition {
          messageDeliveryGender
        }
        messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition {
          messageDeliveryCustomerSegmentNumericCondition {
            lessThanOrEqual
            moreThanOrEqual
            equal
          }
        }
      }
      customerListId
      customerList {
        isFromCsv
        customerListCustomers {
          customer {
            id
            lineProfile {
              name
              profileImage
            }
          }
        }
      }
      isDraft
      deliverAt
      repeatDeliveryTime
      messageDeliveryMessages(order_by: {position: asc}) {
        id
        type
        position
        textTypeMeta {
          id
          text
        }
        imageTypeMeta {
          id
          imageUrl
          url
        }
        couponTypeMeta {
          id
          couponId
        }
        questionnaireTypeMeta {
          id
          questionnaireConfigId
        }
      }
    }
  }
}
    `;

/**
 * __useMessageDeliveryJobGetMessageDeliveryJobQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryJobGetMessageDeliveryJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryJobGetMessageDeliveryJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryJobGetMessageDeliveryJobQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMessageDeliveryJobGetMessageDeliveryJobQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryJobGetMessageDeliveryJobQuery, MessageDeliveryJobGetMessageDeliveryJobQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryJobGetMessageDeliveryJobQuery, MessageDeliveryJobGetMessageDeliveryJobQueryVariables>(MessageDeliveryJobGetMessageDeliveryJobDocument, options);
      }
export function useMessageDeliveryJobGetMessageDeliveryJobLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryJobGetMessageDeliveryJobQuery, MessageDeliveryJobGetMessageDeliveryJobQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryJobGetMessageDeliveryJobQuery, MessageDeliveryJobGetMessageDeliveryJobQueryVariables>(MessageDeliveryJobGetMessageDeliveryJobDocument, options);
        }
export type MessageDeliveryJobGetMessageDeliveryJobQueryHookResult = ReturnType<typeof useMessageDeliveryJobGetMessageDeliveryJobQuery>;
export type MessageDeliveryJobGetMessageDeliveryJobLazyQueryHookResult = ReturnType<typeof useMessageDeliveryJobGetMessageDeliveryJobLazyQuery>;
export type MessageDeliveryJobGetMessageDeliveryJobQueryResult = Apollo.QueryResult<MessageDeliveryJobGetMessageDeliveryJobQuery, MessageDeliveryJobGetMessageDeliveryJobQueryVariables>;
export const MessageDeliveryJobGetCouponsDocument = gql`
    query MessageDeliveryJobGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;

/**
 * __useMessageDeliveryJobGetCouponsQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryJobGetCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryJobGetCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryJobGetCouponsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMessageDeliveryJobGetCouponsQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryJobGetCouponsQuery, MessageDeliveryJobGetCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryJobGetCouponsQuery, MessageDeliveryJobGetCouponsQueryVariables>(MessageDeliveryJobGetCouponsDocument, options);
      }
export function useMessageDeliveryJobGetCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryJobGetCouponsQuery, MessageDeliveryJobGetCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryJobGetCouponsQuery, MessageDeliveryJobGetCouponsQueryVariables>(MessageDeliveryJobGetCouponsDocument, options);
        }
export type MessageDeliveryJobGetCouponsQueryHookResult = ReturnType<typeof useMessageDeliveryJobGetCouponsQuery>;
export type MessageDeliveryJobGetCouponsLazyQueryHookResult = ReturnType<typeof useMessageDeliveryJobGetCouponsLazyQuery>;
export type MessageDeliveryJobGetCouponsQueryResult = Apollo.QueryResult<MessageDeliveryJobGetCouponsQuery, MessageDeliveryJobGetCouponsQueryVariables>;
export const MessageDeliveryJobGetQuestionnaireConfigsDocument = gql`
    query MessageDeliveryJobGetQuestionnaireConfigs($companyId: uuid!) {
  questionnaireConfig(where: {companyId: {_eq: $companyId}}) {
    id
    imageUrl
    questionnaire {
      id
      name
    }
    coupon {
      id
      title
    }
  }
}
    `;

/**
 * __useMessageDeliveryJobGetQuestionnaireConfigsQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryJobGetQuestionnaireConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryJobGetQuestionnaireConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryJobGetQuestionnaireConfigsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMessageDeliveryJobGetQuestionnaireConfigsQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryJobGetQuestionnaireConfigsQuery, MessageDeliveryJobGetQuestionnaireConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryJobGetQuestionnaireConfigsQuery, MessageDeliveryJobGetQuestionnaireConfigsQueryVariables>(MessageDeliveryJobGetQuestionnaireConfigsDocument, options);
      }
export function useMessageDeliveryJobGetQuestionnaireConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryJobGetQuestionnaireConfigsQuery, MessageDeliveryJobGetQuestionnaireConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryJobGetQuestionnaireConfigsQuery, MessageDeliveryJobGetQuestionnaireConfigsQueryVariables>(MessageDeliveryJobGetQuestionnaireConfigsDocument, options);
        }
export type MessageDeliveryJobGetQuestionnaireConfigsQueryHookResult = ReturnType<typeof useMessageDeliveryJobGetQuestionnaireConfigsQuery>;
export type MessageDeliveryJobGetQuestionnaireConfigsLazyQueryHookResult = ReturnType<typeof useMessageDeliveryJobGetQuestionnaireConfigsLazyQuery>;
export type MessageDeliveryJobGetQuestionnaireConfigsQueryResult = Apollo.QueryResult<MessageDeliveryJobGetQuestionnaireConfigsQuery, MessageDeliveryJobGetQuestionnaireConfigsQueryVariables>;
export const MessageDeliveryJobGetShopBusinessOperationHourTypesDocument = gql`
    query MessageDeliveryJobGetShopBusinessOperationHourTypes($companyId: uuid!) {
  shopBusinessOperationHour(
    where: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
  ) {
    id
    businessOperationHourType
  }
}
    `;

/**
 * __useMessageDeliveryJobGetShopBusinessOperationHourTypesQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryJobGetShopBusinessOperationHourTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryJobGetShopBusinessOperationHourTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryJobGetShopBusinessOperationHourTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMessageDeliveryJobGetShopBusinessOperationHourTypesQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryJobGetShopBusinessOperationHourTypesQuery, MessageDeliveryJobGetShopBusinessOperationHourTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryJobGetShopBusinessOperationHourTypesQuery, MessageDeliveryJobGetShopBusinessOperationHourTypesQueryVariables>(MessageDeliveryJobGetShopBusinessOperationHourTypesDocument, options);
      }
export function useMessageDeliveryJobGetShopBusinessOperationHourTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryJobGetShopBusinessOperationHourTypesQuery, MessageDeliveryJobGetShopBusinessOperationHourTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryJobGetShopBusinessOperationHourTypesQuery, MessageDeliveryJobGetShopBusinessOperationHourTypesQueryVariables>(MessageDeliveryJobGetShopBusinessOperationHourTypesDocument, options);
        }
export type MessageDeliveryJobGetShopBusinessOperationHourTypesQueryHookResult = ReturnType<typeof useMessageDeliveryJobGetShopBusinessOperationHourTypesQuery>;
export type MessageDeliveryJobGetShopBusinessOperationHourTypesLazyQueryHookResult = ReturnType<typeof useMessageDeliveryJobGetShopBusinessOperationHourTypesLazyQuery>;
export type MessageDeliveryJobGetShopBusinessOperationHourTypesQueryResult = Apollo.QueryResult<MessageDeliveryJobGetShopBusinessOperationHourTypesQuery, MessageDeliveryJobGetShopBusinessOperationHourTypesQueryVariables>;
export const MessageDeliveryJobGetShopsDocument = gql`
    query MessageDeliveryJobGetShops($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;

/**
 * __useMessageDeliveryJobGetShopsQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryJobGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryJobGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryJobGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMessageDeliveryJobGetShopsQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryJobGetShopsQuery, MessageDeliveryJobGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryJobGetShopsQuery, MessageDeliveryJobGetShopsQueryVariables>(MessageDeliveryJobGetShopsDocument, options);
      }
export function useMessageDeliveryJobGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryJobGetShopsQuery, MessageDeliveryJobGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryJobGetShopsQuery, MessageDeliveryJobGetShopsQueryVariables>(MessageDeliveryJobGetShopsDocument, options);
        }
export type MessageDeliveryJobGetShopsQueryHookResult = ReturnType<typeof useMessageDeliveryJobGetShopsQuery>;
export type MessageDeliveryJobGetShopsLazyQueryHookResult = ReturnType<typeof useMessageDeliveryJobGetShopsLazyQuery>;
export type MessageDeliveryJobGetShopsQueryResult = Apollo.QueryResult<MessageDeliveryJobGetShopsQuery, MessageDeliveryJobGetShopsQueryVariables>;
export const MessageDeliveryJobGetMenusDocument = gql`
    query MessageDeliveryJobGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}
    order_by: [{name: asc}, {menuId: asc}]
  ) {
    id
    name
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        name
      }
    }
  }
}
    `;

/**
 * __useMessageDeliveryJobGetMenusQuery__
 *
 * To run a query within a React component, call `useMessageDeliveryJobGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageDeliveryJobGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageDeliveryJobGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useMessageDeliveryJobGetMenusQuery(baseOptions: Apollo.QueryHookOptions<MessageDeliveryJobGetMenusQuery, MessageDeliveryJobGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessageDeliveryJobGetMenusQuery, MessageDeliveryJobGetMenusQueryVariables>(MessageDeliveryJobGetMenusDocument, options);
      }
export function useMessageDeliveryJobGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageDeliveryJobGetMenusQuery, MessageDeliveryJobGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessageDeliveryJobGetMenusQuery, MessageDeliveryJobGetMenusQueryVariables>(MessageDeliveryJobGetMenusDocument, options);
        }
export type MessageDeliveryJobGetMenusQueryHookResult = ReturnType<typeof useMessageDeliveryJobGetMenusQuery>;
export type MessageDeliveryJobGetMenusLazyQueryHookResult = ReturnType<typeof useMessageDeliveryJobGetMenusLazyQuery>;
export type MessageDeliveryJobGetMenusQueryResult = Apollo.QueryResult<MessageDeliveryJobGetMenusQuery, MessageDeliveryJobGetMenusQueryVariables>;