import React, { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncFn } from "react-use";
import styled from "styled-components";
import { Button, UploadFile } from "antd";
import saveAs from "file-saver";
import { CodeCsvError, ValidateMenuCodeResult } from "models/menuCodeCsv";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { FormFileItem } from "components/Form/FormFileItem";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { useDinii } from "hooks/useDinii";
import { UploadMenuCodeCsvCompleteModal } from "pages/MenuCodeCsv/UploadMenuCodeCsvCompleteModal";
import { UploadMenuCodeCsvUploadFailureModal } from "pages/MenuCodeCsv/UploadMenuCodeCsvFailureModal";
import { UploadMenuCodeCsvVerifyModal } from "pages/MenuCodeCsv/UploadMenuCodeCsvVerifyModal";
import { VerifyMenuCodeCsvFailureModal } from "pages/MenuCodeCsv/VerifyMenuCodeCsvFailureModal";

const ContentHeader = styled.h2`
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const MenuCodeCsv = () => {
  const [dinii, getContext] = useDinii();
  const [company] = useCompany();
  const [menuCodeFile, setMenuCodeFile] = useState<UploadFile<any> | null>(null);
  const { biType } = useParams<{ biType: string }>();

  // NOTE: verify
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const closeVerifyModal = useCallback(() => setVerifyModalVisible(false), []);
  const [verificationErrors, setVerificationErrors] = useState<CodeCsvError[]>([]);
  const isVerifyMenuFailureModalVisible = verificationErrors.length > 0;
  const closeVerifyMenuFailureModal = useCallback(() => setVerificationErrors([]), []);
  const [validateMenuCodeResult, setValidateMenuCodeResult] =
    useState<ValidateMenuCodeResult | null>(null);

  const removeUploadedFile = useCallback(() => {
    setMenuCodeFile(null);
    setVerificationErrors([]);
  }, []);

  const [{ loading: verifyingCsv }, verifyCsv] = useAsyncFn(async () => {
    const context = await getContext();
    if (!context || !company || !menuCodeFile?.originFileObj || !biType) return;

    try {
      const formData = new FormData();
      formData.append("menuCode", menuCodeFile.originFileObj);
      formData.append("companyId", company.id);
      formData.append("biType", biType);

      const { data } = await dinii.menuCodeCsv.verify(context, formData);

      if (data.errors && data.errors.length > 0) {
        setVerificationErrors(data.errors);
        return;
      }

      setValidateMenuCodeResult({
        updatingMenus: data.updatingMenus ?? [],
        updatingPlans: data.updatingPlans ?? [],
        updatingChoices: data.updatingChoices ?? [],
        updatingPlanChoices: data.updatingPlanChoices ?? [],
        deletingMenus: data.deletingMenus ?? [],
        deletingChoices: data.deletingChoices ?? [],
        deletingPlans: data.deletingPlans ?? [],
        deletingPlanChoices: data.deletingPlanChoices ?? [],
      });
      setVerifyModalVisible(true);
    } catch (err) {
      message.error("不明なエラーが発生しました");
    }
  }, [getContext, company, dinii.menuCodeCsv, menuCodeFile, biType]);

  // NOTE: download
  const [{ loading: downloadingCsv }, downloadCsv] = useAsyncFn(async () => {
    const context = await getContext();
    if (!context || !company || !biType) return;
    try {
      const { data: csvText } = await dinii.menuCodeCsv.download(company.id, biType, context);
      const blob = new Blob([csvText], { type: "text/csv;charset=utf-8" });

      saveAs(blob, `menu_code.csv`);
    } catch (err) {
      message.error("不明なエラーが発生しました");
    }
  }, [dinii, getContext, company, biType]);

  // NOTE: upload
  const [updateMenuCompleteModalVisible, setUpdateMenuCompleteModalVisible] = useState(false);
  const closeUpdateMenuCompleteModal = useCallback(() => {
    setUpdateMenuCompleteModalVisible(false);
    setMenuCodeFile(null);
    setVerifyModalVisible(false);
    setValidateMenuCodeResult(null);
  }, []);
  const [isUpdateMenuFailureModalVisible, setUpdateMenuFailureModalVisible] = useState(false);
  const showUpdateMenuFailureModal = useCallback(() => setUpdateMenuFailureModalVisible(true), []);
  const closeUpdateMenuFailureModal = useCallback(
    () => setUpdateMenuFailureModalVisible(false),
    [],
  );

  const [{ loading: uploadingCsv }, uploadCsv] = useAsyncFn(async () => {
    const context = await getContext();
    const file = menuCodeFile?.originFileObj;
    if (!context || !company || !file || !biType) return;

    setVerifyModalVisible(false);

    try {
      const formData = new FormData();
      formData.append("menuCode", file);
      formData.append("companyId", company.id);
      formData.append("biType", biType);

      const { data } = await dinii.menuCodeCsv.upload(context, formData);
      if (data.errors && data.errors.length > 0) {
        setVerificationErrors(data.errors);
        return;
      }

      const isUpdateFailedRowsExist = ["menu", "choice", "plan", "planChoice"].some((type) => {
        const resData = data[type];
        if (!resData) return false;
        return resData.some(({ isSucceeded }: { isSucceeded: boolean }) => !isSucceeded);
      });

      if (isUpdateFailedRowsExist) {
        showUpdateMenuFailureModal();
        return;
      }

      setUpdateMenuCompleteModalVisible(true);
    } catch (err) {
      message.error("不明なエラーが発生しました");
    }
  }, [getContext, dinii, company, menuCodeFile, showUpdateMenuFailureModal, biType]);

  const navigate = useNavigate();

  const goToBiMenu = useCallback(() => {
    setUpdateMenuCompleteModalVisible(false);
    navigate(`/${biType}/menu`);
  }, [navigate, biType]);

  return (
    <DashboardLayout title="メニューコード一括編集">
      <PageHeader title="メニューコード一括編集" />
      <p>メニューコードを一括で更新することができます。</p>
      <p>
        手順に従って CSV ファイルをダウンロードし、編集後そのファイルをアップロードしてください。
      </p>
      <Spacer size={16} />

      <FormContent>
        <ContentHeader>1. CSV ファイルをダウンロード</ContentHeader>
        <Spacer size={24} />
        メニューコードの CSV ファイルをダウンロードしてください。
        <Spacer size={24} />
        <ButtonWrapper>
          <Button type="primary" onClick={downloadCsv} disabled={downloadingCsv}>
            ダウンロード
          </Button>
        </ButtonWrapper>
      </FormContent>
      <Spacer size={24} />
      <FormContent>
        <ContentHeader>2. CSV ファイルをアップロード</ContentHeader>
        <Spacer size={24} />
        <FormFileItem
          files={menuCodeFile ? [menuCodeFile] : []}
          acceptedTypes={["text/csv"]}
          targetFileLabel="メニューコードCSV"
          allowMultiple={false}
          onUpload={(files) => setMenuCodeFile(files[0] ?? null)}
          onRemove={removeUploadedFile}
        />
      </FormContent>
      <FormContent>
        <ButtonWrapper>
          <Button
            type="primary"
            onClick={verifyCsv}
            disabled={!menuCodeFile || uploadingCsv}
            loading={verifyingCsv}
          >
            アップロード内容の確認
          </Button>
        </ButtonWrapper>
      </FormContent>
      {verifyModalVisible && (
        <UploadMenuCodeCsvVerifyModal
          companyId={company?.id ?? null}
          updatingMenus={validateMenuCodeResult?.updatingMenus ?? []}
          updatingChoices={validateMenuCodeResult?.updatingChoices ?? []}
          updatingPlans={validateMenuCodeResult?.updatingPlans ?? []}
          updatingPlanChoices={validateMenuCodeResult?.updatingPlanChoices ?? []}
          deletingMenus={validateMenuCodeResult?.deletingMenus ?? []}
          deletingChoices={validateMenuCodeResult?.deletingChoices ?? []}
          deletingPlans={validateMenuCodeResult?.deletingPlans ?? []}
          deletingPlanChoices={validateMenuCodeResult?.deletingPlanChoices ?? []}
          submit={uploadCsv}
          closeModal={closeVerifyModal}
        />
      )}
      <UploadMenuCodeCsvCompleteModal
        visible={updateMenuCompleteModalVisible}
        fileName={menuCodeFile?.name || ""}
        confirmModal={goToBiMenu}
        closeModal={closeUpdateMenuCompleteModal}
      />
      <VerifyMenuCodeCsvFailureModal
        visible={isVerifyMenuFailureModalVisible}
        errors={verificationErrors}
        closeModal={closeVerifyMenuFailureModal}
      />
      <UploadMenuCodeCsvUploadFailureModal
        visible={isUpdateMenuFailureModalVisible}
        closeModal={closeUpdateMenuFailureModal}
      />
    </DashboardLayout>
  );
};
