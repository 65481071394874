import React, { memo } from "react";
import styled, { css } from "styled-components";
import { Card, Divider, Tag } from "antd";

import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";

import { getFormattedMonetaryAmount, groupedTableColorPalette } from "..";

type TableCardProps = {
  tableName: string;
  tableUser: {
    id: string;
    billableTableUserId: string;
    groupColor: (typeof groupedTableColorPalette)[number] | null;
    groupName: string | null;
    numPeople: number;
    stayDuration: number;
    unitPrice: number;
    state: string;
  } | null;
};

const TableCardTitle = styled.div`
  border-radius: 8px 8px 0 0;
  background-color: rgba(0, 0, 0, 0.02);
  padding: 8px 10px;
  font-size: 16px;
  font-weight: bold;
`;

const TableCardContainer = styled.div`
  padding: 12px;
`;

const TableCardContentsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const TableCardFooter = styled.div<{ state: string }>`
  ${({ state }) =>
    css`
      display: flex;
      justify-content: center;
      font-size: 12px;
      padding: 2px;
      border-radius: 0 0 8px 8px;
      color: ${colors.Text.OnColor};
      margin-top: auto;
      ${state === "active"
        ? `background-color: ${colors.BackGround.PrimaryDefault}`
        : `background-color: #F46F46`};
    `}
`;

const LargeText = styled.text`
  font-size: 16px;
  font-weight: 600;
`;

const RegularText = styled.text`
  font-size: 12px;
`;

const StyledCard = styled(Card)`
  min-height: 124px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const TableCard = memo<TableCardProps>(({ tableName, tableUser }) => (
  <StyledCard
    bodyStyle={{ padding: 0, margin: 0, display: "flex", flexDirection: "column", flex: "1" }}
    cover={
      <>
        <TableCardTitle>{tableName}</TableCardTitle>
        <Divider style={{ margin: 0 }} />
      </>
    }
  >
    {tableUser && (
      <TableCardContainer>
        <Spacer size={4} />

        <TableCardContentsContainer>
          <LargeText>{tableUser.numPeople}</LargeText>
          <RegularText>人</RegularText>

          <Spacer width={8} />

          <LargeText>{tableUser.stayDuration}</LargeText>
          <RegularText>分</RegularText>
        </TableCardContentsContainer>

        <Spacer size={4} />

        <TableCardContentsContainer>
          <LargeText>{getFormattedMonetaryAmount(tableUser.unitPrice)}</LargeText>

          <Spacer size={2} />

          <RegularText>/人</RegularText>
        </TableCardContentsContainer>

        <Spacer size={4} />
        <TableCardContentsContainer style={{ display: "flex", justifyContent: "center" }}>
          {tableUser.groupColor && tableUser.groupName ? (
            <Tag
              bordered={false}
              color={tableUser.groupColor}
              style={{ textAlign: "center", width: "100%", color: "black" }}
            >
              {tableUser.groupName}
            </Tag>
          ) : null}
        </TableCardContentsContainer>
      </TableCardContainer>
    )}
    {tableUser && (
      <TableCardFooter key={tableName} state={tableUser.state}>
        {tableUser.state === "active" ? "利用中" : "会計待ち"}
      </TableCardFooter>
    )}
  </StyledCard>
));
