import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const CustomerFields = gql`
    fragment customerFields on customer {
  id
  lineProfile {
    name
    profileImage
  }
}
    `;
export const AddMessageDeliveryGetCoupons = gql`
    query AddMessageDeliveryGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;
export const AddMessageDeliveryGetCustomersByLikeName = gql`
    query AddMessageDeliveryGetCustomersByLikeName($companyId: uuid!, $likeName: String!) {
  customer(
    where: {shopMembers: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}, lineProfile: {name: {_like: $likeName}}}
  ) {
    ...customerFields
  }
}
    ${CustomerFields}`;
export const AddMessageDeliveryGetCustomersByIds = gql`
    query AddMessageDeliveryGetCustomersByIds($companyId: uuid!, $customerIds: [String!]!) {
  customer(
    where: {shopMembers: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}, id: {_in: $customerIds}, lineId: {_is_null: false}}
  ) {
    ...customerFields
  }
}
    ${CustomerFields}`;
export const AddMessageDeliveryGetShops = gql`
    query AddMessageDeliveryGetShops($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;
export const AddMessageDeliveryGetQuestionnaireConfigs = gql`
    query AddMessageDeliveryGetQuestionnaireConfigs($companyId: uuid!) {
  questionnaireConfig(where: {companyId: {_eq: $companyId}}) {
    id
    imageUrl
    questionnaire {
      id
      name
    }
    coupon {
      id
      title
    }
  }
}
    `;
export const AddMessageDeliveryGetShopBusinessOperationHourTypes = gql`
    query AddMessageDeliveryGetShopBusinessOperationHourTypes($companyId: uuid!) {
  shopBusinessOperationHour(
    where: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
  ) {
    id
    businessOperationHourType
  }
}
    `;
export const AddMessageDeliveryGetMenus = gql`
    query AddMessageDeliveryGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}
    order_by: [{name: asc}, {menuId: asc}]
  ) {
    id
    name
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        name
      }
    }
  }
}
    `;
export const AddMessageDeliveryInsertMessageDelivery = gql`
    mutation AddMessageDeliveryInsertMessageDelivery($messageDelivery: CreateMessageDeliveryInput!) {
  dashboardAccountCreateMessageDelivery(input: $messageDelivery)
}
    `;
export const CopyMessageDeliveryGetMessageDelivery = gql`
    query CopyMessageDeliveryGetMessageDelivery($id: uuid!) {
  messageDelivery_by_pk(id: $id) {
    id
    name
    companyId
    isSuspended
    scope
    customerSegmentId
    customerSegment {
      messageDeliveryCustomerSegmentVisitedDayCountCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
      messageDeliveryCustomerSegmentUnvisitedDayCountCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
      messageDeliveryCustomerSegmentBusinessOperationHoursConditions {
        businessOperationHourType
      }
      messageDeliveryCustomerSegmentQuestionnaireAnswerCondition {
        messageDeliveryQuestionnaireAnswerStatus
      }
      messageDeliveryCustomerSegmentDayOfWeekConditions {
        messageDeliveryDayOfWeek
      }
      messageDeliveryCustomerSegmentMenuConditions {
        menuId
        moreThanOrEqualQuantity
      }
      messageDeliveryCustomerSegmentShopConditions(
        where: {shop: {archivedAt: {_is_null: true}}}
      ) {
        shopId
      }
      messageDeliveryCustomerSegmentGenderCondition {
        messageDeliveryGender
      }
      messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
    }
    customerListId
    customerList {
      isFromCsv
      customerListCustomers {
        customer {
          id
          lineProfile {
            name
            profileImage
          }
        }
      }
    }
    isDraft
    deliverAt
    repeatDeliveryTime
    messageDeliveryMessages(order_by: {position: asc}) {
      id
      type
      position
      textTypeMeta {
        id
        text
      }
      imageTypeMeta {
        id
        imageUrl
        url
      }
      couponTypeMeta {
        id
        couponId
      }
      questionnaireTypeMeta {
        id
        questionnaireConfigId
      }
    }
    messageDeliveryJobs(limit: 1) {
      id
    }
  }
}
    `;
export type AddMessageDeliveryGetCouponsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddMessageDeliveryGetCouponsQuery = (
  { __typename?: 'query_root' }
  & { coupon: Array<(
    { __typename?: 'coupon' }
    & Pick<Types.Coupon, 'id' | 'name'>
  )> }
);

export type CustomerFieldsFragment = (
  { __typename?: 'customer' }
  & Pick<Types.Customer, 'id'>
  & { lineProfile?: Types.Maybe<(
    { __typename?: 'lineProfile' }
    & Pick<Types.LineProfile, 'name' | 'profileImage'>
  )> }
);

export type AddMessageDeliveryGetCustomersByLikeNameQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  likeName: Types.Scalars['String'];
}>;


export type AddMessageDeliveryGetCustomersByLikeNameQuery = (
  { __typename?: 'query_root' }
  & { customer: Array<(
    { __typename?: 'customer' }
    & CustomerFieldsFragment
  )> }
);

export type AddMessageDeliveryGetCustomersByIdsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  customerIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type AddMessageDeliveryGetCustomersByIdsQuery = (
  { __typename?: 'query_root' }
  & { customer: Array<(
    { __typename?: 'customer' }
    & CustomerFieldsFragment
  )> }
);

export type AddMessageDeliveryGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddMessageDeliveryGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type AddMessageDeliveryGetQuestionnaireConfigsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddMessageDeliveryGetQuestionnaireConfigsQuery = (
  { __typename?: 'query_root' }
  & { questionnaireConfig: Array<(
    { __typename?: 'questionnaireConfig' }
    & Pick<Types.QuestionnaireConfig, 'id' | 'imageUrl'>
    & { questionnaire: (
      { __typename?: 'questionnaire' }
      & Pick<Types.Questionnaire, 'id' | 'name'>
    ), coupon?: Types.Maybe<(
      { __typename?: 'coupon' }
      & Pick<Types.Coupon, 'id' | 'title'>
    )> }
  )> }
);

export type AddMessageDeliveryGetShopBusinessOperationHourTypesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddMessageDeliveryGetShopBusinessOperationHourTypesQuery = (
  { __typename?: 'query_root' }
  & { shopBusinessOperationHour: Array<(
    { __typename?: 'shopBusinessOperationHour' }
    & Pick<Types.ShopBusinessOperationHour, 'id' | 'businessOperationHourType'>
  )> }
);

export type AddMessageDeliveryGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type AddMessageDeliveryGetMenusQuery = (
  { __typename?: 'query_root' }
  & { menu: Array<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'name'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { categoryId: Types.CategoryMenu['_categoryId'] }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'name'>
      ) }
    )> }
  )> }
);

export type AddMessageDeliveryInsertMessageDeliveryMutationVariables = Types.Exact<{
  messageDelivery: Types.CreateMessageDeliveryInput;
}>;


export type AddMessageDeliveryInsertMessageDeliveryMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'dashboardAccountCreateMessageDelivery'>
);

export type CopyMessageDeliveryGetMessageDeliveryQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type CopyMessageDeliveryGetMessageDeliveryQuery = (
  { __typename?: 'query_root' }
  & { messageDelivery_by_pk?: Types.Maybe<(
    { __typename?: 'messageDelivery' }
    & Pick<Types.MessageDelivery, 'id' | 'name' | 'companyId' | 'isSuspended' | 'scope' | 'customerSegmentId' | 'customerListId' | 'isDraft' | 'deliverAt' | 'repeatDeliveryTime'>
    & { customerSegment?: Types.Maybe<(
      { __typename?: 'messageDeliveryCustomerSegment' }
      & { messageDeliveryCustomerSegmentVisitedDayCountCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentVisitedDayCountCondition' }
        & { messageDeliveryCustomerSegmentNumericCondition: (
          { __typename?: 'messageDeliveryCustomerSegmentNumericCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentNumericCondition, 'lessThanOrEqual' | 'moreThanOrEqual' | 'equal'>
        ) }
      )>, messageDeliveryCustomerSegmentUnvisitedDayCountCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentUnvisitedDayCountCondition' }
        & { messageDeliveryCustomerSegmentNumericCondition: (
          { __typename?: 'messageDeliveryCustomerSegmentNumericCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentNumericCondition, 'lessThanOrEqual' | 'moreThanOrEqual' | 'equal'>
        ) }
      )>, messageDeliveryCustomerSegmentBusinessOperationHoursConditions: Array<(
        { __typename?: 'messageDeliveryCustomerSegmentBusinessOperationHoursCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentBusinessOperationHoursCondition, 'businessOperationHourType'>
      )>, messageDeliveryCustomerSegmentQuestionnaireAnswerCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentQuestionnaireAnswerCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentQuestionnaireAnswerCondition, 'messageDeliveryQuestionnaireAnswerStatus'>
      )>, messageDeliveryCustomerSegmentDayOfWeekConditions: Array<(
        { __typename?: 'messageDeliveryCustomerSegmentDayOfWeekCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentDayOfWeekCondition, 'messageDeliveryDayOfWeek'>
      )>, messageDeliveryCustomerSegmentMenuConditions: Array<(
        { __typename?: 'messageDeliveryCustomerSegmentMenuCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentMenuCondition, 'menuId' | 'moreThanOrEqualQuantity'>
      )>, messageDeliveryCustomerSegmentShopConditions: Array<(
        { __typename?: 'messageDeliveryCustomerSegmentShopCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentShopCondition, 'shopId'>
      )>, messageDeliveryCustomerSegmentGenderCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentGenderCondition' }
        & Pick<Types.MessageDeliveryCustomerSegmentGenderCondition, 'messageDeliveryGender'>
      )>, messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition?: Types.Maybe<(
        { __typename?: 'messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition' }
        & { messageDeliveryCustomerSegmentNumericCondition: (
          { __typename?: 'messageDeliveryCustomerSegmentNumericCondition' }
          & Pick<Types.MessageDeliveryCustomerSegmentNumericCondition, 'lessThanOrEqual' | 'moreThanOrEqual' | 'equal'>
        ) }
      )> }
    )>, customerList?: Types.Maybe<(
      { __typename?: 'messageDeliveryCustomerList' }
      & Pick<Types.MessageDeliveryCustomerList, 'isFromCsv'>
      & { customerListCustomers: Array<(
        { __typename?: 'messageDeliveryCustomerListCustomer' }
        & { customer: (
          { __typename?: 'customer' }
          & Pick<Types.Customer, 'id'>
          & { lineProfile?: Types.Maybe<(
            { __typename?: 'lineProfile' }
            & Pick<Types.LineProfile, 'name' | 'profileImage'>
          )> }
        ) }
      )> }
    )>, messageDeliveryMessages: Array<(
      { __typename?: 'messageDeliveryMessage' }
      & Pick<Types.MessageDeliveryMessage, 'id' | 'type' | 'position'>
      & { textTypeMeta?: Types.Maybe<(
        { __typename?: 'messageDeliveryMessageTextTypeMeta' }
        & Pick<Types.MessageDeliveryMessageTextTypeMeta, 'id' | 'text'>
      )>, imageTypeMeta?: Types.Maybe<(
        { __typename?: 'messageDeliveryMessageImageTypeMeta' }
        & Pick<Types.MessageDeliveryMessageImageTypeMeta, 'id' | 'imageUrl' | 'url'>
      )>, couponTypeMeta?: Types.Maybe<(
        { __typename?: 'messageDeliveryMessageCouponTypeMeta' }
        & Pick<Types.MessageDeliveryMessageCouponTypeMeta, 'id' | 'couponId'>
      )>, questionnaireTypeMeta?: Types.Maybe<(
        { __typename?: 'messageDeliveryMessageQuestionnaireTypeMeta' }
        & Pick<Types.MessageDeliveryMessageQuestionnaireTypeMeta, 'id' | 'questionnaireConfigId'>
      )> }
    )>, messageDeliveryJobs: Array<(
      { __typename?: 'messageDeliveryJob' }
      & Pick<Types.MessageDeliveryJob, 'id'>
    )> }
  )> }
);

export const CustomerFieldsFragmentDoc = gql`
    fragment customerFields on customer {
  id
  lineProfile {
    name
    profileImage
  }
}
    `;
export const AddMessageDeliveryGetCouponsDocument = gql`
    query AddMessageDeliveryGetCoupons($companyId: uuid!) {
  coupon(where: {companyId: {_eq: $companyId}}) {
    id
    name
  }
}
    `;

/**
 * __useAddMessageDeliveryGetCouponsQuery__
 *
 * To run a query within a React component, call `useAddMessageDeliveryGetCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMessageDeliveryGetCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMessageDeliveryGetCouponsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddMessageDeliveryGetCouponsQuery(baseOptions: Apollo.QueryHookOptions<AddMessageDeliveryGetCouponsQuery, AddMessageDeliveryGetCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMessageDeliveryGetCouponsQuery, AddMessageDeliveryGetCouponsQueryVariables>(AddMessageDeliveryGetCouponsDocument, options);
      }
export function useAddMessageDeliveryGetCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMessageDeliveryGetCouponsQuery, AddMessageDeliveryGetCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMessageDeliveryGetCouponsQuery, AddMessageDeliveryGetCouponsQueryVariables>(AddMessageDeliveryGetCouponsDocument, options);
        }
export type AddMessageDeliveryGetCouponsQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetCouponsQuery>;
export type AddMessageDeliveryGetCouponsLazyQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetCouponsLazyQuery>;
export type AddMessageDeliveryGetCouponsQueryResult = Apollo.QueryResult<AddMessageDeliveryGetCouponsQuery, AddMessageDeliveryGetCouponsQueryVariables>;
export const AddMessageDeliveryGetCustomersByLikeNameDocument = gql`
    query AddMessageDeliveryGetCustomersByLikeName($companyId: uuid!, $likeName: String!) {
  customer(
    where: {shopMembers: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}, lineProfile: {name: {_like: $likeName}}}
  ) {
    ...customerFields
  }
}
    ${CustomerFieldsFragmentDoc}`;

/**
 * __useAddMessageDeliveryGetCustomersByLikeNameQuery__
 *
 * To run a query within a React component, call `useAddMessageDeliveryGetCustomersByLikeNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMessageDeliveryGetCustomersByLikeNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMessageDeliveryGetCustomersByLikeNameQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      likeName: // value for 'likeName'
 *   },
 * });
 */
export function useAddMessageDeliveryGetCustomersByLikeNameQuery(baseOptions: Apollo.QueryHookOptions<AddMessageDeliveryGetCustomersByLikeNameQuery, AddMessageDeliveryGetCustomersByLikeNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMessageDeliveryGetCustomersByLikeNameQuery, AddMessageDeliveryGetCustomersByLikeNameQueryVariables>(AddMessageDeliveryGetCustomersByLikeNameDocument, options);
      }
export function useAddMessageDeliveryGetCustomersByLikeNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMessageDeliveryGetCustomersByLikeNameQuery, AddMessageDeliveryGetCustomersByLikeNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMessageDeliveryGetCustomersByLikeNameQuery, AddMessageDeliveryGetCustomersByLikeNameQueryVariables>(AddMessageDeliveryGetCustomersByLikeNameDocument, options);
        }
export type AddMessageDeliveryGetCustomersByLikeNameQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetCustomersByLikeNameQuery>;
export type AddMessageDeliveryGetCustomersByLikeNameLazyQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetCustomersByLikeNameLazyQuery>;
export type AddMessageDeliveryGetCustomersByLikeNameQueryResult = Apollo.QueryResult<AddMessageDeliveryGetCustomersByLikeNameQuery, AddMessageDeliveryGetCustomersByLikeNameQueryVariables>;
export const AddMessageDeliveryGetCustomersByIdsDocument = gql`
    query AddMessageDeliveryGetCustomersByIds($companyId: uuid!, $customerIds: [String!]!) {
  customer(
    where: {shopMembers: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}, id: {_in: $customerIds}, lineId: {_is_null: false}}
  ) {
    ...customerFields
  }
}
    ${CustomerFieldsFragmentDoc}`;

/**
 * __useAddMessageDeliveryGetCustomersByIdsQuery__
 *
 * To run a query within a React component, call `useAddMessageDeliveryGetCustomersByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMessageDeliveryGetCustomersByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMessageDeliveryGetCustomersByIdsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      customerIds: // value for 'customerIds'
 *   },
 * });
 */
export function useAddMessageDeliveryGetCustomersByIdsQuery(baseOptions: Apollo.QueryHookOptions<AddMessageDeliveryGetCustomersByIdsQuery, AddMessageDeliveryGetCustomersByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMessageDeliveryGetCustomersByIdsQuery, AddMessageDeliveryGetCustomersByIdsQueryVariables>(AddMessageDeliveryGetCustomersByIdsDocument, options);
      }
export function useAddMessageDeliveryGetCustomersByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMessageDeliveryGetCustomersByIdsQuery, AddMessageDeliveryGetCustomersByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMessageDeliveryGetCustomersByIdsQuery, AddMessageDeliveryGetCustomersByIdsQueryVariables>(AddMessageDeliveryGetCustomersByIdsDocument, options);
        }
export type AddMessageDeliveryGetCustomersByIdsQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetCustomersByIdsQuery>;
export type AddMessageDeliveryGetCustomersByIdsLazyQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetCustomersByIdsLazyQuery>;
export type AddMessageDeliveryGetCustomersByIdsQueryResult = Apollo.QueryResult<AddMessageDeliveryGetCustomersByIdsQuery, AddMessageDeliveryGetCustomersByIdsQueryVariables>;
export const AddMessageDeliveryGetShopsDocument = gql`
    query AddMessageDeliveryGetShops($companyId: uuid!) {
  shop(where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    shopId
    name
  }
}
    `;

/**
 * __useAddMessageDeliveryGetShopsQuery__
 *
 * To run a query within a React component, call `useAddMessageDeliveryGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMessageDeliveryGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMessageDeliveryGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddMessageDeliveryGetShopsQuery(baseOptions: Apollo.QueryHookOptions<AddMessageDeliveryGetShopsQuery, AddMessageDeliveryGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMessageDeliveryGetShopsQuery, AddMessageDeliveryGetShopsQueryVariables>(AddMessageDeliveryGetShopsDocument, options);
      }
export function useAddMessageDeliveryGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMessageDeliveryGetShopsQuery, AddMessageDeliveryGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMessageDeliveryGetShopsQuery, AddMessageDeliveryGetShopsQueryVariables>(AddMessageDeliveryGetShopsDocument, options);
        }
export type AddMessageDeliveryGetShopsQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetShopsQuery>;
export type AddMessageDeliveryGetShopsLazyQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetShopsLazyQuery>;
export type AddMessageDeliveryGetShopsQueryResult = Apollo.QueryResult<AddMessageDeliveryGetShopsQuery, AddMessageDeliveryGetShopsQueryVariables>;
export const AddMessageDeliveryGetQuestionnaireConfigsDocument = gql`
    query AddMessageDeliveryGetQuestionnaireConfigs($companyId: uuid!) {
  questionnaireConfig(where: {companyId: {_eq: $companyId}}) {
    id
    imageUrl
    questionnaire {
      id
      name
    }
    coupon {
      id
      title
    }
  }
}
    `;

/**
 * __useAddMessageDeliveryGetQuestionnaireConfigsQuery__
 *
 * To run a query within a React component, call `useAddMessageDeliveryGetQuestionnaireConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMessageDeliveryGetQuestionnaireConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMessageDeliveryGetQuestionnaireConfigsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddMessageDeliveryGetQuestionnaireConfigsQuery(baseOptions: Apollo.QueryHookOptions<AddMessageDeliveryGetQuestionnaireConfigsQuery, AddMessageDeliveryGetQuestionnaireConfigsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMessageDeliveryGetQuestionnaireConfigsQuery, AddMessageDeliveryGetQuestionnaireConfigsQueryVariables>(AddMessageDeliveryGetQuestionnaireConfigsDocument, options);
      }
export function useAddMessageDeliveryGetQuestionnaireConfigsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMessageDeliveryGetQuestionnaireConfigsQuery, AddMessageDeliveryGetQuestionnaireConfigsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMessageDeliveryGetQuestionnaireConfigsQuery, AddMessageDeliveryGetQuestionnaireConfigsQueryVariables>(AddMessageDeliveryGetQuestionnaireConfigsDocument, options);
        }
export type AddMessageDeliveryGetQuestionnaireConfigsQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetQuestionnaireConfigsQuery>;
export type AddMessageDeliveryGetQuestionnaireConfigsLazyQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetQuestionnaireConfigsLazyQuery>;
export type AddMessageDeliveryGetQuestionnaireConfigsQueryResult = Apollo.QueryResult<AddMessageDeliveryGetQuestionnaireConfigsQuery, AddMessageDeliveryGetQuestionnaireConfigsQueryVariables>;
export const AddMessageDeliveryGetShopBusinessOperationHourTypesDocument = gql`
    query AddMessageDeliveryGetShopBusinessOperationHourTypes($companyId: uuid!) {
  shopBusinessOperationHour(
    where: {shop: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
  ) {
    id
    businessOperationHourType
  }
}
    `;

/**
 * __useAddMessageDeliveryGetShopBusinessOperationHourTypesQuery__
 *
 * To run a query within a React component, call `useAddMessageDeliveryGetShopBusinessOperationHourTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMessageDeliveryGetShopBusinessOperationHourTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMessageDeliveryGetShopBusinessOperationHourTypesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddMessageDeliveryGetShopBusinessOperationHourTypesQuery(baseOptions: Apollo.QueryHookOptions<AddMessageDeliveryGetShopBusinessOperationHourTypesQuery, AddMessageDeliveryGetShopBusinessOperationHourTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMessageDeliveryGetShopBusinessOperationHourTypesQuery, AddMessageDeliveryGetShopBusinessOperationHourTypesQueryVariables>(AddMessageDeliveryGetShopBusinessOperationHourTypesDocument, options);
      }
export function useAddMessageDeliveryGetShopBusinessOperationHourTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMessageDeliveryGetShopBusinessOperationHourTypesQuery, AddMessageDeliveryGetShopBusinessOperationHourTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMessageDeliveryGetShopBusinessOperationHourTypesQuery, AddMessageDeliveryGetShopBusinessOperationHourTypesQueryVariables>(AddMessageDeliveryGetShopBusinessOperationHourTypesDocument, options);
        }
export type AddMessageDeliveryGetShopBusinessOperationHourTypesQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetShopBusinessOperationHourTypesQuery>;
export type AddMessageDeliveryGetShopBusinessOperationHourTypesLazyQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetShopBusinessOperationHourTypesLazyQuery>;
export type AddMessageDeliveryGetShopBusinessOperationHourTypesQueryResult = Apollo.QueryResult<AddMessageDeliveryGetShopBusinessOperationHourTypesQuery, AddMessageDeliveryGetShopBusinessOperationHourTypesQueryVariables>;
export const AddMessageDeliveryGetMenusDocument = gql`
    query AddMessageDeliveryGetMenus($companyId: uuid!) {
  menu(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}
    order_by: [{name: asc}, {menuId: asc}]
  ) {
    id
    name
    categoryMenus(
      where: {_not: {category: {menuRecommendationMeta: {}}}, category: {archivedAt: {_is_null: true}}}
    ) {
      categoryId: _categoryId
      category {
        name
      }
    }
  }
}
    `;

/**
 * __useAddMessageDeliveryGetMenusQuery__
 *
 * To run a query within a React component, call `useAddMessageDeliveryGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddMessageDeliveryGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddMessageDeliveryGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useAddMessageDeliveryGetMenusQuery(baseOptions: Apollo.QueryHookOptions<AddMessageDeliveryGetMenusQuery, AddMessageDeliveryGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddMessageDeliveryGetMenusQuery, AddMessageDeliveryGetMenusQueryVariables>(AddMessageDeliveryGetMenusDocument, options);
      }
export function useAddMessageDeliveryGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddMessageDeliveryGetMenusQuery, AddMessageDeliveryGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddMessageDeliveryGetMenusQuery, AddMessageDeliveryGetMenusQueryVariables>(AddMessageDeliveryGetMenusDocument, options);
        }
export type AddMessageDeliveryGetMenusQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetMenusQuery>;
export type AddMessageDeliveryGetMenusLazyQueryHookResult = ReturnType<typeof useAddMessageDeliveryGetMenusLazyQuery>;
export type AddMessageDeliveryGetMenusQueryResult = Apollo.QueryResult<AddMessageDeliveryGetMenusQuery, AddMessageDeliveryGetMenusQueryVariables>;
export const AddMessageDeliveryInsertMessageDeliveryDocument = gql`
    mutation AddMessageDeliveryInsertMessageDelivery($messageDelivery: CreateMessageDeliveryInput!) {
  dashboardAccountCreateMessageDelivery(input: $messageDelivery)
}
    `;
export type AddMessageDeliveryInsertMessageDeliveryMutationFn = Apollo.MutationFunction<AddMessageDeliveryInsertMessageDeliveryMutation, AddMessageDeliveryInsertMessageDeliveryMutationVariables>;

/**
 * __useAddMessageDeliveryInsertMessageDeliveryMutation__
 *
 * To run a mutation, you first call `useAddMessageDeliveryInsertMessageDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMessageDeliveryInsertMessageDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMessageDeliveryInsertMessageDeliveryMutation, { data, loading, error }] = useAddMessageDeliveryInsertMessageDeliveryMutation({
 *   variables: {
 *      messageDelivery: // value for 'messageDelivery'
 *   },
 * });
 */
export function useAddMessageDeliveryInsertMessageDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<AddMessageDeliveryInsertMessageDeliveryMutation, AddMessageDeliveryInsertMessageDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMessageDeliveryInsertMessageDeliveryMutation, AddMessageDeliveryInsertMessageDeliveryMutationVariables>(AddMessageDeliveryInsertMessageDeliveryDocument, options);
      }
export type AddMessageDeliveryInsertMessageDeliveryMutationHookResult = ReturnType<typeof useAddMessageDeliveryInsertMessageDeliveryMutation>;
export type AddMessageDeliveryInsertMessageDeliveryMutationResult = Apollo.MutationResult<AddMessageDeliveryInsertMessageDeliveryMutation>;
export type AddMessageDeliveryInsertMessageDeliveryMutationOptions = Apollo.BaseMutationOptions<AddMessageDeliveryInsertMessageDeliveryMutation, AddMessageDeliveryInsertMessageDeliveryMutationVariables>;
export const CopyMessageDeliveryGetMessageDeliveryDocument = gql`
    query CopyMessageDeliveryGetMessageDelivery($id: uuid!) {
  messageDelivery_by_pk(id: $id) {
    id
    name
    companyId
    isSuspended
    scope
    customerSegmentId
    customerSegment {
      messageDeliveryCustomerSegmentVisitedDayCountCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
      messageDeliveryCustomerSegmentUnvisitedDayCountCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
      messageDeliveryCustomerSegmentBusinessOperationHoursConditions {
        businessOperationHourType
      }
      messageDeliveryCustomerSegmentQuestionnaireAnswerCondition {
        messageDeliveryQuestionnaireAnswerStatus
      }
      messageDeliveryCustomerSegmentDayOfWeekConditions {
        messageDeliveryDayOfWeek
      }
      messageDeliveryCustomerSegmentMenuConditions {
        menuId
        moreThanOrEqualQuantity
      }
      messageDeliveryCustomerSegmentShopConditions(
        where: {shop: {archivedAt: {_is_null: true}}}
      ) {
        shopId
      }
      messageDeliveryCustomerSegmentGenderCondition {
        messageDeliveryGender
      }
      messageDeliveryCustomerSegmentDaysCountUntilBirthdayCondition {
        messageDeliveryCustomerSegmentNumericCondition {
          lessThanOrEqual
          moreThanOrEqual
          equal
        }
      }
    }
    customerListId
    customerList {
      isFromCsv
      customerListCustomers {
        customer {
          id
          lineProfile {
            name
            profileImage
          }
        }
      }
    }
    isDraft
    deliverAt
    repeatDeliveryTime
    messageDeliveryMessages(order_by: {position: asc}) {
      id
      type
      position
      textTypeMeta {
        id
        text
      }
      imageTypeMeta {
        id
        imageUrl
        url
      }
      couponTypeMeta {
        id
        couponId
      }
      questionnaireTypeMeta {
        id
        questionnaireConfigId
      }
    }
    messageDeliveryJobs(limit: 1) {
      id
    }
  }
}
    `;

/**
 * __useCopyMessageDeliveryGetMessageDeliveryQuery__
 *
 * To run a query within a React component, call `useCopyMessageDeliveryGetMessageDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyMessageDeliveryGetMessageDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyMessageDeliveryGetMessageDeliveryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopyMessageDeliveryGetMessageDeliveryQuery(baseOptions: Apollo.QueryHookOptions<CopyMessageDeliveryGetMessageDeliveryQuery, CopyMessageDeliveryGetMessageDeliveryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopyMessageDeliveryGetMessageDeliveryQuery, CopyMessageDeliveryGetMessageDeliveryQueryVariables>(CopyMessageDeliveryGetMessageDeliveryDocument, options);
      }
export function useCopyMessageDeliveryGetMessageDeliveryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopyMessageDeliveryGetMessageDeliveryQuery, CopyMessageDeliveryGetMessageDeliveryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopyMessageDeliveryGetMessageDeliveryQuery, CopyMessageDeliveryGetMessageDeliveryQueryVariables>(CopyMessageDeliveryGetMessageDeliveryDocument, options);
        }
export type CopyMessageDeliveryGetMessageDeliveryQueryHookResult = ReturnType<typeof useCopyMessageDeliveryGetMessageDeliveryQuery>;
export type CopyMessageDeliveryGetMessageDeliveryLazyQueryHookResult = ReturnType<typeof useCopyMessageDeliveryGetMessageDeliveryLazyQuery>;
export type CopyMessageDeliveryGetMessageDeliveryQueryResult = Apollo.QueryResult<CopyMessageDeliveryGetMessageDeliveryQuery, CopyMessageDeliveryGetMessageDeliveryQueryVariables>;