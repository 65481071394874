import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditMenusBulkGetMenus = gql`
    query EditMenusBulkGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      categoryMenuId
      categoryId: _categoryId
      category {
        categoryId
        name
      }
      displayType
      menu {
        totalDealingShopCount
        shopMenus(
          where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
        ) {
          shop {
            shopId
            name
          }
        }
        menuId
        name
        openPrice
        taxMethod
        taxRate
        costPrice
        costTaxRate
        price
        imageUrl
        menuOptions(
          where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
          order_by: [{priority: asc}, {_optionId: asc}]
        ) {
          option {
            name
            optionId
            choices(
              where: {archivedAt: {_is_null: true}}
              order_by: [{priority: asc}, {choiceId: asc}]
            ) {
              name
              price
              choiceId
            }
          }
        }
      }
    }
  }
}
    `;
export const EditMenusBulkGetCategories = gql`
    query EditMenusBulkGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const EditMenusBulkGetShops = gql`
    query EditMenusBulkGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const EditMenusBulkGetCompany = gql`
    query EditMenusBulkGetCompany($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    companyId
    defaultCostTaxMethod
  }
}
    `;
export const UpdateMenusBulk = gql`
    mutation UpdateMenusBulk($input: UpdateCategoryMenusBulkInput!) {
  updateMenusBulk(input: $input)
}
    `;
export type EditMenusBulkGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMenusBulkGetMenusQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'categoryMenuId' | 'displayType'>
      & { categoryId: Types.CategoryMenu['_categoryId'] }
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name'>
      ), menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'totalDealingShopCount' | 'menuId' | 'name' | 'openPrice' | 'taxMethod' | 'taxRate' | 'costPrice' | 'costTaxRate' | 'price' | 'imageUrl'>
        & { shopMenus: Array<(
          { __typename?: 'shopMenu' }
          & { shop: (
            { __typename?: 'shop' }
            & Pick<Types.Shop, 'shopId' | 'name'>
          ) }
        )>, menuOptions: Array<(
          { __typename?: 'menuOption' }
          & { option: (
            { __typename?: 'option' }
            & Pick<Types.Option, 'name' | 'optionId'>
            & { choices: Array<(
              { __typename?: 'choice' }
              & Pick<Types.Choice, 'name' | 'price' | 'choiceId'>
            )> }
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type EditMenusBulkGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMenusBulkGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type EditMenusBulkGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMenusBulkGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type EditMenusBulkGetCompanyQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type EditMenusBulkGetCompanyQuery = (
  { __typename?: 'query_root' }
  & { company: Array<(
    { __typename?: 'company' }
    & Pick<Types.Company, 'companyId' | 'defaultCostTaxMethod'>
  )> }
);

export type UpdateMenusBulkMutationVariables = Types.Exact<{
  input: Types.UpdateCategoryMenusBulkInput;
}>;


export type UpdateMenusBulkMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'updateMenusBulk'>
);


export const EditMenusBulkGetMenusDocument = gql`
    query EditMenusBulkGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      categoryMenuId
      categoryId: _categoryId
      category {
        categoryId
        name
      }
      displayType
      menu {
        totalDealingShopCount
        shopMenus(
          where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
        ) {
          shop {
            shopId
            name
          }
        }
        menuId
        name
        openPrice
        taxMethod
        taxRate
        costPrice
        costTaxRate
        price
        imageUrl
        menuOptions(
          where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
          order_by: [{priority: asc}, {_optionId: asc}]
        ) {
          option {
            name
            optionId
            choices(
              where: {archivedAt: {_is_null: true}}
              order_by: [{priority: asc}, {choiceId: asc}]
            ) {
              name
              price
              choiceId
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useEditMenusBulkGetMenusQuery__
 *
 * To run a query within a React component, call `useEditMenusBulkGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenusBulkGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenusBulkGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMenusBulkGetMenusQuery(baseOptions: Apollo.QueryHookOptions<EditMenusBulkGetMenusQuery, EditMenusBulkGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenusBulkGetMenusQuery, EditMenusBulkGetMenusQueryVariables>(EditMenusBulkGetMenusDocument, options);
      }
export function useEditMenusBulkGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenusBulkGetMenusQuery, EditMenusBulkGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenusBulkGetMenusQuery, EditMenusBulkGetMenusQueryVariables>(EditMenusBulkGetMenusDocument, options);
        }
export type EditMenusBulkGetMenusQueryHookResult = ReturnType<typeof useEditMenusBulkGetMenusQuery>;
export type EditMenusBulkGetMenusLazyQueryHookResult = ReturnType<typeof useEditMenusBulkGetMenusLazyQuery>;
export type EditMenusBulkGetMenusQueryResult = Apollo.QueryResult<EditMenusBulkGetMenusQuery, EditMenusBulkGetMenusQueryVariables>;
export const EditMenusBulkGetCategoriesDocument = gql`
    query EditMenusBulkGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useEditMenusBulkGetCategoriesQuery__
 *
 * To run a query within a React component, call `useEditMenusBulkGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenusBulkGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenusBulkGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMenusBulkGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<EditMenusBulkGetCategoriesQuery, EditMenusBulkGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenusBulkGetCategoriesQuery, EditMenusBulkGetCategoriesQueryVariables>(EditMenusBulkGetCategoriesDocument, options);
      }
export function useEditMenusBulkGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenusBulkGetCategoriesQuery, EditMenusBulkGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenusBulkGetCategoriesQuery, EditMenusBulkGetCategoriesQueryVariables>(EditMenusBulkGetCategoriesDocument, options);
        }
export type EditMenusBulkGetCategoriesQueryHookResult = ReturnType<typeof useEditMenusBulkGetCategoriesQuery>;
export type EditMenusBulkGetCategoriesLazyQueryHookResult = ReturnType<typeof useEditMenusBulkGetCategoriesLazyQuery>;
export type EditMenusBulkGetCategoriesQueryResult = Apollo.QueryResult<EditMenusBulkGetCategoriesQuery, EditMenusBulkGetCategoriesQueryVariables>;
export const EditMenusBulkGetShopsDocument = gql`
    query EditMenusBulkGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __useEditMenusBulkGetShopsQuery__
 *
 * To run a query within a React component, call `useEditMenusBulkGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenusBulkGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenusBulkGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMenusBulkGetShopsQuery(baseOptions: Apollo.QueryHookOptions<EditMenusBulkGetShopsQuery, EditMenusBulkGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenusBulkGetShopsQuery, EditMenusBulkGetShopsQueryVariables>(EditMenusBulkGetShopsDocument, options);
      }
export function useEditMenusBulkGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenusBulkGetShopsQuery, EditMenusBulkGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenusBulkGetShopsQuery, EditMenusBulkGetShopsQueryVariables>(EditMenusBulkGetShopsDocument, options);
        }
export type EditMenusBulkGetShopsQueryHookResult = ReturnType<typeof useEditMenusBulkGetShopsQuery>;
export type EditMenusBulkGetShopsLazyQueryHookResult = ReturnType<typeof useEditMenusBulkGetShopsLazyQuery>;
export type EditMenusBulkGetShopsQueryResult = Apollo.QueryResult<EditMenusBulkGetShopsQuery, EditMenusBulkGetShopsQueryVariables>;
export const EditMenusBulkGetCompanyDocument = gql`
    query EditMenusBulkGetCompany($companyId: uuid!) {
  company(where: {id: {_eq: $companyId}, archivedAt: {_is_null: true}}) {
    companyId
    defaultCostTaxMethod
  }
}
    `;

/**
 * __useEditMenusBulkGetCompanyQuery__
 *
 * To run a query within a React component, call `useEditMenusBulkGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditMenusBulkGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditMenusBulkGetCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useEditMenusBulkGetCompanyQuery(baseOptions: Apollo.QueryHookOptions<EditMenusBulkGetCompanyQuery, EditMenusBulkGetCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditMenusBulkGetCompanyQuery, EditMenusBulkGetCompanyQueryVariables>(EditMenusBulkGetCompanyDocument, options);
      }
export function useEditMenusBulkGetCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditMenusBulkGetCompanyQuery, EditMenusBulkGetCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditMenusBulkGetCompanyQuery, EditMenusBulkGetCompanyQueryVariables>(EditMenusBulkGetCompanyDocument, options);
        }
export type EditMenusBulkGetCompanyQueryHookResult = ReturnType<typeof useEditMenusBulkGetCompanyQuery>;
export type EditMenusBulkGetCompanyLazyQueryHookResult = ReturnType<typeof useEditMenusBulkGetCompanyLazyQuery>;
export type EditMenusBulkGetCompanyQueryResult = Apollo.QueryResult<EditMenusBulkGetCompanyQuery, EditMenusBulkGetCompanyQueryVariables>;
export const UpdateMenusBulkDocument = gql`
    mutation UpdateMenusBulk($input: UpdateCategoryMenusBulkInput!) {
  updateMenusBulk(input: $input)
}
    `;
export type UpdateMenusBulkMutationFn = Apollo.MutationFunction<UpdateMenusBulkMutation, UpdateMenusBulkMutationVariables>;

/**
 * __useUpdateMenusBulkMutation__
 *
 * To run a mutation, you first call `useUpdateMenusBulkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMenusBulkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMenusBulkMutation, { data, loading, error }] = useUpdateMenusBulkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMenusBulkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMenusBulkMutation, UpdateMenusBulkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMenusBulkMutation, UpdateMenusBulkMutationVariables>(UpdateMenusBulkDocument, options);
      }
export type UpdateMenusBulkMutationHookResult = ReturnType<typeof useUpdateMenusBulkMutation>;
export type UpdateMenusBulkMutationResult = Apollo.MutationResult<UpdateMenusBulkMutation>;
export type UpdateMenusBulkMutationOptions = Apollo.BaseMutationOptions<UpdateMenusBulkMutation, UpdateMenusBulkMutationVariables>;