import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import { Button, Typography } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { FormContent } from "components/Template/FormTemplate";
import { colors } from "constants/colors";

import { AdyenTerminalAssignmentHistory } from "../types";

import { ModelField } from "./ModelField";
import { ReleaseShopCashlessSettingTerminalIdFormConfirmModal } from "./ReleaseShopCashlessSettingTerminalIdFormConfirmModal";
import { SerialNumberField } from "./SerialNumberField";
import {
  AssignShopCashlessSettingTerminalIdFormValues,
  useAssignShopCashlessSettingTerminalIdForm,
} from "./useAssignShopCashlessSettingTerminalIdForm";

type Props = {
  initialAdyenTerminalAssignmentHistory: AdyenTerminalAssignmentHistory | null;
  loading: boolean;
  onSubmit: (values: AssignShopCashlessSettingTerminalIdFormValues) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

const StyledForm = styled(Form)`
  border: 1px solid ${colors.Divider.Default};
  padding: 24px;
`;

export const AssignShopCashlessSettingTerminalIdForm = memo<Props>(
  ({ initialAdyenTerminalAssignmentHistory, loading, onSubmit, onFormValidationError }) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const { form, initialValues, submit } = useAssignShopCashlessSettingTerminalIdForm({
      initialAdyenTerminalAssignmentHistory,
      onSubmit,
      onFormValidationError,
    });

    const handleSubmit = useCallback(async () => {
      if (initialAdyenTerminalAssignmentHistory) {
        setShowConfirm(true);
        return;
      }
      await submit();
    }, [initialAdyenTerminalAssignmentHistory, submit]);

    const onCancel = useCallback(() => {
      setShowConfirm(false);
    }, []);

    const onConfirm = useCallback(async () => {
      await submit();
      setShowConfirm(false);
    }, [submit]);

    return (
      <>
        <FormContent>
          <StyledForm name="shop" form={form} layout="vertical" initialValues={initialValues}>
            <Typography.Title level={3}>TerminalId</Typography.Title>
            <ModelField disabled={Boolean(initialAdyenTerminalAssignmentHistory)} />
            <SerialNumberField disabled={Boolean(initialAdyenTerminalAssignmentHistory)} />
            <FormActions>
              <Button type="primary" onClick={handleSubmit} loading={loading}>
                {initialAdyenTerminalAssignmentHistory ? "解除" : "登録"}
              </Button>
            </FormActions>
          </StyledForm>
        </FormContent>
        <ReleaseShopCashlessSettingTerminalIdFormConfirmModal
          visible={showConfirm}
          getFieldsValue={form.getFieldsValue}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      </>
    );
  },
);
