import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const PikaichiMenusGetMenus = gql`
    query PikaichiMenusGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, categoryMenus: {menu: {_and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}], archivedAt: {_is_null: true}}}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      categoryMenuId
      category {
        categoryId
        name
      }
      menu {
        menuId
        name
        shopMenus(
          where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
        ) {
          shop {
            shopId
            name
          }
        }
        menuOptions(
          where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
          order_by: [{priority: asc}, {_optionId: asc}]
        ) {
          option {
            optionId
            name
          }
        }
        pikaichiMenuMenus {
          pikaichiMenuId
          menuId: _menuId
          pikaichiMenu {
            pikaichiMenuId
            pikaichiMenuCd
            pikaichiMenuName
            pikaichiBumonCd
            pikaichiBumonName
            pikaichiCategoryCd
            pikaichiCategoryName
          }
        }
      }
    }
  }
}
    `;
export const PikaichiMenusGetCategories = gql`
    query PikaichiMenusGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const PikaichiMenusGetShops = gql`
    query PikaichiMenusGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;
export const PikaichiMenusDeletePikaichiMenu = gql`
    mutation PikaichiMenusDeletePikaichiMenu($pikaichiMenuId: uuid!) {
  delete_pikaichiMenu_by_pk(pikaichiMenuId: $pikaichiMenuId) {
    pikaichiMenuId
  }
}
    `;
export type PikaichiMenusGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PikaichiMenusGetMenusQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & Pick<Types.CategoryMenu, 'categoryMenuId'>
      & { category: (
        { __typename?: 'category' }
        & Pick<Types.Category, 'categoryId' | 'name'>
      ), menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'menuId' | 'name'>
        & { shopMenus: Array<(
          { __typename?: 'shopMenu' }
          & { shop: (
            { __typename?: 'shop' }
            & Pick<Types.Shop, 'shopId' | 'name'>
          ) }
        )>, menuOptions: Array<(
          { __typename?: 'menuOption' }
          & { option: (
            { __typename?: 'option' }
            & Pick<Types.Option, 'optionId' | 'name'>
          ) }
        )>, pikaichiMenuMenus: Array<(
          { __typename?: 'pikaichiMenuMenu' }
          & Pick<Types.PikaichiMenuMenu, 'pikaichiMenuId'>
          & { menuId: Types.PikaichiMenuMenu['_menuId'] }
          & { pikaichiMenu: (
            { __typename?: 'pikaichiMenu' }
            & Pick<Types.PikaichiMenu, 'pikaichiMenuId' | 'pikaichiMenuCd' | 'pikaichiMenuName' | 'pikaichiBumonCd' | 'pikaichiBumonName' | 'pikaichiCategoryCd' | 'pikaichiCategoryName'>
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type PikaichiMenusGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PikaichiMenusGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type PikaichiMenusGetShopsQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type PikaichiMenusGetShopsQuery = (
  { __typename?: 'query_root' }
  & { shop: Array<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name'>
  )> }
);

export type PikaichiMenusDeletePikaichiMenuMutationVariables = Types.Exact<{
  pikaichiMenuId: Types.Scalars['uuid'];
}>;


export type PikaichiMenusDeletePikaichiMenuMutation = (
  { __typename?: 'mutation_root' }
  & { delete_pikaichiMenu_by_pk?: Types.Maybe<(
    { __typename?: 'pikaichiMenu' }
    & Pick<Types.PikaichiMenu, 'pikaichiMenuId'>
  )> }
);


export const PikaichiMenusGetMenusDocument = gql`
    query PikaichiMenusGetMenus($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, categoryMenus: {menu: {_and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}], archivedAt: {_is_null: true}}}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      categoryMenuId
      category {
        categoryId
        name
      }
      menu {
        menuId
        name
        shopMenus(
          where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}
        ) {
          shop {
            shopId
            name
          }
        }
        menuOptions(
          where: {option: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
          order_by: [{priority: asc}, {_optionId: asc}]
        ) {
          option {
            optionId
            name
          }
        }
        pikaichiMenuMenus {
          pikaichiMenuId
          menuId: _menuId
          pikaichiMenu {
            pikaichiMenuId
            pikaichiMenuCd
            pikaichiMenuName
            pikaichiBumonCd
            pikaichiBumonName
            pikaichiCategoryCd
            pikaichiCategoryName
          }
        }
      }
    }
  }
}
    `;

/**
 * __usePikaichiMenusGetMenusQuery__
 *
 * To run a query within a React component, call `usePikaichiMenusGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiMenusGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiMenusGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePikaichiMenusGetMenusQuery(baseOptions: Apollo.QueryHookOptions<PikaichiMenusGetMenusQuery, PikaichiMenusGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiMenusGetMenusQuery, PikaichiMenusGetMenusQueryVariables>(PikaichiMenusGetMenusDocument, options);
      }
export function usePikaichiMenusGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiMenusGetMenusQuery, PikaichiMenusGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiMenusGetMenusQuery, PikaichiMenusGetMenusQueryVariables>(PikaichiMenusGetMenusDocument, options);
        }
export type PikaichiMenusGetMenusQueryHookResult = ReturnType<typeof usePikaichiMenusGetMenusQuery>;
export type PikaichiMenusGetMenusLazyQueryHookResult = ReturnType<typeof usePikaichiMenusGetMenusLazyQuery>;
export type PikaichiMenusGetMenusQueryResult = Apollo.QueryResult<PikaichiMenusGetMenusQuery, PikaichiMenusGetMenusQueryVariables>;
export const PikaichiMenusGetCategoriesDocument = gql`
    query PikaichiMenusGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __usePikaichiMenusGetCategoriesQuery__
 *
 * To run a query within a React component, call `usePikaichiMenusGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiMenusGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiMenusGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePikaichiMenusGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<PikaichiMenusGetCategoriesQuery, PikaichiMenusGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiMenusGetCategoriesQuery, PikaichiMenusGetCategoriesQueryVariables>(PikaichiMenusGetCategoriesDocument, options);
      }
export function usePikaichiMenusGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiMenusGetCategoriesQuery, PikaichiMenusGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiMenusGetCategoriesQuery, PikaichiMenusGetCategoriesQueryVariables>(PikaichiMenusGetCategoriesDocument, options);
        }
export type PikaichiMenusGetCategoriesQueryHookResult = ReturnType<typeof usePikaichiMenusGetCategoriesQuery>;
export type PikaichiMenusGetCategoriesLazyQueryHookResult = ReturnType<typeof usePikaichiMenusGetCategoriesLazyQuery>;
export type PikaichiMenusGetCategoriesQueryResult = Apollo.QueryResult<PikaichiMenusGetCategoriesQuery, PikaichiMenusGetCategoriesQueryVariables>;
export const PikaichiMenusGetShopsDocument = gql`
    query PikaichiMenusGetShops($companyId: uuid!) {
  shop(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}
    order_by: {name: asc}
  ) {
    shopId
    name
  }
}
    `;

/**
 * __usePikaichiMenusGetShopsQuery__
 *
 * To run a query within a React component, call `usePikaichiMenusGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePikaichiMenusGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePikaichiMenusGetShopsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePikaichiMenusGetShopsQuery(baseOptions: Apollo.QueryHookOptions<PikaichiMenusGetShopsQuery, PikaichiMenusGetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PikaichiMenusGetShopsQuery, PikaichiMenusGetShopsQueryVariables>(PikaichiMenusGetShopsDocument, options);
      }
export function usePikaichiMenusGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PikaichiMenusGetShopsQuery, PikaichiMenusGetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PikaichiMenusGetShopsQuery, PikaichiMenusGetShopsQueryVariables>(PikaichiMenusGetShopsDocument, options);
        }
export type PikaichiMenusGetShopsQueryHookResult = ReturnType<typeof usePikaichiMenusGetShopsQuery>;
export type PikaichiMenusGetShopsLazyQueryHookResult = ReturnType<typeof usePikaichiMenusGetShopsLazyQuery>;
export type PikaichiMenusGetShopsQueryResult = Apollo.QueryResult<PikaichiMenusGetShopsQuery, PikaichiMenusGetShopsQueryVariables>;
export const PikaichiMenusDeletePikaichiMenuDocument = gql`
    mutation PikaichiMenusDeletePikaichiMenu($pikaichiMenuId: uuid!) {
  delete_pikaichiMenu_by_pk(pikaichiMenuId: $pikaichiMenuId) {
    pikaichiMenuId
  }
}
    `;
export type PikaichiMenusDeletePikaichiMenuMutationFn = Apollo.MutationFunction<PikaichiMenusDeletePikaichiMenuMutation, PikaichiMenusDeletePikaichiMenuMutationVariables>;

/**
 * __usePikaichiMenusDeletePikaichiMenuMutation__
 *
 * To run a mutation, you first call `usePikaichiMenusDeletePikaichiMenuMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePikaichiMenusDeletePikaichiMenuMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pikaichiMenusDeletePikaichiMenuMutation, { data, loading, error }] = usePikaichiMenusDeletePikaichiMenuMutation({
 *   variables: {
 *      pikaichiMenuId: // value for 'pikaichiMenuId'
 *   },
 * });
 */
export function usePikaichiMenusDeletePikaichiMenuMutation(baseOptions?: Apollo.MutationHookOptions<PikaichiMenusDeletePikaichiMenuMutation, PikaichiMenusDeletePikaichiMenuMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PikaichiMenusDeletePikaichiMenuMutation, PikaichiMenusDeletePikaichiMenuMutationVariables>(PikaichiMenusDeletePikaichiMenuDocument, options);
      }
export type PikaichiMenusDeletePikaichiMenuMutationHookResult = ReturnType<typeof usePikaichiMenusDeletePikaichiMenuMutation>;
export type PikaichiMenusDeletePikaichiMenuMutationResult = Apollo.MutationResult<PikaichiMenusDeletePikaichiMenuMutation>;
export type PikaichiMenusDeletePikaichiMenuMutationOptions = Apollo.BaseMutationOptions<PikaichiMenusDeletePikaichiMenuMutation, PikaichiMenusDeletePikaichiMenuMutationVariables>;