import React, { memo, useCallback } from "react";
import { Button, Col, Row } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";

import { Plan } from "../types";

import { NameField } from "./NameField";
import { TecAggregationDpCodeField } from "./TecAggregationDpCodeField";
import { TecAggregationDpNameField } from "./TecAggregationDpNameField";
import { TecAggregationGetAvailableCodeButtonField } from "./TecAggregationGetAvailableMenuCdButtonField";
import { TecAggregationGpCodeField } from "./TecAggregationGpCodeField";
import { TecAggregationGpNameField } from "./TecAggregationGpNameField";
import { TecAggregationMenuCodeField } from "./TecAggregationMenuCodeField";
import { TecAggregationPlanOptionTable } from "./TecAggregationPlanOptionTable";
import {
  EditPlanTecAggregationPlanFormValues,
  useEditPlanTecAggregationPlanForm,
} from "./useEditPlanTecAggregationPlanForm";

type Props = {
  plan: Plan;
  onSubmit: (args: EditPlanTecAggregationPlanFormValues) => void;
  onClose: () => void;
  getAvailableMenuCode: () => number;
  loading: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const EditPlanTecAggregationPlanForm = memo<Props>(
  ({ plan, onClose, onSubmit, getAvailableMenuCode, loading, onFormValidationError }) => {
    const tecAggregationMenu = plan?.tecAggregationMenu ?? undefined;

    const { form, initialValues, submit } = useEditPlanTecAggregationPlanForm({
      plan,
      tecAggregationMenu,
      onSubmit,
    });

    const handleAutoAdoptMenuCodeButtonPressed = useCallback(() => {
      const maxMenuCode = getAvailableMenuCode();
      form.setFields([{ name: "menuCode", value: maxMenuCode.toString() }]);
    }, [getAvailableMenuCode, form]);

    const handleGetAvailableMenuCodeForOptionButtonPressed = useCallback(() => {
      const fieldsValue = form.getFieldsValue();

      const availableMenuCode = getAvailableMenuCode();

      fieldsValue.plan?.planOptions.forEach((planOption, planOptionIndex) => {
        planOption.planChoices.forEach((_, planChoiceIndex) => {
          const fieldPath = [
            "plan",
            "planOptions",
            planOptionIndex,
            "planChoices",
            planChoiceIndex,
            "tecAggregationMenu",
            "menuCode",
          ];

          form.setFieldValue(fieldPath, (availableMenuCode + planChoiceIndex).toString());
        });
      });
    }, [form, getAvailableMenuCode]);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [submit, onFormValidationError, form]);

    return (
      <>
        <FormContent>
          <Form
            name="tecAggregationMenu"
            form={form}
            initialValues={initialValues}
            layout="vertical"
          >
            <Row gutter={24}>
              <Col flex={1}>
                <TecAggregationMenuCodeField />
              </Col>
              <Col>
                <TecAggregationGetAvailableCodeButtonField
                  handleGetAvailableMenuCodeButtonPressed={handleAutoAdoptMenuCodeButtonPressed}
                />
              </Col>
            </Row>
            <NameField />
            <TecAggregationDpCodeField />
            <TecAggregationDpNameField />
            <TecAggregationGpCodeField />
            <TecAggregationGpNameField />
            <TecAggregationPlanOptionTable
              handleGetAvailableMenuCodeForOptionButtonPressed={
                handleGetAvailableMenuCodeForOptionButtonPressed
              }
            />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
