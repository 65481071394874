import { RouteName } from "components/Router";
import { DashboardAccountRolePermissionTypeEnum, DashboardFeatureEnum } from "types/graphql";

/*
 * serviceAdmin はすべての機能にアクセス可能
 * その判定は別で行うので、serviceAdmin のみアクセス可能なページは undefined になっている
 * また signIn、signOut、invalidAccount ページもそれぞれ特殊な権限管理を行うため、undefined にしている
 */
export const accessPermissions: Record<
  RouteName,
  | {
      feature: DashboardFeatureEnum;
      type: DashboardAccountRolePermissionTypeEnum;
    }
  | undefined
> = {
  accountingDetail: {
    feature: DashboardFeatureEnum.AccountingHistory,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  accountingHistory: {
    feature: DashboardFeatureEnum.AccountingHistory,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  addCategory: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  addClerk: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addCompany: undefined,
  addCorporation: undefined,
  addCookingItem: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addCoupon: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addDashboardAccount: {
    feature: DashboardFeatureEnum.DashboardAccountManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addDashboardAccountRole: {
    feature: DashboardFeatureEnum.DashboardAccountManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addDishUpSlipGroup: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addGift: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addGiftChoice: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addGmoBankAccount: undefined,
  addKitchenDisplayDisplayTarget: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addLineOfficialAccount: undefined,
  addMenu: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addMessageDelivery: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addOrderableTime: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addPlan: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addPlanGroup: undefined,
  addRole: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addServiceAdmin: undefined,
  addShop: undefined,
  addTable: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  analyticsSettings: {
    feature: DashboardFeatureEnum.CompanyManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addTableArea: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  categories: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  clearTableHistory: {
    feature: DashboardFeatureEnum.TableClearHistory,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  clerks: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  coineySales: {
    feature: DashboardFeatureEnum.Aggregation,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  companies: undefined,
  corporations: undefined,
  cookingItems: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  coupons: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  couponAnalytics: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  currentSales: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  customerDashboards: undefined,
  lineOfficialAccounts: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  dailyCashRegisterBalancing: {
    feature: DashboardFeatureEnum.DailyReport,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  dashboard: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  dashboardAccounts: {
    feature: DashboardFeatureEnum.DashboardAccountManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  dashboardAccountRoles: {
    feature: DashboardFeatureEnum.DashboardAccountManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  dishUpSlipGroupMenus: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  dishUpSlipGroupPlans: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  dishUpSlipGroups: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editEbicaConfig: undefined,
  ebicaTables: undefined,
  editCashRegisterConfig: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editCategory: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editCategoryPriorities: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editCategoryTranslation: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editClerk: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editClerkPriorities: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editCookingItem: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editCompany: undefined,
  editCorporation: undefined,
  editCoupon: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editDashboardAccount: {
    feature: DashboardFeatureEnum.DashboardAccountManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editDashboardAccountRole: {
    feature: DashboardFeatureEnum.DashboardAccountManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editDefaultTaxMethods: undefined,
  editDishUpSlipGroup: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editFoodingJournalConfig: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editGift: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editGiftChoice: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editGiftPriorities: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editGmoBankAccount: undefined,
  editGoogleMapConfig: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editDiniiPayConfig: undefined,
  editTecAggregationConfig: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editTecAggregationMenu: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editTecAggregationPlan: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editTecAggregationOnSitePaymentDetailType: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editKitchenDisplayDisplayTarget: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editLineChannelConfig: undefined,
  editLineOfficialAccount: undefined,
  editMenu: {
    feature: DashboardFeatureEnum.MenuMasterData,
    // 編集画面という名前ではあるが、ここでしか閲覧できない情報もあるため、View 権限のみでアクセス可能にしている。編集機能は featurePermissions の機能で無効化している。
    // 同様のページがいくつか存在する。
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editMenuDealers: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editMenuTranslation: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editMenusBulk: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editMenuTecAggregationMenu: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editMenuPikaichiMenu: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editMenuWinboardMenu: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editMenuPriorities: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editMessageDelivery: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editOrderableTime: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editPikaichiConfig: undefined,
  editPikaichiOnSitePaymentDetailType: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editPikaichiOnSitePaymentDiscountType: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editPikaichiMenu: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editPikaichiPlan: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editPlan: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editPlanDealers: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editPlanGroup: undefined,
  editPlanPriorities: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editPlanTranslation: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editPlanPikaichiPlan: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editPlanTecAggregationPlan: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editPlanWinboardPlan: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editRole: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editServiceAdmin: undefined,
  editShop: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editShopMenuOutput: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editShopMenuStock: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editShopPlanOptionRoles: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editShopPlanRoles: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editTable: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editTableArea: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editInflowSourceTags: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editInflowSourceTagPriorities: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editOnSitePaymentDetailTypes: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editOnSitePaymentDetailTypesPriorities: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editOnSitePaymentDiscountTypes: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editOnSitePaymentDiscountTypesPriorities: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editWinboardConfig: undefined,
  editWinboardInflowSourceTag: {
    feature: DashboardFeatureEnum.Aggregation,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editWinboardMenu: {
    feature: DashboardFeatureEnum.Aggregation,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editWinboardPlan: {
    feature: DashboardFeatureEnum.Aggregation,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  excludeFromAggregation: undefined,
  exportDailyAggregatedData: {
    feature: DashboardFeatureEnum.Aggregation,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  exportDailyAggregatedDataV2: undefined,
  featureFlags: undefined,
  foodingJournalMasters: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  foodingJournalMenus: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  foodingJournalOnSitePaymentDetailTypes: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  foodingJournalOnSitePaymentDiscountTypes: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  foodingJournalPlans: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  foodingJournalUpload: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  generateQrCodes: undefined,
  gifts: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  giftChoices: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  handouts: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  inflowSourceTags: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  tecAggregationMediaMaps: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  tecAggregationDetailTypes: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  tecAggregationMenus: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  tecAggregationMenuOptions: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  tecPlans: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  tecPlanOptions: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  tecAggregationGifts: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  tecUpload: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  kitchenDisplayDisplayTargetMenus: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  kitchenDisplayDisplayTargets: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  menuOptions: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  faveYell: {
    feature: DashboardFeatureEnum.Aggregation,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  menuOrderItemAnalytics: {
    feature: DashboardFeatureEnum.Aggregation,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  salesAnalytics: {
    feature: DashboardFeatureEnum.Aggregation,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  salesByDayofweekAndHourAnalytics: {
    feature: DashboardFeatureEnum.Aggregation,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  companySalesAnalytics: {
    feature: DashboardFeatureEnum.Aggregation,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  menus: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  menuAutoCompulsoryAppetizer: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  menuAutoCompulsoryAppetizerModal: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  menuMasterCsv: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  menuCodeCsv: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  messageDeliveries: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  messageDeliveryJobs: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  messageDeliveryJob: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  messageDeliveryAnalytics: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  mobileOrderDesign: {
    feature: DashboardFeatureEnum.CompanyManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  options: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  addOption: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editOption: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editOptionTranslation: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editOptionDealer: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editOptionPikaichiOption: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editOptionTecAggregationOption: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editOptionWinboardOption: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  onboardingHandout: undefined,
  onSitePaymentDetailTypes: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  onSitePaymentDiscountTypes: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  steraOnSitePaymentDetailTypes: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  gmoOffPaymentOnSitePaymentDetailTypes: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  adyenTerminalPaymentOnSitePaymentDetailTypes: {
    feature: DashboardFeatureEnum.TerminalPayment,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  adyenTerminalPaymentSignIn: undefined,
  adyenTerminalPaymentSignOut: undefined,
  adyenTerminalPaymentHistory: {
    feature: DashboardFeatureEnum.TerminalPayment,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  adyenTerminalPaymentDepositHistory: {
    feature: DashboardFeatureEnum.TerminalPayment,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  adyenTerminalPaymentDepositHistoryByShop: {
    feature: DashboardFeatureEnum.TerminalPayment,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  pikaichiGifts: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  pikaichiMenuOptions: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  pikaichiMenus: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  pikaichiOnSitePaymentTypes: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  pikaichiPlanOptions: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  pikaichiPlans: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  pikaichiUpload: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  planCategories: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  planFirstOrderCategoryMenus: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  planGroupPlans: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  planGroups: undefined,
  planMainOptionPrintings: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  planMenus: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  planOptions: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  plans: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  previewUserApp: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  qrCodes: undefined,
  questionnaireAnalyticsCommentsMetrics: {
    feature: DashboardFeatureEnum.Questionnaire,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  questionnaireAnalyticsScoreChangesMetrics: {
    feature: DashboardFeatureEnum.Questionnaire,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  questionnaireAnalyticsScoresMetrics: {
    feature: DashboardFeatureEnum.Questionnaire,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  questionnaireAnalyticsShopMetrics: {
    feature: DashboardFeatureEnum.Questionnaire,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  questionnaireAnalyticsShopMetricsPerCategory: {
    feature: DashboardFeatureEnum.Questionnaire,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  roles: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  serviceAdmins: undefined,
  shopDailyCashRegisterBalancing: {
    feature: DashboardFeatureEnum.DailyReport,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  shops: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  signIn: undefined,
  signOut: undefined,
  tables: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  inputMasterData: undefined,
  inputShopData: undefined,
  copyMasterData: undefined,
  copyDealingData: undefined,
  invalidAccount: undefined,
  convertTecFormat: undefined,
  addOnSitePaymentDiscountType: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addOnSitePaymentDetailType: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addInflowSourceTag: {
    feature: DashboardFeatureEnum.PaymentMethodSettings,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  winboardInflowSourceTags: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  winboardGifts: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  winboardMenus: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  winboardMenuOptions: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  winboardPlans: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  winboardPlanOptions: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  winboardMenuRegistrationCsv: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  winboardUpload: {
    feature: DashboardFeatureEnum.BusinessIntelligenceIntegration,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  orderableTimeShops: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  rankingFaveYellAmounts: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  rankingFaveYellCounts: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  rankingViralCounts: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  rankingVisitedCounts: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  shopOrderableTimes: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  shopMenus: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  shopPlans: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  kitchenSlipTablePrinting: undefined,
  dishUpSlipGroupTablePrinting: undefined,
  paymentReceiptRoleTablePrinting: undefined,
  editMembershipCardAppearanceType: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  membershipRankConfig: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  editMembershipRankConfig: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  externalOnlineMenus: undefined,
  editShopExternalOnlineMenuConfig: undefined,
  addShopExternalOnlineMenu: undefined,
  editShopExternalOnlineMenu: undefined,
  questionnaireConfigs: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  addQuestionnaireConfig: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editQuestionnaireConfig: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editShopBusinessOperationHour: {
    feature: DashboardFeatureEnum.ShopManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  connectGameConfigs: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  addConnectGameConfig: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editConnectGameConfig: {
    feature: DashboardFeatureEnum.CustomerRelationshipManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  addMenuRecommendation: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editMenuRecommendation: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editMenuRecommendationTranslation: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  menuRecommendations: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  menuRecommendationMenus: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  lineReportingBotConfigs: {
    feature: DashboardFeatureEnum.DailyReport,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  addLineReportingBotConfig: {
    feature: DashboardFeatureEnum.DailyReport,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editLineReportingBotConfig: {
    feature: DashboardFeatureEnum.DailyReport,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  lineReportingImages: undefined,
  autoTranslation: {
    feature: DashboardFeatureEnum.CompanyManagement,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  salesBudget: {
    feature: DashboardFeatureEnum.SalesBudget,
    type: DashboardAccountRolePermissionTypeEnum.View,
  },
  addSalesBudget: {
    feature: DashboardFeatureEnum.SalesBudget,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editSalesBudget: {
    feature: DashboardFeatureEnum.SalesBudget,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editShopMenusBulk: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  editShopPlansBulk: {
    feature: DashboardFeatureEnum.MenuMasterData,
    type: DashboardAccountRolePermissionTypeEnum.Edit,
  },
  shopCashlessSetting: undefined,
  editShopCashlessSettingStoreMeta: undefined,
  editShopCashlessSettingProviderSetting: undefined,
  bankAccountCashlessSetting: undefined,
};
