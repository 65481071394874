import React, { memo, useCallback } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { NameField } from "pages/AddDishUpSlipGroup/AddDishUpSlipGroupForm/NameField";
import { RoleField } from "pages/AddDishUpSlipGroup/AddDishUpSlipGroupForm/RoleField";
import { useAddDishUpSlipGroupForm } from "pages/AddDishUpSlipGroup/AddDishUpSlipGroupForm/useAddDishUpSlipGroupForm";
import { Role } from "pages/AddDishUpSlipGroup/types";
import { DishUpSlipGroupInsertInput, DishUpSlipGroupRolesInsertInput } from "types/graphql";

type Props = {
  roles: Role[];
  onSubmit: ({
    dishUpSlipGroup,
    dishUpSlipGroupRoles,
  }: {
    dishUpSlipGroup: Omit<DishUpSlipGroupInsertInput, "shopId">;
    dishUpSlipGroupRoles: Omit<DishUpSlipGroupRolesInsertInput, "dishUpSlipGroupId" | "_dishUpSlipGroupId" | "shopId">[];
  }) => void;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
  onClose: () => void;
  loading: boolean;
};

export const AddDishUpSlipGroupForm = memo<Props>(
  ({ onClose, onFormValidationError, onSubmit, loading, roles }) => {
    const { form, initialValues, submit } = useAddDishUpSlipGroupForm({ roles, onSubmit });

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        await submit();
      } catch (e) {
        if (isValidateErrorEntity(e)) onFormValidationError({ formValidationError: e });
      }
    }, [submit, form, onFormValidationError]);

    const handleClose = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <>
        <FormContent>
          <Form name="dishUpSlipGroup" form={form} initialValues={initialValues} layout="vertical">
            <NameField />
            <RoleField roles={roles} />
          </Form>
        </FormContent>
        <Spacer size={24} />
        <FormActions>
          <Button onClick={handleClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            登録
          </Button>
        </FormActions>
      </>
    );
  },
);
