import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { useShop } from "hooks/useShop";
import { Role } from "pages/AddDishUpSlipGroup/types";
import {
  DishUpSlipGroup,
  DishUpSlipGroupInsertInput,
  DishUpSlipGroupRolesInsertInput,
} from "types/graphql";

export type AddDishUpSlipGroupFormValues = Pick<DishUpSlipGroup, "name"> & {
  roleIds?: Role["roleId"][];
};
const getInitialValues = () => ({});

export const AddDishUpSlipGroupFormItem = createFormItem<AddDishUpSlipGroupFormValues>();

export const useAddDishUpSlipGroupForm = ({
  roles,
  onSubmit,
}: {
  roles: Pick<Role, "id" | "roleId">[];
  onSubmit: ({
    dishUpSlipGroup,
    dishUpSlipGroupRoles,
  }: {
    dishUpSlipGroup: Omit<DishUpSlipGroupInsertInput, "shopId">;
    dishUpSlipGroupRoles: Omit<DishUpSlipGroupRolesInsertInput, "dishUpSlipGroupId" | "_dishUpSlipGroupId" | "shopId">[];
  }) => void;
}) => {
  const [shop] = useShop();

  const shopId = shop?.shopId;

  const [form] = Form.useForm();
  const initialValues = getInitialValues();

  const submit = useCallback(() => {
    const formValues = form.getFieldsValue() as AddDishUpSlipGroupFormValues;
    const { roleIds, name } = formValues;
    const dishUpSlipGroupRoles = (roleIds ?? []).map((_roleId) => {
      const role = roles.find(({ roleId }) => roleId === _roleId);

      if (!role) throw new Error("role not found");

      return { roleId: role.id, _roleId: role.roleId };
    });

    onSubmit({
      dishUpSlipGroup: { name },
      dishUpSlipGroupRoles,
    });
  }, [form, onSubmit, roles]);

  return { form, initialValues, submit };
};
