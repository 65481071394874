import React, { memo } from "react";
import styled from "styled-components";
import { Button, Col, Input, Row, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";

import { Select } from "components/Input/Select";
import { UpdateFilterConditionFunctionType } from "hooks/useFilterConditions";

import { Corporation, Shop } from "../types";

export type FilterConditions = {
  name?: string;
  corporationIds?: string[];
  shopIds?: string[];
};

const Wrapper = styled(Row).attrs({ gutter: 16 })`
  width: 100%;
  align-items: center;
`;

type Props = {
  corporations: Corporation[];
  shops: Shop[];
  hasFilterConditions: boolean;
  filterConditions: FilterConditions;
  updateFilterCondition: UpdateFilterConditionFunctionType<FilterConditions>;
  clearFilterConditions: () => void;
};

export const GmoBankAccountFilter = memo<Props>(
  ({
    corporations,
    shops,
    hasFilterConditions,
    filterConditions,
    updateFilterCondition,
    clearFilterConditions,
  }) => (
    <Wrapper>
      <Col flex="1 0">
        <Input
          placeholder="管理名称"
          value={filterConditions.name}
          onChange={(e) => updateFilterCondition({ name: e.target.value })}
        />
      </Col>
      <Col flex="1 0">
        <Select<string[]>
          mode="multiple"
          placeholder="対象法人名"
          defaultValue={[]}
          value={filterConditions.corporationIds ?? []}
          onChange={(corporationIds) => updateFilterCondition({ corporationIds })}
          optionFilterProp="label"
          options={corporations
            .filter((c) => c?.id && c?.name)
            .map((c) => ({
              label: c?.name,
              value: c?.id,
            }))}
        />
      </Col>
      <Col flex="1 0">
        <Select<string[]>
          mode="multiple"
          placeholder="対象店舗名"
          defaultValue={[]}
          value={filterConditions.shopIds ?? []}
          onChange={(shopIds) => updateFilterCondition({ shopIds })}
          optionFilterProp="label"
          options={shops
            .filter((s) => s?.id && s?.name)
            .map((s) => ({
              label: s?.name,
              value: s?.id,
            }))}
        />
      </Col>
      <Col flex="none">
        <Tooltip title="フィルター条件をクリア">
          <Button
            shape="circle"
            icon={<ClearOutlined />}
            disabled={hasFilterConditions}
            onClick={clearFilterConditions}
          />
        </Tooltip>
      </Col>
    </Wrapper>
  ),
);
