import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CorporationsGetCorporations = gql`
    query CorporationsGetCorporations {
  corporation {
    id
    name
    companies(where: {archivedAt: {_is_null: true}}) {
      companyId
      name
    }
  }
}
    `;
export type CorporationsGetCorporationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CorporationsGetCorporationsQuery = (
  { __typename?: 'query_root' }
  & { corporation: Array<(
    { __typename?: 'corporation' }
    & Pick<Types.Corporation, 'id' | 'name'>
    & { companies: Array<(
      { __typename?: 'company' }
      & Pick<Types.Company, 'companyId' | 'name'>
    )> }
  )> }
);


export const CorporationsGetCorporationsDocument = gql`
    query CorporationsGetCorporations {
  corporation {
    id
    name
    companies(where: {archivedAt: {_is_null: true}}) {
      companyId
      name
    }
  }
}
    `;

/**
 * __useCorporationsGetCorporationsQuery__
 *
 * To run a query within a React component, call `useCorporationsGetCorporationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCorporationsGetCorporationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCorporationsGetCorporationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCorporationsGetCorporationsQuery(baseOptions?: Apollo.QueryHookOptions<CorporationsGetCorporationsQuery, CorporationsGetCorporationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CorporationsGetCorporationsQuery, CorporationsGetCorporationsQueryVariables>(CorporationsGetCorporationsDocument, options);
      }
export function useCorporationsGetCorporationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CorporationsGetCorporationsQuery, CorporationsGetCorporationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CorporationsGetCorporationsQuery, CorporationsGetCorporationsQueryVariables>(CorporationsGetCorporationsDocument, options);
        }
export type CorporationsGetCorporationsQueryHookResult = ReturnType<typeof useCorporationsGetCorporationsQuery>;
export type CorporationsGetCorporationsLazyQueryHookResult = ReturnType<typeof useCorporationsGetCorporationsLazyQuery>;
export type CorporationsGetCorporationsQueryResult = Apollo.QueryResult<CorporationsGetCorporationsQuery, CorporationsGetCorporationsQueryVariables>;