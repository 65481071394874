import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import { NavigationLayout } from "components/Layout/DashboardLayout/NavigationLayout";
import { PageElement } from "components/Router/PageElement";
import { AccountingDetail } from "pages/AccountingDetail";
import { AccountingHistory } from "pages/AccountingHistory";
import { AddCategory } from "pages/AddCategory";
import { AddClerk } from "pages/AddClerk";
import { AddCompany } from "pages/AddCompany";
import { AddConnectGameConfig } from "pages/AddConnectGameConfig";
import { AddCookingItem } from "pages/AddCookingItem";
import { AddCorporation } from "pages/AddCorporation";
import { AddCoupon } from "pages/AddCoupon";
import { AddDashboardAccount } from "pages/AddDashboardAccount";
import { AddDashboardAccountRole } from "pages/AddDashboardAccountRole";
import { AddDishUpSlipGroup } from "pages/AddDishUpSlipGroup/index";
import { AddGift } from "pages/AddGift";
import { AddGiftChoice } from "pages/AddGiftChoice";
import { AddGmoBankAccount } from "pages/AddGmoBankAccount";
import { AddInflowSourceTag } from "pages/AddInflowSourceTag";
import { AddKitchenDisplayDisplayTarget } from "pages/AddKitchenDisplayDisplayTarget";
import { AddLineOfficialAccount } from "pages/AddLineOfficialAccount";
import { AddLineReportingBotConfig } from "pages/AddLineReportingBotConfig";
import { AddMenu } from "pages/AddMenu";
import { AddMenuRecommendation } from "pages/AddMenuRecommendation";
import { AddMessageDelivery } from "pages/AddMessageDelivery";
import { AddOnSitePaymentDetailType } from "pages/AddOnSitePaymentDetailType";
import { AddOnSitePaymentDiscountType } from "pages/AddOnSitePaymentDiscountType";
import { AddOption } from "pages/AddOption";
import { AddOrderableTime } from "pages/AddOrderableTime";
import { AddPlan } from "pages/AddPlan";
import { AddPlanGroup } from "pages/AddPlanGroup";
import { AddQuestionnaireConfig } from "pages/AddQuestionnaireConfig";
import { AddRole } from "pages/AddRole";
import { AddSalesBudget } from "pages/AddSalesBudget";
import { AddServiceAdmin } from "pages/AddServiceAdmin";
import { AddShop } from "pages/AddShop";
import { AddShopExternalOnlineMenu } from "pages/AddShopExternalOnlineMenu";
import { AddTable } from "pages/AddTable";
import { AddTableArea } from "pages/AddTableArea";
import { AdyenPaymentDepositHistory } from "pages/AdyenPaymentDepositHistory";
import { AdyenPaymentDepositHistoryByShop } from "pages/AdyenPaymentDepositHistoryByShop";
import { AdyenTerminalPaymentHistory } from "pages/AdyenTerminalPaymentHistory";
import { AdyenTerminalOnSitePaymentDetailTypes } from "pages/AdyenTerminalPaymentOnSitePaymentDetailTypes";
import { AdyenTerminalPaymentSignIn } from "pages/AdyenTerminalPaymentSignIn";
import { AdyenTerminalPaymentSignOut } from "pages/AdyenTerminalPaymentSignOut";
import { AnalyticsSettings } from "pages/AnalyticsSettings";
import { AutoTranslation } from "pages/AutoTranslation";
import { BankAccountCashlessSetting } from "pages/BankAccountCashlessSetting";
import { Categories } from "pages/Categories";
import { ClearTableHistory } from "pages/ClearTableHistory";
import { Clerks } from "pages/Clerks";
import { CoineySales } from "pages/CoineySales";
import { Companies } from "pages/Companies";
import { CompanySalesAnalytics } from "pages/CompanySalesAnalytics";
import { ConnectGameConfigs } from "pages/ConnectGameConfigs";
import { ConvertTecFormat } from "pages/ConvertTecFormat";
import { CookingItems } from "pages/CookingItems";
import { CopyDealingData } from "pages/CopyDealingData";
import { CopyMasterData } from "pages/CopyMasterData";
import { Corporations } from "pages/Corporations";
import { CouponAnalytics } from "pages/CouponAnalytics";
import { Coupons } from "pages/Coupons";
import { CurrentSales } from "pages/CurrentSales";
import { CustomerDashboards } from "pages/CustomerDashboards";
import { DailyCashRegisterBalancing } from "pages/DailyCashRegisterBalancing";
import { Dashboard } from "pages/Dashboard";
import { DashboardAccountRoles } from "pages/DashboardAccountRoles";
import { DashboardAccounts } from "pages/DashboardAccounts";
import { DishUpSlipGroupMenus } from "pages/DishUpSlipGroupMenus";
import { DishUpSlipGroupPlans } from "pages/DishUpSlipGroupPlans";
import { DishUpSlipGroups } from "pages/DishUpSlipGroups";
import { DishUpSlipGroupTablePrinting } from "pages/DishUpSlipGroupTablePrinting";
import { EbicaTables } from "pages/EbicaTables";
import { EditCashRegisterConfig } from "pages/EditCashRegisterConfig";
import { EditCategory } from "pages/EditCategory";
import { EditCategoryPriorities } from "pages/EditCategoryPriorities";
import { EditCategoryTranslations } from "pages/EditCategoryTranslations";
import { EditClerk } from "pages/EditClerk";
import { EditClerkPriorities } from "pages/EditClerkPriorities";
import { EditCompany } from "pages/EditCompany";
import { EditConnectGameConfig } from "pages/EditConnectGameConfig";
import { EditCookingItem } from "pages/EditCookingItem";
import { EditCorporation } from "pages/EditCorporation";
import { EditCoupon } from "pages/EditCoupon";
import { EditDashboardAccount } from "pages/EditDashboardAccount";
import { EditDashboardAccountRole } from "pages/EditDashboardAccountRole";
import { EditDefaultTaxMethods } from "pages/EditDefaultTaxMethods";
import { EditDiniiPayConfig } from "pages/EditDiniiPayConfig";
import { EditDishUpSlipGroup } from "pages/EditDishUpSlipGroup";
import { EditEbicaConfig } from "pages/EditEbicaConfig";
import { EditFoodingJournalConfig } from "pages/EditFoodingJournalConfig";
import { EditGift } from "pages/EditGift";
import { EditGiftChoice } from "pages/EditGiftChoice";
import { EditGiftPriorities } from "pages/EditGiftPriorities";
import { EditGmoBankAccount } from "pages/EditGmoBankAccount";
import { EditGoogleMapConfig } from "pages/EditGoogleMapConfig";
import { EditInflowSourceTag } from "pages/EditInflowSourceTag";
import { EditInflowSourceTagPriorities } from "pages/EditInflowSourceTagPriorities";
import { EditKitchenDisplayDisplayTarget } from "pages/EditKitchenDisplayDisplayTarget";
import { EditLineChannelConfig } from "pages/EditLineChannelConfig";
import { EditLineOfficialAccount } from "pages/EditLineOfficialAccount";
import { EditLineReportingBotConfig } from "pages/EditLineReportingBotConfig";
import { EditMembershipCardAppearanceType } from "pages/EditMembershipCardAppearanceType";
import { EditMembershipRankConfig } from "pages/EditMembershipRankConfig";
import { EditMenu } from "pages/EditMenu";
import { EditMenuDealers } from "pages/EditMenuDealers";
import { EditMenuPikaichiMenu } from "pages/EditMenuPikaichiMenu";
import { EditMenuPriorities } from "pages/EditMenuPriorities";
import { EditMenuRecommendation } from "pages/EditMenuRecommendation";
import { EditMenuRecommendationTranslations } from "pages/EditMenuRecommendationTranslations";
import { EditMenusBulk } from "pages/EditMenusBulk";
import { EditMenuTecAggregationMenu } from "pages/EditMenuTecAggregationMenu";
import { EditMenuTranslations } from "pages/EditMenuTranslations";
import { EditMenuWinboardMenu } from "pages/EditMenuWinboardMenu";
import { EditMessageDelivery } from "pages/EditMessageDelivery";
import { EditOnSitePaymentDetailType } from "pages/EditOnSitePaymentDetailType";
import { EditOnSitePaymentDetailTypePriorities } from "pages/EditOnSitePaymentDetailTypePriorities";
import { EditOnSitePaymentDiscountType } from "pages/EditOnSitePaymentDiscountType";
import { EditOnSitePaymentDiscountTypePriorities } from "pages/EditOnSitePaymentDiscountTypePriorities";
import { EditOption } from "pages/EditOption";
import { EditOptionDealers } from "pages/EditOptionDealers";
import { EditOptionPikaichiOption } from "pages/EditOptionPikaichiOption";
import { EditOptionTecAggregationOption } from "pages/EditOptionTecAggregationOption";
import { EditOptionTranslations } from "pages/EditOptionTranslations";
import { EditOptionWinboardOption } from "pages/EditOptionWinboardOption";
import { EditOrderableTime } from "pages/EditOrderableTime";
import { EditPikaichiConfig } from "pages/EditPikaichiConfig";
import { EditPikaichiMenu } from "pages/EditPikaichiMenu";
import { EditPikaichiOnSitePaymentDetailType } from "pages/EditPikaichiOnSitePaymentDetailType";
import { EditPikaichiOnSitePaymentDiscountType } from "pages/EditPikaichiOnSitePaymentDiscountType";
import { EditPikaichiPlan } from "pages/EditPikaichiPlan";
import { EditPlan } from "pages/EditPlan";
import { EditPlanDealers } from "pages/EditPlanDealers";
import { EditPlanGroup } from "pages/EditPlanGroup";
import { EditPlanPikaichiPlan } from "pages/EditPlanPikaichiPlan";
import { EditPlanPriorities } from "pages/EditPlanPriorities";
import { EditPlanTecAggregationPlan } from "pages/EditPlanTecAggregationPlan";
import { EditPlanTranslations } from "pages/EditPlanTranslations";
import { EditPlanWinboardPlan } from "pages/EditPlanWinboardPlan";
import { EditQuestionnaireConfig } from "pages/EditQuestionnaireConfig";
import { EditRole } from "pages/EditRole";
import { EditSalesBudget } from "pages/EditSalesBudget";
import { EditServiceAdmin } from "pages/EditServiceAdmin";
import { EditShop } from "pages/EditShop";
import { EditShopBusinessOperationHour } from "pages/EditShopBusinessOperationHour";
import { EditShopCashlessSettingProviderSetting } from "pages/EditShopCashlessSettingProviderSetting";
import { EditShopCashlessSettingStoreMeta } from "pages/EditShopCashlessSettingStoreMeta";
import { EditShopExternalOnlineMenu } from "pages/EditShopExternalOnlineMenu";
import { EditShopExternalOnlineMenuConfig } from "pages/EditShopExternalOnlineMenuConfig";
import { EditShopMenuOutput } from "pages/EditShopMenuOutput";
import { EditShopMenusBulk } from "pages/EditShopMenusBulk";
import { EditShopMenuStock } from "pages/EditShopMenuStock";
import { EditShopPlanOptionRoles } from "pages/EditShopPlanOptionRoles";
import { EditShopPlanRoles } from "pages/EditShopPlanRoles";
import { EditShopPlansBulk } from "pages/EditShopPlansBulk";
import { EditTable } from "pages/EditTable";
import { EditTableArea } from "pages/EditTableArea";
import { EditTecAggregationConfig } from "pages/EditTecAggregationConfig";
import { EditTecAggregationMenu } from "pages/EditTecAggregationMenu";
import { EditTecAggregationOnSitePaymentDetailType } from "pages/EditTecAggregationOnSitePaymentDetailType";
import { EditTecAggregationPlan } from "pages/EditTecAggregationPlan";
import { EditWinboardConfig } from "pages/EditWinboardConfig";
import { EditWinboardInflowSourceTag } from "pages/EditWinboardInflowSourceTag";
import { EditWinboardMenu } from "pages/EditWinboardMenu";
import { EditWinboardPlan } from "pages/EditWinboardPlan";
import { ExcludeFromAggregation } from "pages/ExcludeFromAggregation";
import { ExportDailyAggregatedData } from "pages/ExportDailyAggregatedData";
import { ExportDailyAggregatedDataV2 } from "pages/ExportDailyAggregatedDataV2";
import { ExternalOnlineMenus } from "pages/ExternalOnlineMenus";
import { FaveYellAnalytics } from "pages/FaveYell";
import { FeatureFlags } from "pages/FeatureFlags";
import { FoodingJournalMasters } from "pages/FoodingJournalMasters";
import { FoodingJournalMenus } from "pages/FoodingJournalMenus";
import { FoodingJournalOnSitePaymentDetailTypes } from "pages/FoodingJournalOnSitePaymentDetailTypes";
import { FoodingJournalOnSitePaymentDiscountTypes } from "pages/FoodingJournalOnSitePaymentDiscountTypes";
import { FoodingJournalPlans } from "pages/FoodingJournalPlans";
import { FoodingJournalUpload } from "pages/FoodingJournalUpload";
import { GenerateQrCodes } from "pages/GenerateQrCodes";
import { GiftChoices } from "pages/GiftChoices";
import { Gifts } from "pages/Gifts";
import { GmoTerminalOnSitePaymentDetailTypes } from "pages/GmoOffPaymentOnSitePaymentDetailTypes";
import { Handouts } from "pages/Handouts";
import { InflowSourceTags } from "pages/InflowSourceTags";
import { InputMasterData } from "pages/InputMasterData";
import { InputShopData } from "pages/InputShopData";
import { InvalidAccount } from "pages/InvalidAccount";
import { KitchenDisplayDisplayTargetMenus } from "pages/KitchenDisplayDisplayTargetMenus";
import { KitchenDisplayDisplayTargets } from "pages/KitchenDisplayDisplayTargets";
import { KitchenSlipTablePrinting } from "pages/KitchenSlipTablePrinting";
import { LineOfficialAccounts } from "pages/LineOfficialAccounts";
import { LineReportingBotConfigs } from "pages/LineReportingBotConfigs";
import { LineReportingImages } from "pages/LineReportingImages";
import { MembershipRankConfig } from "pages/MembershipRankConfig";
import { MenuAutoCompulsoryAppetizer } from "pages/MenuAutoCompulsoryAppetizer";
import { MenuCodeCsv } from "pages/MenuCodeCsv";
import { MenuMasterCsv } from "pages/MenuMasterCsv";
import { MenuOptions } from "pages/MenuOptions";
import { MenuOrderItemAnalytics } from "pages/MenuOrderItemAnalytics";
import { MenuRecommendations } from "pages/MenuRecommendation";
import { MenuRecommendationMenus } from "pages/MenuRecommendationMenus";
import { Menus } from "pages/Menus";
import { MessageDeliveries } from "pages/MessageDeliveries";
import { MessageDeliveryAnalytics } from "pages/MessageDeliveryAnalytics";
import { MessageDeliveryJob } from "pages/MessageDeliveryJob";
import { MessageDeliveryJobs } from "pages/MessageDeliveryJobs";
import { MobileOrderDesignSettings } from "pages/MobileOrderDesignSettings";
import { NotFound } from "pages/NotFound/index";
import { OnboardingHandout } from "pages/OnboardingHandout";
import { OnSitePaymentDetailTypes } from "pages/OnSitePaymentDetailTypes";
import { OnSitePaymentDiscountTypes } from "pages/OnSitePaymentDiscountTypes";
import { Options } from "pages/Options";
import { OrderableTimeShops } from "pages/OrderableTimeShops";
import { PaymentReceiptRoleTablePrinting } from "pages/PaymentReceiptRoleTablePrinting";
import { PikaichiGifts } from "pages/PikaichiGifts";
import { PikaichiMenuOptions } from "pages/PikaichiMenuOptions";
import { PikaichiMenus } from "pages/PikaichiMenus";
import { PikaichiOnSitePaymentTypes } from "pages/PikaichiOnSitePaymentTypes";
import { PikaichiPlanOptions } from "pages/PikaichiPlanOptions";
import { PikaichiPlans } from "pages/PikaichiPlans";
import { PikaichiUpload } from "pages/PikaichiUpload";
import { PlanCategories } from "pages/PlanCategories";
import { PlanFirstOrderCategoryMenus } from "pages/PlanFirstOrderCategoryMenus";
import { PlanGroupPlans } from "pages/PlanGroupPlans";
import { PlanGroups } from "pages/PlanGroups";
import { PlanMainOptionPrintings } from "pages/PlanMainOptionPrintings";
import { PlanMenus } from "pages/PlanMenus";
import { PlanOptions } from "pages/PlanOptions";
import { Plans } from "pages/Plans";
import { PreviewUserApp } from "pages/PreviewUserApp";
import { QrCodes } from "pages/QrCodes";
import { QuestionnaireAnalyticsCommentsMetrics } from "pages/QuestionnaireAnalyticsCommentsMetrics";
import { QuestionnaireAnalyticsScoreChangesMetrics } from "pages/QuestionnaireAnalyticsScoreChangesMetrics";
import { QuestionnaireAnalyticsScoresMetrics } from "pages/QuestionnaireAnalyticsScoresMetrics";
import { QuestionnaireAnalyticsShopMetrics } from "pages/QuestionnaireAnalyticsShopMetrics";
import { QuestionnaireAnalyticsShopMetricsPerCategory } from "pages/QuestionnaireAnalyticsShopMetricsPerCategory";
import { QuestionnaireConfigs } from "pages/QuestionnaireConfigs";
import { RankingFaveYellAmounts } from "pages/RankingFaveYellAmounts";
import { RankingFaveYellCounts } from "pages/RankingFaveYellCounts";
import { RankingViralCounts } from "pages/RankingViralCounts";
import { RankingVisitedCounts } from "pages/RankingVisitedCounts";
import { Roles } from "pages/Roles";
import { SalesAnalytics } from "pages/SalesAnalytics";
import { SalesBudget } from "pages/SalesBudget";
import { SalesByDayofweekAndHourAnalytics } from "pages/SalesByDayofweekAndHourAnalytics";
import { ServiceAdmins } from "pages/ServiceAdmins";
import { ShopCashlessSetting } from "pages/ShopCashlessSetting";
import { ShopDailyCashRegisterBalancing } from "pages/ShopDailyCashRegisterBalancing";
import { ShopMenus } from "pages/ShopMenus";
import { ShopOrderableTimes } from "pages/ShopOrderableTimes";
import { ShopPlans } from "pages/ShopPlans";
import { Shops } from "pages/Shops";
import { SignIn } from "pages/SignIn";
import { SignOut } from "pages/SignOut";
import { SteraOnSitePaymentDetailTypes } from "pages/SteraOnSitePaymentDetailTypes";
import { Tables } from "pages/Tables";
import { TecAggregationGifts } from "pages/TecAggregationGifts";
import { TecAggregationMediaMaps } from "pages/TecAggregationMediaMaps";
import { TecAggregationMenuOptions } from "pages/TecAggregationMenuOptions";
import { TecAggregationMenus } from "pages/TecAggregationMenus";
import { TecAggregationOnSitePaymentDetailTypes } from "pages/TecAggregationOnSitePaymentDetailTypes";
import { TecAggregationPlanOptions } from "pages/TecAggregationPlanOptions";
import { TecAggregationPlans } from "pages/TecAggregationPlans";
import { TecAggregationUpload } from "pages/TecAggregationUpload";
import { WinboardGifts } from "pages/WinboardGifts";
import { WinboardInflowSourceTags } from "pages/WinboardInflowSourceTag";
import { WinboardMenuOptions } from "pages/WinboardMenuOptions";
import { WinboardMenuRegistrationCsv } from "pages/WinboardMenuRegistrationCsv/index";
import { WinboardMenus } from "pages/WinboardMenus";
import { WinboardPlanOptions } from "pages/WinboardPlanOptions";
import { WinboardPlans } from "pages/WinboardPlans";
import { WinboardUpload } from "pages/WinboardUpload";

export type RouteName = keyof typeof routes;

const routes = {
  accountingDetail: {
    path: "/accounting/:id",
    component: AccountingDetail,
  },
  accountingHistory: {
    path: "/accounting/history",
    component: AccountingHistory,
  },
  addCategory: {
    path: "/category/add",
    component: AddCategory,
  },
  addClerk: {
    path: "/clerk/add",
    component: AddClerk,
  },
  addCompany: {
    path: "/company/add",
    component: AddCompany,
  },
  addCorporation: {
    path: "/corporation/add",
    component: AddCorporation,
  },
  addCookingItem: {
    path: "/cookingItem/add",
    component: AddCookingItem,
  },
  addCoupon: {
    path: "/coupon/add",
    component: AddCoupon,
  },
  addDashboardAccount: {
    path: "/setting/account/add",
    component: AddDashboardAccount,
  },
  addDashboardAccountRole: {
    path: "/setting/accountRole/add",
    component: AddDashboardAccountRole,
  },
  addDishUpSlipGroup: {
    path: "/dishUpSlipGroup/add",
    component: AddDishUpSlipGroup,
  },
  addGift: {
    path: "/gift/add",
    component: AddGift,
  },
  addGiftChoice: {
    path: "/giftChoice/add",
    component: AddGiftChoice,
  },
  addGmoBankAccount: {
    path: "/gmoBankAccount/add",
    component: AddGmoBankAccount,
  },
  addKitchenDisplayDisplayTarget: {
    path: "/kitchenDisplayDisplayTarget/add",
    component: AddKitchenDisplayDisplayTarget,
  },
  addLineOfficialAccount: {
    path: "/lineOfficialAccounts/add",
    component: AddLineOfficialAccount,
  },
  addMenu: {
    path: "/menu/add",
    component: AddMenu,
  },
  addMessageDelivery: {
    path: "/messageDelivery/add",
    component: AddMessageDelivery,
  },
  addMenuRecommendation: {
    path: "/menuRecommendation/add",
    component: AddMenuRecommendation,
  },
  addOrderableTime: {
    path: "/orderableTime/add",
    component: AddOrderableTime,
  },
  addPlan: {
    path: "/plan/add",
    component: AddPlan,
  },
  addPlanGroup: {
    path: "/planGroup/add",
    component: AddPlanGroup,
  },
  addRole: {
    path: "/role/add",
    component: AddRole,
  },
  addServiceAdmin: {
    path: "/serviceAdmin/add",
    component: AddServiceAdmin,
  },
  addShop: {
    path: "/shop/add",
    component: AddShop,
  },
  addTableArea: {
    path: "/table/area/add",
    component: AddTableArea,
  },
  addTable: {
    path: "/table/add",
    component: AddTable,
  },
  analyticsSettings: {
    path: "/analytics-settings",
    component: AnalyticsSettings,
  },
  categories: {
    path: "/category",
    component: Categories,
  },
  clearTableHistory: {
    path: "/clearTableHistory",
    component: ClearTableHistory,
  },
  clerks: {
    path: "/clerk",
    component: Clerks,
  },
  coineySales: {
    path: "/coiney/sale",
    component: CoineySales,
  },
  companies: {
    path: "/company",
    component: Companies,
  },
  corporations: {
    path: "/corporation",
    component: Corporations,
  },
  cookingItems: {
    path: "/cookingItem",
    component: CookingItems,
  },
  coupons: {
    path: "/coupon",
    component: Coupons,
  },
  couponAnalytics: {
    path: "/couponAnalytics",
    component: CouponAnalytics,
  },
  lineOfficialAccounts: {
    path: "/lineOfficialAccounts",
    component: LineOfficialAccounts,
  },
  currentSales: {
    path: "/currentSales",
    component: CurrentSales,
  },
  customerDashboards: {
    path: "/customerDashboard",
    component: CustomerDashboards,
  },
  dailyCashRegisterBalancing: {
    path: "/dailyCashRegisterBalancing",
    component: DailyCashRegisterBalancing,
  },
  dashboard: {
    path: "/",
    component: Dashboard,
  },
  dashboardAccounts: {
    path: "/setting/account",
    component: DashboardAccounts,
  },
  dashboardAccountRoles: {
    path: "/setting/accountRole",
    component: DashboardAccountRoles,
  },
  excludeFromAggregation: {
    path: "/excludeFromAggregation",
    component: ExcludeFromAggregation,
  },
  dishUpSlipGroupMenus: {
    path: "/dishUpSlipGroup/:id/menu/*",
    component: DishUpSlipGroupMenus,
  },
  dishUpSlipGroupPlans: {
    path: "/dishUpSlipGroup/:id/plan/*",
    component: DishUpSlipGroupPlans,
  },
  dishUpSlipGroups: {
    path: "/dishUpSlipGroup",
    component: DishUpSlipGroups,
  },
  editEbicaConfig: {
    path: "/ebica/config",
    component: EditEbicaConfig,
  },
  ebicaTables: {
    path: "/ebica/table",
    component: EbicaTables,
  },
  editCashRegisterConfig: {
    path: "/shop/:id/cashRegisterConfig/edit/*",
    component: EditCashRegisterConfig,
  },
  editCategory: {
    path: "/category/:id/edit",
    component: EditCategory,
  },
  editCategoryPriorities: {
    path: "/category/priority/edit",
    component: EditCategoryPriorities,
  },
  editCategoryTranslation: {
    path: "/category/:id/translation/edit",
    component: EditCategoryTranslations,
  },

  editClerk: {
    path: "/clerk/:id/edit",
    component: EditClerk,
  },
  editClerkPriorities: {
    path: "/clerk/priority/edit",
    component: EditClerkPriorities,
  },
  editCookingItem: {
    path: "/cookingItem/:id/edit",
    component: EditCookingItem,
  },
  editCompany: {
    path: `/company/:id/edit`,
    component: EditCompany,
  },
  editCorporation: {
    path: `/corporation/:id/edit`,
    component: EditCorporation,
  },
  editCoupon: {
    path: "/coupon/:id/edit",
    component: EditCoupon,
  },
  editDashboardAccount: {
    path: "/setting/account/:id/edit",
    component: EditDashboardAccount,
  },
  editDashboardAccountRole: {
    path: "/setting/accountRole/:id/edit",
    component: EditDashboardAccountRole,
  },
  editDefaultTaxMethods: {
    path: "/defaultTaxMethods/edit",
    component: EditDefaultTaxMethods,
  },
  editDishUpSlipGroup: {
    path: "/dishUpSlipGroup/:id/edit",
    component: EditDishUpSlipGroup,
  },
  editGift: {
    path: "/gift/:id/edit",
    component: EditGift,
  },
  editGiftChoice: {
    path: "/giftChoice/:id/edit",
    component: EditGiftChoice,
  },
  editGiftPriorities: {
    path: "/gift/priority/edit",
    component: EditGiftPriorities,
  },
  editGmoBankAccount: {
    path: "/gmoBankAccount/:id/edit",
    component: EditGmoBankAccount,
  },
  editGoogleMapConfig: {
    path: "/shop/:id/googleMapConfig/edit",
    component: EditGoogleMapConfig,
  },
  editDiniiPayConfig: {
    path: "/shop/:id/diniiPayConfig/edit",
    component: EditDiniiPayConfig,
  },
  editFoodingJournalConfig: {
    path: "/foodingJournal/config",
    component: EditFoodingJournalConfig,
  },
  editTecAggregationConfig: {
    path: "/tec/config/:integrationType",
    component: EditTecAggregationConfig,
  },
  editTecAggregationMenu: {
    path: "/tec/menu/:id/edit",
    component: EditTecAggregationMenu,
  },
  editTecAggregationPlan: {
    path: "/tec/plan/:id/edit",
    component: EditTecAggregationPlan,
  },
  editTecAggregationOnSitePaymentDetailType: {
    path: "/tec/detailType/:type/edit",
    component: EditTecAggregationOnSitePaymentDetailType,
  },
  editKitchenDisplayDisplayTarget: {
    path: `/kitchenDisplayDisplayTarget/:id/edit`,
    component: EditKitchenDisplayDisplayTarget,
  },
  editLineChannelConfig: {
    path: "/shop/:id/lineChannelConfig/edit",
    component: EditLineChannelConfig,
  },
  editLineOfficialAccount: {
    path: "/lineOfficialAccounts/:id/edit",
    component: EditLineOfficialAccount,
  },
  editMenu: {
    path: "/menu/:id/edit",
    component: EditMenu,
  },
  editMenuDealers: {
    path: "/menu/:id/dealer/edit/*",
    component: EditMenuDealers,
  },
  editMenuTranslation: {
    path: "/menu/:id/translation/edit",
    component: EditMenuTranslations,
  },
  editMenuPikaichiMenu: {
    path: "/menu/:id/pikaichi/edit",
    component: EditMenuPikaichiMenu,
  },
  editMenuTecAggregationMenu: {
    path: "/menu/:id/tec/edit",
    component: EditMenuTecAggregationMenu,
  },
  editMenuWinboardMenu: {
    path: "/menu/:id/winboard/edit",
    component: EditMenuWinboardMenu,
  },
  editMenuPriorities: {
    path: "/menu/priority/edit",
    component: EditMenuPriorities,
  },
  editMenuRecommendation: {
    path: "/menuRecommendation/:menuRecommendationMetaId/edit",
    component: EditMenuRecommendation,
  },
  editMenuRecommendationTranslation: {
    path: "/menuRecommendation/:menuRecommendationMetaId/translation/edit",
    component: EditMenuRecommendationTranslations,
  },
  editMenusBulk: {
    path: "/menu/edit/bulk/*",
    component: EditMenusBulk,
  },
  editMessageDelivery: {
    path: "/messageDelivery/:id/edit",
    component: EditMessageDelivery,
  },
  editOrderableTime: {
    path: `/orderableTime/:id/shop/:shopId/edit`,
    component: EditOrderableTime,
  },
  editPikaichiConfig: {
    path: "/pikaichi/config",
    component: EditPikaichiConfig,
  },
  editPikaichiMenu: {
    path: "/pikaichi/menu/:id/edit",
    component: EditPikaichiMenu,
  },
  editPikaichiOnSitePaymentDetailType: {
    path: `/pikaichi/onSitePaymentDetailType/:type/edit`,
    component: EditPikaichiOnSitePaymentDetailType,
  },
  editPikaichiOnSitePaymentDiscountType: {
    path: `/pikaichi/onSitePaymentDiscountType/:type/edit`,
    component: EditPikaichiOnSitePaymentDiscountType,
  },
  editPikaichiPlan: {
    path: "/pikaichi/plan/:id/edit",
    component: EditPikaichiPlan,
  },
  editPlan: {
    path: "/plan/:id/edit",
    component: EditPlan,
  },
  editPlanDealers: {
    path: "/plan/:id/dealer/edit",
    component: EditPlanDealers,
  },
  editPlanGroup: {
    path: "/planGroup/:id/edit",
    component: EditPlanGroup,
  },
  editPlanPikaichiPlan: {
    path: "/plan/:id/pikaichi/edit",
    component: EditPlanPikaichiPlan,
  },
  editPlanPriorities: {
    path: "/plan/priority/edit",
    component: EditPlanPriorities,
  },
  editPlanTecAggregationPlan: {
    path: "/plan/:id/tec/edit",
    component: EditPlanTecAggregationPlan,
  },
  editPlanTranslation: {
    path: "/plan/:id/translation/edit",
    component: EditPlanTranslations,
  },
  editPlanWinboardPlan: {
    path: "/plan/:id/winboard/edit",
    component: EditPlanWinboardPlan,
  },
  editRole: {
    path: "/role/:id/edit",
    component: EditRole,
  },
  editServiceAdmin: {
    path: `/serviceAdmin/:id/edit`,
    component: EditServiceAdmin,
  },
  editShop: {
    path: "/shop/:id/edit",
    component: EditShop,
  },
  editShopMenuOutput: {
    path: `/shop/:shopId/menu/:menuId/output/edit/*`,
    component: EditShopMenuOutput,
  },
  editShopMenuStock: {
    path: `/shop/:shopId/menu/:menuId/stock/edit`,
    component: EditShopMenuStock,
  },
  editShopPlanOptionRoles: {
    path: `/shop/:shopId/mainOption/:planOptionId/role/edit`,
    component: EditShopPlanOptionRoles,
  },
  editShopPlanRoles: {
    path: `/shop/:shopId/plan/:planId/role/edit`,
    component: EditShopPlanRoles,
  },
  editTableArea: {
    path: "/table/area/:id/edit",
    component: EditTableArea,
  },
  editTable: {
    path: "/table/:id/edit",
    component: EditTable,
  },
  editInflowSourceTags: {
    path: `/inflowSourceTag/:id/edit`,
    component: EditInflowSourceTag,
  },
  editInflowSourceTagPriorities: {
    path: "/inflowSourceTag/priority/edit",
    component: EditInflowSourceTagPriorities,
  },
  editOnSitePaymentDetailTypes: {
    path: `/onSitePaymentDetailType/:id/edit`,
    component: EditOnSitePaymentDetailType,
  },
  editOnSitePaymentDetailTypesPriorities: {
    path: "/onSitePaymentDetailType/priority/edit",
    component: EditOnSitePaymentDetailTypePriorities,
  },
  editOnSitePaymentDiscountTypes: {
    path: `/onSitePaymentDiscountType/:id/edit`,
    component: EditOnSitePaymentDiscountType,
  },
  editOnSitePaymentDiscountTypesPriorities: {
    path: "/onSitePaymentDiscountType/priority/edit",
    component: EditOnSitePaymentDiscountTypePriorities,
  },
  editWinboardConfig: {
    path: "/winboard/config",
    component: EditWinboardConfig,
  },
  editWinboardInflowSourceTag: {
    path: "/winboard/inflowSourceTag/:id/edit",
    component: EditWinboardInflowSourceTag,
  },
  editWinboardMenu: {
    path: "/winboard/menu/:id/edit",
    component: EditWinboardMenu,
  },
  editWinboardPlan: {
    path: "/winboard/plan/:id/edit",
    component: EditWinboardPlan,
  },
  exportDailyAggregatedData: {
    path: "/aggregatedData/daily/export",
    component: ExportDailyAggregatedData,
  },
  exportDailyAggregatedDataV2: {
    path: "/aggregatedData/daily/export/v2",
    component: ExportDailyAggregatedDataV2,
  },
  faveYell: {
    path: "/fave-yell-analytics",
    component: FaveYellAnalytics,
  },
  featureFlags: {
    path: "/shop/:id/featureFlag",
    component: FeatureFlags,
  },
  generateQrCodes: {
    path: "/qrCode/generate",
    component: GenerateQrCodes,
  },
  gifts: {
    path: "/gift",
    component: Gifts,
  },
  giftChoices: {
    path: "/giftChoice",
    component: GiftChoices,
  },
  handouts: {
    path: "/handout",
    component: Handouts,
  },
  inflowSourceTags: {
    path: "/inflowSourceTag",
    component: InflowSourceTags,
  },
  foodingJournalUpload: {
    path: "/foodingJournal/upload",
    component: FoodingJournalUpload,
  },
  foodingJournalMenus: {
    path: "/foodingJournal/menu",
    component: FoodingJournalMenus,
  },
  foodingJournalPlans: {
    path: "/foodingJournal/plan",
    component: FoodingJournalPlans,
  },
  foodingJournalMasters: {
    path: "/foodingJournal/masters",
    component: FoodingJournalMasters,
  },
  foodingJournalOnSitePaymentDetailTypes: {
    path: "/foodingJournal/onSitePaymentDetailTypes",
    component: FoodingJournalOnSitePaymentDetailTypes,
  },
  foodingJournalOnSitePaymentDiscountTypes: {
    path: "/foodingJournal/onSitePaymentDiscountTypes",
    component: FoodingJournalOnSitePaymentDiscountTypes,
  },
  tecUpload: {
    path: "/tec/upload",
    component: TecAggregationUpload,
  },
  tecAggregationMediaMaps: {
    path: "/tec/mediaMap/*",
    component: TecAggregationMediaMaps,
  },
  tecAggregationMenus: {
    path: "/tec/menu",
    component: TecAggregationMenus,
  },
  tecAggregationMenuOptions: {
    path: "/tec/menu/:id/option/*",
    component: TecAggregationMenuOptions,
  },
  tecAggregationDetailTypes: {
    path: "/tec/detailType",
    component: TecAggregationOnSitePaymentDetailTypes,
  },
  tecPlans: {
    path: "/tec/plan",
    component: TecAggregationPlans,
  },
  tecPlanOptions: {
    path: "/tec/plan/:id/option/*",
    component: TecAggregationPlanOptions,
  },
  tecAggregationGifts: {
    path: "/tec/gift",
    component: TecAggregationGifts,
  },
  kitchenDisplayDisplayTargetMenus: {
    path: `/kitchenDisplayDisplayTarget/:id/menu/*`,
    component: KitchenDisplayDisplayTargetMenus,
  },
  kitchenDisplayDisplayTargets: {
    path: "/kitchenDisplayDisplayTarget",
    component: KitchenDisplayDisplayTargets,
  },
  menuOptions: {
    path: "/menu/:id/option",
    component: MenuOptions,
  },
  menus: {
    path: "/menu",
    component: Menus,
  },
  menuAutoCompulsoryAppetizer: {
    path: "/menu/autoCompulsoryAppetizer",
    component: MenuAutoCompulsoryAppetizer,
  },
  menuAutoCompulsoryAppetizerModal: {
    path: "/menu/autoCompulsoryAppetizer/:modalRoute",
    component: MenuAutoCompulsoryAppetizer,
  },
  menuMasterCsv: {
    path: "/menuMasterCsv",
    component: MenuMasterCsv,
  },
  messageDeliveries: {
    path: "/messageDelivery/:state",
    component: MessageDeliveries,
  },
  messageDeliveryJob: {
    path: "/messageDelivery/job/:id",
    component: MessageDeliveryJob,
  },
  messageDeliveryJobs: {
    path: "/messageDelivery/job",
    component: MessageDeliveryJobs,
  },
  messageDeliveryAnalytics: {
    path: "/messageDeliveryAnalytics",
    component: MessageDeliveryAnalytics,
  },
  menuRecommendations: {
    path: "/menuRecommendations",
    component: MenuRecommendations,
  },
  menuRecommendationMenus: {
    path: "/menuRecommendation/:menuRecommendationMetaId/menus/*",
    component: MenuRecommendationMenus,
  },
  mobileOrderDesign: {
    path: "/mobileOrderDesign/:state",
    component: MobileOrderDesignSettings,
  },
  options: {
    path: "/option",
    component: Options,
  },
  menuOrderItemAnalytics: {
    path: "/product-analytics",
    component: MenuOrderItemAnalytics,
  },
  salesAnalytics: {
    path: "/sales-analytics",
    component: SalesAnalytics,
  },
  salesByDayofweekAndHourAnalytics: {
    path: "/sales-by-dayofweek-and-hour-analytics",
    component: SalesByDayofweekAndHourAnalytics,
  },
  companySalesAnalytics: {
    path: "/company-sales-analytics",
    component: CompanySalesAnalytics,
  },
  addOption: {
    path: "/option/add",
    component: AddOption,
  },
  editOption: {
    path: "/option/:id/edit",
    component: EditOption,
  },
  editOptionTranslation: {
    path: "/option/:id/translation/edit",
    component: EditOptionTranslations,
  },
  editOptionDealer: {
    path: "/option/:id/dealer/edit/*",
    component: EditOptionDealers,
  },
  editOptionPikaichiOption: {
    path: "/option/:id/pikaichi/edit",
    component: EditOptionPikaichiOption,
  },
  editOptionTecAggregationOption: {
    path: "/option/:id/tec/edit",
    component: EditOptionTecAggregationOption,
  },
  editOptionWinboardOption: {
    path: "/option/:id/winboard/edit",
    component: EditOptionWinboardOption,
  },
  onboardingHandout: {
    path: "/onboardingHandout",
    component: OnboardingHandout,
  },
  onSitePaymentDetailTypes: {
    path: "/onSitePaymentDetailType",
    component: OnSitePaymentDetailTypes,
  },
  onSitePaymentDiscountTypes: {
    path: "/onSitePaymentDiscountType",
    component: OnSitePaymentDiscountTypes,
  },
  steraOnSitePaymentDetailTypes: {
    path: "/steraOnSitePaymentDetailType",
    component: SteraOnSitePaymentDetailTypes,
  },
  gmoOffPaymentOnSitePaymentDetailTypes: {
    path: "/offlineTerminalOnSitePaymentDetailType",
    component: GmoTerminalOnSitePaymentDetailTypes,
  },
  adyenTerminalPaymentOnSitePaymentDetailTypes: {
    path: "/adyenTerminalOnSitePaymentDetailType",
    component: AdyenTerminalOnSitePaymentDetailTypes,
  },
  adyenTerminalPaymentSignIn: {
    path: "/terminalPayment/signIn",
    component: AdyenTerminalPaymentSignIn,
  },
  adyenTerminalPaymentSignOut: {
    path: "/terminalPayment/signOut",
    component: AdyenTerminalPaymentSignOut,
  },
  adyenTerminalPaymentHistory: {
    path: "/terminalPayment/history",
    component: AdyenTerminalPaymentHistory,
  },
  adyenTerminalPaymentDepositHistory: {
    path: "/terminalPayment/depositHistory",
    component: AdyenPaymentDepositHistory,
  },
  adyenTerminalPaymentDepositHistoryByShop: {
    path: "/terminalPayment/depositHistory/:id",
    component: AdyenPaymentDepositHistoryByShop,
  },
  pikaichiGifts: {
    path: "/pikaichi/gift",
    component: PikaichiGifts,
  },
  pikaichiMenuOptions: {
    path: "/pikaichi/menu/:id/option/*",
    component: PikaichiMenuOptions,
  },
  pikaichiMenus: {
    path: "/pikaichi/menu",
    component: PikaichiMenus,
  },
  pikaichiOnSitePaymentTypes: {
    path: "/pikaichi/onSitePaymentTypes",
    component: PikaichiOnSitePaymentTypes,
  },
  pikaichiPlanOptions: {
    path: "/pikaichi/plan/:id/option/*",
    component: PikaichiPlanOptions,
  },
  pikaichiPlans: {
    path: "/pikaichi/plan",
    component: PikaichiPlans,
  },
  pikaichiUpload: {
    path: "/pikaichi/upload",
    component: PikaichiUpload,
  },
  planCategories: {
    path: "/plan/:id/category/*",
    component: PlanCategories,
  },
  planFirstOrderCategoryMenus: {
    path: "/plan/:id/firstOrderCategoryMenu/*",
    component: PlanFirstOrderCategoryMenus,
  },
  planGroupPlans: {
    path: "/planGroup/:id/plan/*",
    component: PlanGroupPlans,
  },
  planGroups: {
    path: "/planGroup",
    component: PlanGroups,
  },
  planMainOptionPrintings: {
    path: "/printing/mainOption",
    component: PlanMainOptionPrintings,
  },
  planMenus: {
    path: "/plan/:id/menu",
    component: PlanMenus,
  },
  planOptions: {
    path: "/plan/:id/option/*",
    component: PlanOptions,
  },
  plans: {
    path: "/plan",
    component: Plans,
  },
  previewUserApp: {
    path: "/previewUserApp",
    component: PreviewUserApp,
  },
  qrCodes: {
    path: "/qrCode",
    component: QrCodes,
  },
  questionnaireAnalyticsScoresMetrics: {
    path: "/questionnaireAnalytics/score",
    component: QuestionnaireAnalyticsScoresMetrics,
  },
  questionnaireAnalyticsShopMetricsPerCategory: {
    path: "/questionnaireAnalytics/shop/:category",
    component: QuestionnaireAnalyticsShopMetricsPerCategory,
  },
  questionnaireAnalyticsShopMetrics: {
    path: "/questionnaireAnalytics/shop",
    component: QuestionnaireAnalyticsShopMetrics,
  },
  rankingFaveYellAmounts: {
    path: "/ranking/faveYellAmount",
    component: RankingFaveYellAmounts,
    exact: true,
  },
  rankingFaveYellCounts: {
    path: "/ranking/faveYellCount",
    component: RankingFaveYellCounts,
    exact: true,
  },
  rankingViralCounts: {
    path: "/ranking/viralCount",
    component: RankingViralCounts,
    exact: true,
  },
  rankingVisitedCounts: {
    path: "/ranking/visitedCount",
    component: RankingVisitedCounts,
  },
  roles: {
    path: "/role",
    component: Roles,
  },
  serviceAdmins: {
    path: "/serviceAdmin",
    component: ServiceAdmins,
  },
  shopDailyCashRegisterBalancing: {
    path: `/dailyCashRegisterBalancing/:shopId/:date/*`,
    component: ShopDailyCashRegisterBalancing,
  },
  shops: {
    path: "/shop",
    component: Shops,
  },
  signIn: {
    path: "/signIn",
    component: SignIn,
  },
  signOut: {
    path: "/signOut",
    component: SignOut,
  },
  tables: {
    path: "/table",
    component: Tables,
  },
  inputMasterData: {
    path: "/inputMasterData",
    component: InputMasterData,
  },
  inputShopData: {
    path: "/inputShopData",
    component: InputShopData,
  },
  copyMasterData: {
    path: "/copyMasterData",
    component: CopyMasterData,
  },
  copyDealingData: {
    path: "/copyDealingData",
    component: CopyDealingData,
  },
  invalidAccount: {
    path: "/invalidAccount",
    component: InvalidAccount,
  },
  convertTecFormat: {
    path: "/convertTecFormat",
    component: ConvertTecFormat,
  },
  addOnSitePaymentDiscountType: {
    path: "/onSitePaymentDiscountType/add",
    component: AddOnSitePaymentDiscountType,
  },
  addOnSitePaymentDetailType: {
    path: "/onSitePaymentDetailType/add",
    component: AddOnSitePaymentDetailType,
  },
  addInflowSourceTag: {
    path: "/inflowSourceTag/add",
    component: AddInflowSourceTag,
  },
  winboardInflowSourceTags: {
    path: "/winboard/inflowSourceTag",
    component: WinboardInflowSourceTags,
  },
  winboardGifts: {
    path: "/winboard/gift",
    component: WinboardGifts,
  },
  winboardMenuRegistrationCsv: {
    path: "/winboard/menuRegistrationCsv",
    component: WinboardMenuRegistrationCsv,
  },
  winboardMenus: {
    path: "/winboard/menu",
    component: WinboardMenus,
  },
  winboardMenuOptions: {
    path: "/winboard/menu/:id/option/*",
    component: WinboardMenuOptions,
  },
  winboardPlans: {
    path: "/winboard/plan",
    component: WinboardPlans,
  },
  winboardPlanOptions: {
    path: "/winboard/plan/:id/option/*",
    component: WinboardPlanOptions,
  },
  winboardUpload: {
    path: "/winboard/upload",
    component: WinboardUpload,
  },
  orderableTimeShops: {
    path: `/orderableTime/:id/shop`,
    component: OrderableTimeShops,
  },
  shopOrderableTimes: {
    path: `/orderableTime/shop`,
    component: ShopOrderableTimes,
  },
  shopMenus: {
    path: "/shop/menu",
    component: ShopMenus,
  },
  shopPlans: {
    path: "/shop/plan",
    component: ShopPlans,
  },
  kitchenSlipTablePrinting: {
    path: "/tablePrinting/kitchenSlip",
    component: KitchenSlipTablePrinting,
  },
  dishUpSlipGroupTablePrinting: {
    path: "/tablePrinting/dishUpSlipGroup",
    component: DishUpSlipGroupTablePrinting,
  },
  paymentReceiptRoleTablePrinting: {
    path: "/tablePrinting/paymentReceiptRole",
    component: PaymentReceiptRoleTablePrinting,
  },
  editMembershipCardAppearanceType: {
    path: "/membershipCard/appearanceType/edit",
    component: EditMembershipCardAppearanceType,
  },
  membershipRankConfig: {
    path: "/membershipCard/rankConfig",
    component: MembershipRankConfig,
  },
  editMembershipRankConfig: {
    path: "/membershipCard/rankConfig/:id/edit",
    component: EditMembershipRankConfig,
  },
  externalOnlineMenus: {
    path: "/externalOnlineMenu",
    component: ExternalOnlineMenus,
  },
  editShopExternalOnlineMenuConfig: {
    path: "/shop/:shopId/externalOnlineMenuConfig",
    component: EditShopExternalOnlineMenuConfig,
  },
  addShopExternalOnlineMenu: {
    path: "/shop/:shopId/externalOnlineMenu/add",
    component: AddShopExternalOnlineMenu,
  },
  editShopExternalOnlineMenu: {
    path: "/shop/:shopId/externalOnlineMenu/:externalOnlineMenuId/edit",
    component: EditShopExternalOnlineMenu,
  },
  questionnaireConfigs: {
    path: "/questionnaireConfig",
    component: QuestionnaireConfigs,
  },
  addQuestionnaireConfig: {
    path: "/questionnaireConfig/add",
    component: AddQuestionnaireConfig,
  },
  editQuestionnaireConfig: {
    path: "/questionnaireConfig/:id/edit",
    component: EditQuestionnaireConfig,
  },
  editShopBusinessOperationHour: {
    path: "/shop/:id/businessOperationHour/edit",
    component: EditShopBusinessOperationHour,
  },
  questionnaireAnalyticsScoreChangesMetrics: {
    path: "/questionnaireAnalytics/scoreChanges",
    component: QuestionnaireAnalyticsScoreChangesMetrics,
  },
  questionnaireAnalyticsCommentsMetrics: {
    path: "/questionnaireAnalytics/comment",
    component: QuestionnaireAnalyticsCommentsMetrics,
  },
  connectGameConfigs: {
    path: "/connectGameConfig",
    component: ConnectGameConfigs,
  },
  addConnectGameConfig: {
    path: "/connectGameConfig/add",
    component: AddConnectGameConfig,
  },
  editConnectGameConfig: {
    path: "/connectGameConfig/:id/edit",
    component: EditConnectGameConfig,
  },
  lineReportingBotConfigs: {
    path: "/lineReportingBotConfig/:state",
    component: LineReportingBotConfigs,
  },
  addLineReportingBotConfig: {
    path: "/lineReportingBotConfig/add",
    component: AddLineReportingBotConfig,
  },
  editLineReportingBotConfig: {
    path: "/lineReportingBotConfig/:id/edit",
    component: EditLineReportingBotConfig,
  },
  autoTranslation: {
    path: "/autoTranslation",
    component: AutoTranslation,
  },
  salesBudget: {
    path: "/salesBudget",
    component: SalesBudget,
  },
  addSalesBudget: {
    path: "/salesBudget/add",
    component: AddSalesBudget,
  },
  editSalesBudget: {
    path: "/salesBudget/:id/edit",
    component: EditSalesBudget,
  },
  editShopMenusBulk: {
    path: "/shopMenu/edit/bulk/*",
    component: EditShopMenusBulk,
  },
  editShopPlansBulk: {
    path: "/shopPlan/edit/bulk/*",
    component: EditShopPlansBulk,
  },
  lineReportingImages: {
    path: "/lineReportingImages",
    component: LineReportingImages,
  },
  menuCodeCsv: {
    path: "/menuCodeCsv/:biType",
    component: MenuCodeCsv,
  },
  shopCashlessSetting: {
    path: "/cashlessSetting/shop",
    component: ShopCashlessSetting,
  },
  editShopCashlessSettingStoreMeta: {
    path: "/cashlessSetting/shop/:id/storeMeta/edit",
    component: EditShopCashlessSettingStoreMeta,
  },
  editShopCashlessSettingProviderSetting: {
    path: "/cashlessSetting/shop/:id/providerSetting/edit",
    component: EditShopCashlessSettingProviderSetting,
  },
  bankAccountCashlessSetting: {
    path: "/cashlessSetting/bankAccount",
    component: BankAccountCashlessSetting,
  },
};

const routeEntries = Object.entries(routes)
  .sort(([, a], [, b]) => b.path.length - a.path.length)
  .map(([name, props]) => [name as RouteName, props] as const);

const Layout = ({ children }: React.PropsWithChildren<object>) => {
  const { pathname } = useLocation();

  if ([routes.signIn.path, routes.adyenTerminalPaymentSignIn.path].includes(pathname)) {
    return <>{children}</>;
  }

  return <NavigationLayout>{children}</NavigationLayout>;
};

export const Router = () => (
  <Layout>
    <Routes>
      {routeEntries.map(([name, { path, component: Component }]) => {
        const element = <PageElement route={name} component={() => <Component />} />;
        return <Route key={path} path={path} element={element} />;
      })}
      <Route element={<NotFound />} />
    </Routes>
  </Layout>
);
