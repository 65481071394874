import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditGoogleMapConfigGetConfig = gql`
    query EditGoogleMapConfigGetConfig($shopId: uuid!) {
  shop: shop_by_pk(shopId: $shopId) {
    shopId
    name
    enableGoogleMapReview
    googleMapPlaceId
  }
}
    `;
export const EditGoogleMapConfigUpdateGoogleMapConfig = gql`
    mutation EditGoogleMapConfigUpdateGoogleMapConfig($input: EditGoogleMapConfigInput!) {
  dashboardAccountEditGoogleMapConfig(input: $input) {
    result
  }
}
    `;
export type EditGoogleMapConfigGetConfigQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditGoogleMapConfigGetConfigQuery = (
  { __typename?: 'query_root' }
  & { shop?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'name' | 'enableGoogleMapReview' | 'googleMapPlaceId'>
  )> }
);

export type EditGoogleMapConfigUpdateGoogleMapConfigMutationVariables = Types.Exact<{
  input: Types.EditGoogleMapConfigInput;
}>;


export type EditGoogleMapConfigUpdateGoogleMapConfigMutation = (
  { __typename?: 'mutation_root' }
  & { dashboardAccountEditGoogleMapConfig: (
    { __typename?: 'EditGoogleMapConfigOutput' }
    & Pick<Types.EditGoogleMapConfigOutput, 'result'>
  ) }
);


export const EditGoogleMapConfigGetConfigDocument = gql`
    query EditGoogleMapConfigGetConfig($shopId: uuid!) {
  shop: shop_by_pk(shopId: $shopId) {
    shopId
    name
    enableGoogleMapReview
    googleMapPlaceId
  }
}
    `;

/**
 * __useEditGoogleMapConfigGetConfigQuery__
 *
 * To run a query within a React component, call `useEditGoogleMapConfigGetConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditGoogleMapConfigGetConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditGoogleMapConfigGetConfigQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditGoogleMapConfigGetConfigQuery(baseOptions: Apollo.QueryHookOptions<EditGoogleMapConfigGetConfigQuery, EditGoogleMapConfigGetConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditGoogleMapConfigGetConfigQuery, EditGoogleMapConfigGetConfigQueryVariables>(EditGoogleMapConfigGetConfigDocument, options);
      }
export function useEditGoogleMapConfigGetConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditGoogleMapConfigGetConfigQuery, EditGoogleMapConfigGetConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditGoogleMapConfigGetConfigQuery, EditGoogleMapConfigGetConfigQueryVariables>(EditGoogleMapConfigGetConfigDocument, options);
        }
export type EditGoogleMapConfigGetConfigQueryHookResult = ReturnType<typeof useEditGoogleMapConfigGetConfigQuery>;
export type EditGoogleMapConfigGetConfigLazyQueryHookResult = ReturnType<typeof useEditGoogleMapConfigGetConfigLazyQuery>;
export type EditGoogleMapConfigGetConfigQueryResult = Apollo.QueryResult<EditGoogleMapConfigGetConfigQuery, EditGoogleMapConfigGetConfigQueryVariables>;
export const EditGoogleMapConfigUpdateGoogleMapConfigDocument = gql`
    mutation EditGoogleMapConfigUpdateGoogleMapConfig($input: EditGoogleMapConfigInput!) {
  dashboardAccountEditGoogleMapConfig(input: $input) {
    result
  }
}
    `;
export type EditGoogleMapConfigUpdateGoogleMapConfigMutationFn = Apollo.MutationFunction<EditGoogleMapConfigUpdateGoogleMapConfigMutation, EditGoogleMapConfigUpdateGoogleMapConfigMutationVariables>;

/**
 * __useEditGoogleMapConfigUpdateGoogleMapConfigMutation__
 *
 * To run a mutation, you first call `useEditGoogleMapConfigUpdateGoogleMapConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditGoogleMapConfigUpdateGoogleMapConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editGoogleMapConfigUpdateGoogleMapConfigMutation, { data, loading, error }] = useEditGoogleMapConfigUpdateGoogleMapConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditGoogleMapConfigUpdateGoogleMapConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditGoogleMapConfigUpdateGoogleMapConfigMutation, EditGoogleMapConfigUpdateGoogleMapConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditGoogleMapConfigUpdateGoogleMapConfigMutation, EditGoogleMapConfigUpdateGoogleMapConfigMutationVariables>(EditGoogleMapConfigUpdateGoogleMapConfigDocument, options);
      }
export type EditGoogleMapConfigUpdateGoogleMapConfigMutationHookResult = ReturnType<typeof useEditGoogleMapConfigUpdateGoogleMapConfigMutation>;
export type EditGoogleMapConfigUpdateGoogleMapConfigMutationResult = Apollo.MutationResult<EditGoogleMapConfigUpdateGoogleMapConfigMutation>;
export type EditGoogleMapConfigUpdateGoogleMapConfigMutationOptions = Apollo.BaseMutationOptions<EditGoogleMapConfigUpdateGoogleMapConfigMutation, EditGoogleMapConfigUpdateGoogleMapConfigMutationVariables>;