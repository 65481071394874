import React, { memo } from "react";
import { Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

import { FormItemProps } from "components/antd/Form";
import { Select } from "components/Input/Select";

import { Coupon } from "../../types";
import { EditQuestionnaireConfigFormItem } from "../useEditQuestionnaireConfigForm";

type Props = { coupons: Coupon[] } & Omit<FormItemProps, "children" | "name">;

export const CouponSelectField = memo<Props>(({ coupons, ...props }) => (
  <EditQuestionnaireConfigFormItem label="クーポン" name="rewardCouponId" {...props}>
    <Select
      options={coupons.map(({ id, name }) => ({ label: name, value: id }))}
      showSearch
      optionFilterProp="label"
      allowClear
      fullWidth
      notFoundContent={
        <Paragraph type="secondary">
          選択できるクーポンが存在しません。
          <Typography.Link href="/coupon/add" target="_blank">
            クーポン作成画面
          </Typography.Link>
          にてクーポンを作成してください。
        </Paragraph>
      }
    />
  </EditQuestionnaireConfigFormItem>
));
