import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Alert } from "antd";
import dayjs from "dayjs";
import { businessOperationHourTypeToWord } from "models/businessOperationHour";
import { isNotNull } from "util/type/primitive";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useAnalyticsSetting } from "hooks/useAnalyticsSetting";
import { useCompany } from "hooks/useCompany";
import { useCompanySalesAnalytics } from "hooks/useCompanySalesAnalytics";
import { CompanySalesAnalyticsRow } from "hooks/useCompanySalesAnalytics/types";
import { useCorporation } from "hooks/useCorporation";
import { useFilterConditions } from "hooks/useFilterConditions";
import { deserializeRange, serializeRange } from "hooks/useFilterConditions/rangeTransformer";
import { usePagination } from "hooks/usePagination";

import pkg from "../../../package.json";

import { getColumnDefinitions } from "./CompanySalesAnalyticsTable/companySalesAnalyticsColumns";
import { ColumnSelectModal } from "./ColumnSelectModal";
import { exportCompanySalesAnalyticsCsv } from "./companySalesAnalyticsCsvExport";
import { CompanySalesAnalyticsTable } from "./CompanySalesAnalyticsTable";
import { FilterConditions, Filters, SerializedFilterConditions } from "./Filters";
import {
  useCompanySalesAnalyticsGetBusinessOperationHourTypesQuery,
  useCompanySalesAnalyticsGetShopsQuery,
} from "./queries";

const version = pkg.version;

const companySalesAnalyticsSelectedColumnKey = "companySalesAnalyticsSelectedColumns";

type CompanySalesAnalyticsSavedColumnType = {
  columns: SortedColumn[];
  version: string;
};

// NOTE: dinii-dataform で未設定の場合は unknown という文字列に直している
export const salesAnalyticsBusinessOperationHourTypeLabelMap: Record<string, string> = {
  ...businessOperationHourTypeToWord,
  unknown: "未設定",
};

const columns = getColumnDefinitions();

type SortedColumn = {
  columnId: string;
  isEnabled: boolean;
  hideInColumnSelect: boolean;
};

const defaultSortedColumns: SortedColumn[] = getColumnDefinitions().map((column) => ({
  columnId: column.columnId,
  isEnabled: column.defaultEnabled,
  hideInColumnSelect: Boolean(column.hideInColumnSelect),
}));

/**
 * カラム追加/削除時にブラウザに保存済みの表示対象カラムとコンフリクトしないようにマージする関数
 */
const getInitialSortedColumns = () => {
  const savedColumns = localStorage.getItem(companySalesAnalyticsSelectedColumnKey);

  if (!savedColumns) return defaultSortedColumns;

  const sortedColumns = (JSON.parse(savedColumns) as CompanySalesAnalyticsSavedColumnType).columns;

  const columnIds = sortedColumns.map(({ columnId }) => columnId);
  const defaultColumnIds = defaultSortedColumns.map(({ columnId }) => columnId);

  return [
    ...sortedColumns.filter(({ columnId }) => defaultColumnIds.includes(columnId)),
    ...defaultSortedColumns.filter(({ columnId }) => !columnIds.includes(columnId)),
  ];
};

const getFileName = ({ startDate, endDate }: { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs }) =>
  `店舗別売上一覧_${startDate.format("YYYYMMDD")}-${endDate.format("YYYYMMDD")}`;

const TopBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FiltersWrapper = styled.div`
  flex-grow: 1;
`;

export const CompanySalesAnalytics = () => {
  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId ?? null;
  const [company] = useCompany();
  const companyId = company?.id ?? null;

  const {
    data: getShopsData,
    error: getShopsError,
    loading: loadingGetShops,
  } = useCompanySalesAnalyticsGetShopsQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );
  const companies = useMemo(
    () => getShopsData?.corporation[0]?.companies ?? [],
    [getShopsData?.corporation],
  );
  const allShopIds = useMemo(
    () => companies.flatMap(({ shops }) => shops.map(({ shopId }) => shopId)),
    [companies],
  );

  const [pagination, setPagination] = usePagination();
  const [showColumnSelectModal, setShowColumnSelectModal] = useState(false);
  const [sortedColumns, setSortedColumns] = useState<SortedColumn[]>(getInitialSortedColumns());
  const [editingSortedColumns, setEditingSortedColumns] = useState<SortedColumn[] | null>(null);

  const {
    analyticsSetting: { displayTaxIncluded },
  } = useAnalyticsSetting({ corporationId });

  const { filterConditions, updateFilterCondition } = useFilterConditions<
    FilterConditions,
    SerializedFilterConditions
  >(
    {
      businessOperationHourTypes: [],
      range: [dayjs().startOf("month"), dayjs()],
      shopIds: null,
    },
    undefined,
    {
      serialize: ({ businessOperationHourTypes, range, shopIds }) => ({
        businessOperationHourTypes: businessOperationHourTypes ?? [],
        range: serializeRange(range),
        shopIds: shopIds ?? null,
      }),
      deserialize: ({ businessOperationHourTypes, range, shopIds }) => ({
        businessOperationHourTypes,
        range: deserializeRange(range),
        shopIds,
      }),
    },
  );

  const columnsWithEnabledStatus = useMemo(
    () =>
      sortedColumns
        .map(({ columnId, isEnabled }) => {
          const referenceColumn = columns.find((column) => column.columnId === columnId);

          if (!referenceColumn) return null;

          return {
            ...referenceColumn,
            isEnabled,
          };
        })
        .filter(isNotNull),
    [sortedColumns],
  );

  const editingColumnsWithEnabledStatus = useMemo(
    () =>
      editingSortedColumns
        ? editingSortedColumns
            .map(({ columnId, isEnabled }) => {
              const referenceColumn = columns.find((column) => column.columnId === columnId);

              if (!referenceColumn) return null;

              return {
                ...referenceColumn,
                isEnabled,
              };
            })
            .filter(isNotNull)
        : [],
    [editingSortedColumns],
  );

  const {
    data: businessOperationHourTypesData,
    loading: loadingGetBusinessOperationHourTypes,
    error: getBusinessOperationHourTypesError,
  } = useCompanySalesAnalyticsGetBusinessOperationHourTypesQuery(
    companyId
      ? {
          variables: {
            companyId,
          },
        }
      : { skip: true },
  );

  const businessOperationHourTypes =
    businessOperationHourTypesData?.shopBusinessOperationHour ?? [];

  // const businessOperationHourTypesToSortIndex = useMemo(
  //   () =>
  //     new Map(
  //       (businessOperationHourTypes?.shopBusinessOperationHour ?? [])
  //         .sort((a, b) => (dayjs(a.start).isAfter(dayjs(b.start)) ? 1 : -1))
  //         .map((shopBusinessOperationHour, ind) => [
  //           shopBusinessOperationHour.businessOperationHourType,
  //           ind,
  //         ]),
  //     ),
  //   [businessOperationHourTypes],
  // );

  const { normalizedRows, isLoading: isLoadingCompanySalesAnalytics } = useCompanySalesAnalytics({
    allShopIds,
    selectedShopIds: filterConditions.shopIds ?? null,
    selectedBusinessOperationHourTypes: filterConditions.businessOperationHourTypes ?? [],
    showTaxIncluded: displayTaxIncluded,
    startDate: filterConditions.range?.[0] ?? dayjs().subtract(30, "days"),
    endDate: filterConditions.range?.[1] ?? dayjs(),
    businessOperationHourTypes,
  });

  const filteredSalesAnalytics: CompanySalesAnalyticsRow[] = useMemo(
    () =>
      normalizedRows.sort((a, b) =>
        (!a.isEmpty && a.isSummaryRow) || (!b.isEmpty && b.isSummaryRow)
          ? 0
          : dayjs(a.shopName).isAfter(dayjs(b.shopName))
          ? 1
          : -1,
      ),
    [normalizedRows],
  );

  const handleEditColumns = useCallback(() => {
    setEditingSortedColumns(sortedColumns);
    setShowColumnSelectModal(true);
  }, [sortedColumns]);
  const handleToggleColumn = useCallback(
    (columnId: string) => {
      if (editingSortedColumns === null) return;

      const newIds = editingSortedColumns.map((column) =>
        column.columnId === columnId ? { ...column, isEnabled: !column.isEnabled } : column,
      );

      setEditingSortedColumns(newIds);
    },
    [editingSortedColumns],
  );

  const handleMoveColumns = useCallback(
    ({ dragIndex, hoverIndex }: { dragIndex: number; hoverIndex: number }) => {
      if (editingSortedColumns === null) return;

      // the column that we are dragging
      const dragIndexColumn = editingSortedColumns.filter((column) => !column.hideInColumnSelect)[
        dragIndex
      ];

      // the column that we dropped onto
      const afterIndexColumn = editingSortedColumns.filter((column) => !column.hideInColumnSelect)[
        hoverIndex
      ];

      if (!dragIndexColumn) return;

      if (dragIndex === hoverIndex) return;

      setEditingSortedColumns((value) => {
        if (!value) return value;

        // remove the column that we dragged
        const filteredColumns = value.filter(
          ({ columnId }) => columnId !== dragIndexColumn.columnId,
        );
        // this is where we will insert the column that we dragged
        const newAfterIndex = value.findIndex(
          ({ columnId }) => columnId === afterIndexColumn?.columnId,
        );

        // if the dragged column is moved down, we put it AFTER the dropped column
        // if the dragged column is moved up, we put it BEFORE the dropped column
        const beforeOrAfterOffset = dragIndex < hoverIndex ? 1 : 0;

        const sortedColumns = [
          ...filteredColumns.slice(0, newAfterIndex + beforeOrAfterOffset),
          dragIndexColumn,
          ...filteredColumns.slice(newAfterIndex + beforeOrAfterOffset, filteredColumns.length),
        ];

        return sortedColumns;
      });
    },
    [editingSortedColumns],
  );

  const handleSaveColumns = useCallback(() => {
    if (!editingSortedColumns) return;

    setSortedColumns(editingSortedColumns);

    localStorage.setItem(
      companySalesAnalyticsSelectedColumnKey,
      JSON.stringify({ columns: editingSortedColumns, version }),
    );

    setEditingSortedColumns(null);

    setShowColumnSelectModal(false);
  }, [editingSortedColumns]);

  const handleCloseModal = useCallback(() => setShowColumnSelectModal(false), []);

  const handleExportCsv = useCallback(() => {
    if (!filterConditions.range?.[0] || !filterConditions.range?.[1]) return;

    const [startDate, endDate] = filterConditions.range;

    const fileName = getFileName({
      startDate,
      endDate,
    });

    exportCompanySalesAnalyticsCsv({
      fileName,
      columnsWithEnabledStatus,
      rows: filteredSalesAnalytics,
    });
  }, [filteredSalesAnalytics, columnsWithEnabledStatus, filterConditions]);

  const error = getBusinessOperationHourTypesError || getShopsError;

  const loading =
    isLoadingCompanySalesAnalytics || loadingGetBusinessOperationHourTypes || loadingGetShops;

  return (
    <DashboardLayout title="店舗別売上一覧">
      <PageHeader title="店舗別売上一覧" footer={null} />
      <Spacer size={12} />
      <TopBar>
        <FiltersWrapper>
          <Filters
            companies={companies}
            allShopIds={allShopIds}
            filterConditions={{
              shopIds: filterConditions.shopIds ?? null,
              businessOperationHourTypes: filterConditions.businessOperationHourTypes ?? [],
              range: filterConditions.range,
            }}
            onChangeFilterCondition={updateFilterCondition}
            onCsvExport={handleExportCsv}
            onEditColumns={handleEditColumns}
          />
        </FiltersWrapper>
      </TopBar>

      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}

      <CompanySalesAnalyticsTable
        columnsWithEnabledState={columnsWithEnabledStatus}
        rows={filteredSalesAnalytics}
        isLoading={loading}
        pagination={pagination}
        onPaginationChange={setPagination}
      />

      <ColumnSelectModal
        columns={editingColumnsWithEnabledStatus}
        isOpen={showColumnSelectModal}
        onSave={handleSaveColumns}
        onMove={handleMoveColumns}
        onCancel={handleCloseModal}
        onToggleColumn={handleToggleColumn}
      />
    </DashboardLayout>
  );
};
