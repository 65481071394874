import React, { memo } from "react";
import styled from "styled-components";
import { Button, Typography, Upload, UploadFile } from "antd";
import { UploadOutlined } from "@ant-design/icons";

// eslint-disable-next-line no-restricted-imports
import { FormItem } from "components/antd/Form";

type Props = {
  adyenStoreMerchantCategoryChangeRequestFile: UploadFile<any> | null;
  loadingVerifyCsv: boolean;
  onSelectFiles: (files: UploadFile<any>) => void;
  removeUploadedFile: () => void;
};

const ErrorMessage = styled(Typography.Text)`
  white-space: pre-wrap;
`;

export const AdyenStoreMerchantCategoryChangeRequestFileField = memo<Props>(
  ({
    adyenStoreMerchantCategoryChangeRequestFile,
    loadingVerifyCsv,
    onSelectFiles,
    removeUploadedFile,
  }) => (
    <FormItem label="CSVファイル" required>
      <Upload
        accept=".csv"
        fileList={
          adyenStoreMerchantCategoryChangeRequestFile
            ? [adyenStoreMerchantCategoryChangeRequestFile]
            : []
        }
        beforeUpload={() => false}
        onChange={async ({ file, fileList }) => {
          if (file.status === "removed") {
            removeUploadedFile();
            return;
          }
          if (fileList[0]) {
            onSelectFiles(fileList[0]);
          }
        }}
        itemRender={(originNode, file) => (
          <>
            {originNode}
            <ErrorMessage type="danger">{file.error}</ErrorMessage>
          </>
        )}
        maxCount={1}
      >
        <Button icon={<UploadOutlined />} loading={loadingVerifyCsv}>
          CSVファイルを選択する
        </Button>
      </Upload>
    </FormItem>
  ),
);
