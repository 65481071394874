import React, { memo } from "react";
import { Input } from "antd";

import { FormItemProps } from "components/antd/Form";

import { AssignShopCashlessSettingTerminalIdFormItem } from "../useAssignShopCashlessSettingTerminalIdForm";

type Props = Omit<FormItemProps, "children" | "name"> & {
  disabled?: boolean;
};

export const SerialNumberField = memo<Props>(({ disabled, ...props }) => (
  <AssignShopCashlessSettingTerminalIdFormItem
    label="端末シリアル番号"
    name="serialNumber"
    rules={[
      { required: true, message: "端末シリアル番号を入力してください" },
      { pattern: /^\d{15}$/, message: "15桁の数字を入力してください" },
    ]}
    required
    {...props}
  >
    <Input disabled={disabled} />
  </AssignShopCashlessSettingTerminalIdFormItem>
));
