import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Tag } from "antd/lib";

import { Table } from "components/Table";
import { usePagination } from "hooks/usePagination";

import { GmoBankAccount } from "../types";

type Props = {
  loading?: boolean;
  gmoBankAccounts: GmoBankAccount[];
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
`;

export const GmoBankAccountTable = memo<Props>(({ loading, gmoBankAccounts }) => {
  const [pagination, setPagination] = usePagination();

  const columns = [
    {
      title: "管理名",
      dataIndex: "name",
      render(_: unknown, { name, id }: (typeof gmoBankAccounts)[number]) {
        return <Link to={`/gmoBankAccount/${id}/edit`}>{name}</Link>;
      },
    },
    {
      title: "対象法人名",
      dataIndex: "corporationName",
      render(_: unknown, { corporationEntityRef }: (typeof gmoBankAccounts)[number]) {
        return <Column>{corporationEntityRef?.name}</Column>;
      },
    },
    {
      title: "対象店舗名",
      dataIndex: "shopName",
      render(_: unknown, { gmoBankAccountShops }: (typeof gmoBankAccounts)[number]) {
        return (
          <Column>
            {gmoBankAccountShops?.map(({ shops }) => (
              <Tag key={shops?.id}>{shops?.name}</Tag>
            ))}
          </Column>
        );
      },
    } as const,
  ];

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={gmoBankAccounts}
      loading={loading}
      bordered
      pagination={pagination}
      onChange={({ position: _, ...pagination }) => setPagination(pagination)}
    />
  );
});
