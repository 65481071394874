import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { useFilterConditions } from "hooks/useFilterConditions";

import { FilterConditions, GmoBankAccountFilter } from "./GmoBankAccountFilter";
import { GmoBankAccountTable } from "./GmoBankAccountTable";
import { useGmoBankAccountGetGmoBankAccountsQuery } from "./queries";
import { GmoBankAccount } from "./types";

const useGmoBankAccountsData = () => {
  const { data: gmoBankAccountsData, loading: loadingGmoBankAccounts } =
    useGmoBankAccountGetGmoBankAccountsQuery();

  const gmoBankAccounts = useMemo(
    () => gmoBankAccountsData?.gmoBankAccount ?? [],
    [gmoBankAccountsData],
  );

  const corporations = useMemo(
    () =>
      gmoBankAccountsData?.gmoBankAccount.map(
        (gmoBankAccount) => gmoBankAccount.corporationEntityRef,
      ) ?? [],
    [gmoBankAccountsData],
  );

  const shopsByCompany = useMemo(
    () =>
      gmoBankAccountsData?.gmoBankAccount
        .map((gmoBankAccount) => gmoBankAccount.gmoBankAccountShops.map((shop) => shop.shops))
        .flat() ?? [],
    [gmoBankAccountsData],
  );

  return { gmoBankAccounts, corporations, shopsByCompany, loadingGmoBankAccounts };
};

const filterGmoBankAccounts = (
  gmoBankAccounts: GmoBankAccount[],
  { name, corporationIds, shopIds }: FilterConditions,
) =>
  gmoBankAccounts.filter(
    (gmoBankAccount) =>
      (name === undefined || gmoBankAccount.name.includes(name)) &&
      (corporationIds === undefined ||
        (gmoBankAccount.corporationId && corporationIds.includes(gmoBankAccount.corporationId))) &&
      (shopIds === undefined ||
        gmoBankAccount.gmoBankAccountShops.some((shop) => shopIds.includes(shop.shopId))),
  );

export const BankAccountCashlessSetting = () => {
  const { gmoBankAccounts, corporations, shopsByCompany, loadingGmoBankAccounts } =
    useGmoBankAccountsData();

  const { hasFilterConditions, filterConditions, updateFilterCondition, clearFilterConditions } =
    useFilterConditions<FilterConditions>({});

  const filteredGmoBankAccounts = useMemo(
    () => filterGmoBankAccounts(gmoBankAccounts, filterConditions),
    [gmoBankAccounts, filterConditions],
  );

  return (
    <DashboardLayout title="銀行口座設定">
      <PageHeader
        title="銀行口座設定"
        extra={
          <Link to="/gmoBankAccount/add">
            <Button type="primary">新規作成</Button>
          </Link>
        }
      />
      <GmoBankAccountFilter
        corporations={corporations}
        shops={shopsByCompany}
        hasFilterConditions={hasFilterConditions}
        filterConditions={filterConditions}
        updateFilterCondition={updateFilterCondition}
        clearFilterConditions={clearFilterConditions}
      />
      <Spacer size={24} />
      <GmoBankAccountTable
        gmoBankAccounts={filteredGmoBankAccounts}
        loading={loadingGmoBankAccounts}
      />
    </DashboardLayout>
  );
};
