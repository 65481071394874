import React, { memo, useMemo } from "react";
import styled from "styled-components";
import { Button, Checkbox, Col, DatePicker, Row } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { csvNames } from "models/csvName";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { Spacer } from "components/Spacer";
import { useFeatureFlag } from "hooks/useFeatureFlag";
import { useRangePresets } from "hooks/useRangePresets";
import {
  isCustomerSegmentAggregationEnabled,
  isKdServedTimeAggregationEnabled,
  isKioskServedTimeAggregationEnabled,
} from "libs/companyFeatureFlag";
import { FeatureFlagEnum } from "types/graphql";

import { csvNameMap } from "../csvNamesMap";
import { Shop } from "../types";

import {
  AggregatedDataFormItem,
  OnSubmitAggregatedData,
  useAggregatedDataForm,
} from "./useAggregatedDataForm";

type Props = {
  _companyId: number;
  isDownloading: boolean;
  disabled: boolean;
  shops: Shop[];
  onSubmit: OnSubmitAggregatedData;
};

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CheckboxGroup = styled(Checkbox.Group)`
  display: inline-block;
`;

const { RangePicker } = DatePicker;

export const AggregatedDataForm = memo<Props>(
  ({ _companyId, isDownloading, disabled, shops, onSubmit }) => {
    const { form, initialValues, submit } = useAggregatedDataForm({ onSubmit });
    // NOTE: 属性集計は田中系列にのみ解放する
    const enableCustomerSegmentAggregation = isCustomerSegmentAggregationEnabled(_companyId);
    // NOTE: KIOSK 提供時間はセカンドアローにのみ開放する
    const enableKioskServedTimeAggregation = isKioskServedTimeAggregationEnabled(_companyId);
    // NOTE: キッチンディスプレイ提供時間はキッチンディスプレイ導入店舗が一つでもあれば開放する
    const enableKdServedTimeAggregation = isKdServedTimeAggregationEnabled(shops);
    // NOTE: GMO決済が有効になっている店舗が一つでもあれば開放する
    const { isFeatureAvailable } = useFeatureFlag();
    const enableGmoTransactionAggregation = isFeatureAvailable({
      name: FeatureFlagEnum.EnableGmoOnlinePayment,
      shopIds: shops.map((shop) => shop.shopId),
    });
    const enableAdyenTransactionAggregation = isFeatureAvailable({
      name: FeatureFlagEnum.EnableAdyenOnlinePayment,
      shopIds: shops.map((shop) => shop.shopId),
    });

    const csvNameList = useMemo(
      () =>
        csvNames.filter((csvName) => {
          if (csvName === "customerSegment") return enableCustomerSegmentAggregation;
          if (csvName === "kioskServedTime") return enableKioskServedTimeAggregation;
          if (csvName === "kdServedTime") return enableKdServedTimeAggregation;
          if (csvName === "onlinePaymentTransaction") {
            return enableGmoTransactionAggregation || enableAdyenTransactionAggregation;
          }
          return true;
        }),
      [
        enableCustomerSegmentAggregation,
        enableKioskServedTimeAggregation,
        enableKdServedTimeAggregation,
        enableGmoTransactionAggregation,
        enableAdyenTransactionAggregation,
      ],
    );

    const { rangePresets } = useRangePresets();

    return (
      <>
        <Form name="aggregatedDataForm" form={form} layout="vertical" initialValues={initialValues}>
          <AggregatedDataFormItem label="期間選択" name="dateRange" rules={[{ required: true }]}>
            <RangePicker ranges={rangePresets} />
          </AggregatedDataFormItem>

          <AggregatedDataFormItem
            label="出力ファイル選択"
            name="csvNames"
            rules={[{ required: true }]}
          >
            <CheckboxGroup>
              {csvNameList.map((csvName, index) => (
                <Row key={index}>
                  <Col>
                    <Checkbox value={csvName}>{csvNameMap[csvName]}</Checkbox>
                    <Spacer size={8} />
                  </Col>
                </Row>
              ))}
            </CheckboxGroup>
          </AggregatedDataFormItem>
        </Form>

        <DescriptionContainer>
          <p>集計完了まで数分かかる場合があります</p>
        </DescriptionContainer>

        <FormActions>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            disabled={disabled}
            loading={isDownloading}
            onClick={submit}
          >
            ダウンロード
          </Button>
        </FormActions>
      </>
    );
  },
);
