import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GmoBankAccountGetGmoBankAccounts = gql`
    query GmoBankAccountGetGmoBankAccounts {
  gmoBankAccount {
    id
    name
    corporationId
    corporationEntityRef {
      id
      name
    }
    gmoBankAccountShops {
      id
      shopId
      shops {
        id
        name
      }
    }
  }
}
    `;
export type GmoBankAccountGetGmoBankAccountsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GmoBankAccountGetGmoBankAccountsQuery = (
  { __typename?: 'query_root' }
  & { gmoBankAccount: Array<(
    { __typename?: 'gmoBankAccount' }
    & Pick<Types.GmoBankAccount, 'id' | 'name' | 'corporationId'>
    & { corporationEntityRef?: Types.Maybe<(
      { __typename?: 'corporationEntityRef' }
      & Pick<Types.CorporationEntityRef, 'id' | 'name'>
    )>, gmoBankAccountShops: Array<(
      { __typename?: 'gmoBankAccountShop' }
      & Pick<Types.GmoBankAccountShop, 'id' | 'shopId'>
      & { shops?: Types.Maybe<(
        { __typename?: 'shopEntityRef' }
        & Pick<Types.ShopEntityRef, 'id' | 'name'>
      )> }
    )> }
  )> }
);


export const GmoBankAccountGetGmoBankAccountsDocument = gql`
    query GmoBankAccountGetGmoBankAccounts {
  gmoBankAccount {
    id
    name
    corporationId
    corporationEntityRef {
      id
      name
    }
    gmoBankAccountShops {
      id
      shopId
      shops {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGmoBankAccountGetGmoBankAccountsQuery__
 *
 * To run a query within a React component, call `useGmoBankAccountGetGmoBankAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGmoBankAccountGetGmoBankAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGmoBankAccountGetGmoBankAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGmoBankAccountGetGmoBankAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GmoBankAccountGetGmoBankAccountsQuery, GmoBankAccountGetGmoBankAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GmoBankAccountGetGmoBankAccountsQuery, GmoBankAccountGetGmoBankAccountsQueryVariables>(GmoBankAccountGetGmoBankAccountsDocument, options);
      }
export function useGmoBankAccountGetGmoBankAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GmoBankAccountGetGmoBankAccountsQuery, GmoBankAccountGetGmoBankAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GmoBankAccountGetGmoBankAccountsQuery, GmoBankAccountGetGmoBankAccountsQueryVariables>(GmoBankAccountGetGmoBankAccountsDocument, options);
        }
export type GmoBankAccountGetGmoBankAccountsQueryHookResult = ReturnType<typeof useGmoBankAccountGetGmoBankAccountsQuery>;
export type GmoBankAccountGetGmoBankAccountsLazyQueryHookResult = ReturnType<typeof useGmoBankAccountGetGmoBankAccountsLazyQuery>;
export type GmoBankAccountGetGmoBankAccountsQueryResult = Apollo.QueryResult<GmoBankAccountGetGmoBankAccountsQuery, GmoBankAccountGetGmoBankAccountsQueryVariables>;