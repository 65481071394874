import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const ShopMenusGetMenus = gql`
    query ShopMenusGetMenus($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    useKd
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}, menu: {archivedAt: {_is_null: true}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}}
      order_by: {_menuId: asc}
    ) {
      shopId
      menuId: _menuId
      id: menuId
      menu {
        id
        imageUrl
        menuId
        name
        categoryMenus(
          where: {category: {archivedAt: {_is_null: true}}, _not: {category: {menuRecommendationMeta: {}}}}
        ) {
          categoryId: _categoryId
          priority
          category {
            categoryId
            name
            priority
          }
        }
        menuOptions(
          where: {option: {archivedAt: {_is_null: true}}}
          order_by: {option: {name: asc}}
        ) {
          option {
            id
            optionId
            name
            choices(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
              id
              choiceId
              name
              shopChoices(where: {archivedAt: {_is_null: true}, shopId: {_eq: $shopId}}) {
                id
                shopId
                choiceId: _choiceId
                isVisibleForCustomer
                isVisibleForStaff
                stock {
                  id
                  currentStockNum
                }
              }
            }
          }
        }
      }
      isVisibleForCustomer
      isVisibleForStaff
      stock {
        id
        currentStockNum
        dailyStockNum
      }
      shopMenuKitchenRoles {
        role {
          name
          roleId
        }
      }
      dishUpSlipGroupShopMenus {
        dishUpSlipGroup {
          id: serial
          name
        }
      }
      shopMenuKdDisplayTargets {
        kdDisplayTarget {
          id
          name
        }
      }
    }
  }
}
    `;
export const ShopMenusGetCategories = gql`
    query ShopMenusGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;
export const ShopMenusGetRoles = gql`
    query ShopMenusGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    roles {
      roleId
      name
    }
  }
}
    `;
export const ShopMenusGetDishUpSlipGroups = gql`
    query ShopMenusGetDishUpSlipGroups($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    dishUpSlipGroups {
      id: serial
      name
    }
  }
}
    `;
export const ShopMenusGetKdDisplayTargets = gql`
    query ShopMenusGetKdDisplayTargets($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    kdDisplayTargets {
      id
      name
    }
  }
}
    `;
export const ShopMenusUpdateShopMenuVisibleForCustomer = gql`
    mutation ShopMenusUpdateShopMenuVisibleForCustomer($shopId: uuid!, $menuId: uuid!, $isVisibleForCustomer: Boolean!) {
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: {isVisibleForCustomer: $isVisibleForCustomer}
  ) {
    affected_rows
  }
}
    `;
export const ShopMenusUpdateShopMenuVisibleForStaff = gql`
    mutation ShopMenusUpdateShopMenuVisibleForStaff($shopId: uuid!, $menuId: uuid!, $isVisibleForStaff: Boolean!) {
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: {isVisibleForStaff: $isVisibleForStaff}
  ) {
    affected_rows
  }
}
    `;
export const ShopMenusUpdateShopMenuStock = gql`
    mutation ShopMenusUpdateShopMenuStock($shopId: uuid!, $menuId: uuid!, $stockId: uuid!, $stock: stock_insert_input!) {
  insert_stock_one(
    object: $stock
    on_conflict: {constraint: stock_pkey, update_columns: [currentStockNum]}
  ) {
    id
  }
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: {stockId: $stockId}
  ) {
    affected_rows
  }
}
    `;
export const ShopMenusUpdateShopMenus = gql`
    mutation ShopMenusUpdateShopMenus($input: UpdateShopMenusInput!) {
  updateShopMenus(input: $input)
}
    `;
export const ShopMenusUpsertShopChoices = gql`
    mutation ShopMenusUpsertShopChoices($input: UpsertShopChoicesInput!) {
  upsertShopChoices(input: $input) {
    result
  }
}
    `;
export type ShopMenusGetMenusQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type ShopMenusGetMenusQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'useKd'>
    & { shopMenus: Array<(
      { __typename?: 'shopMenu' }
      & Pick<Types.ShopMenu, 'shopId' | 'isVisibleForCustomer' | 'isVisibleForStaff'>
      & { menuId: Types.ShopMenu['_menuId'], id: Types.ShopMenu['menuId'] }
      & { menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'id' | 'imageUrl' | 'menuId' | 'name'>
        & { categoryMenus: Array<(
          { __typename?: 'categoryMenu' }
          & Pick<Types.CategoryMenu, 'priority'>
          & { categoryId: Types.CategoryMenu['_categoryId'] }
          & { category: (
            { __typename?: 'category' }
            & Pick<Types.Category, 'categoryId' | 'name' | 'priority'>
          ) }
        )>, menuOptions: Array<(
          { __typename?: 'menuOption' }
          & { option: (
            { __typename?: 'option' }
            & Pick<Types.Option, 'id' | 'optionId' | 'name'>
            & { choices: Array<(
              { __typename?: 'choice' }
              & Pick<Types.Choice, 'id' | 'choiceId' | 'name'>
              & { shopChoices: Array<(
                { __typename?: 'shopChoice' }
                & Pick<Types.ShopChoice, 'id' | 'shopId' | 'isVisibleForCustomer' | 'isVisibleForStaff'>
                & { choiceId: Types.ShopChoice['_choiceId'] }
                & { stock?: Types.Maybe<(
                  { __typename?: 'stock' }
                  & Pick<Types.Stock, 'id' | 'currentStockNum'>
                )> }
              )> }
            )> }
          ) }
        )> }
      ), stock?: Types.Maybe<(
        { __typename?: 'stock' }
        & Pick<Types.Stock, 'id' | 'currentStockNum' | 'dailyStockNum'>
      )>, shopMenuKitchenRoles: Array<(
        { __typename?: 'shopMenuKitchenRole' }
        & { role: (
          { __typename?: 'role' }
          & Pick<Types.Role, 'name' | 'roleId'>
        ) }
      )>, dishUpSlipGroupShopMenus: Array<(
        { __typename?: 'dishUpSlipGroupShopMenus' }
        & { dishUpSlipGroup?: Types.Maybe<(
          { __typename?: 'dishUpSlipGroup' }
          & Pick<Types.DishUpSlipGroup, 'name'>
          & { id: Types.DishUpSlipGroup['serial'] }
        )> }
      )>, shopMenuKdDisplayTargets: Array<(
        { __typename?: 'shopMenuKdDisplayTarget' }
        & { kdDisplayTarget?: Types.Maybe<(
          { __typename?: 'kdDisplayTarget' }
          & Pick<Types.KdDisplayTarget, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type ShopMenusGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type ShopMenusGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
  )> }
);

export type ShopMenusGetRolesQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type ShopMenusGetRolesQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
    & { roles: Array<(
      { __typename?: 'role' }
      & Pick<Types.Role, 'roleId' | 'name'>
    )> }
  )> }
);

export type ShopMenusGetDishUpSlipGroupsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type ShopMenusGetDishUpSlipGroupsQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
    & { dishUpSlipGroups: Array<(
      { __typename?: 'dishUpSlipGroup' }
      & Pick<Types.DishUpSlipGroup, 'name'>
      & { id: Types.DishUpSlipGroup['serial'] }
    )> }
  )> }
);

export type ShopMenusGetKdDisplayTargetsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type ShopMenusGetKdDisplayTargetsQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId'>
    & { kdDisplayTargets: Array<(
      { __typename?: 'kdDisplayTarget' }
      & Pick<Types.KdDisplayTarget, 'id' | 'name'>
    )> }
  )> }
);

export type ShopMenusUpdateShopMenuVisibleForCustomerMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  menuId: Types.Scalars['uuid'];
  isVisibleForCustomer: Types.Scalars['Boolean'];
}>;


export type ShopMenusUpdateShopMenuVisibleForCustomerMutation = (
  { __typename?: 'mutation_root' }
  & { update_shopMenu?: Types.Maybe<(
    { __typename?: 'shopMenu_mutation_response' }
    & Pick<Types.ShopMenuMutationResponse, 'affected_rows'>
  )> }
);

export type ShopMenusUpdateShopMenuVisibleForStaffMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  menuId: Types.Scalars['uuid'];
  isVisibleForStaff: Types.Scalars['Boolean'];
}>;


export type ShopMenusUpdateShopMenuVisibleForStaffMutation = (
  { __typename?: 'mutation_root' }
  & { update_shopMenu?: Types.Maybe<(
    { __typename?: 'shopMenu_mutation_response' }
    & Pick<Types.ShopMenuMutationResponse, 'affected_rows'>
  )> }
);

export type ShopMenusUpdateShopMenuStockMutationVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
  menuId: Types.Scalars['uuid'];
  stockId: Types.Scalars['uuid'];
  stock: Types.StockInsertInput;
}>;


export type ShopMenusUpdateShopMenuStockMutation = (
  { __typename?: 'mutation_root' }
  & { insert_stock_one?: Types.Maybe<(
    { __typename?: 'stock' }
    & Pick<Types.Stock, 'id'>
  )>, update_shopMenu?: Types.Maybe<(
    { __typename?: 'shopMenu_mutation_response' }
    & Pick<Types.ShopMenuMutationResponse, 'affected_rows'>
  )> }
);

export type ShopMenusUpdateShopMenusMutationVariables = Types.Exact<{
  input: Types.UpdateShopMenusInput;
}>;


export type ShopMenusUpdateShopMenusMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Types.MutationRoot, 'updateShopMenus'>
);

export type ShopMenusUpsertShopChoicesMutationVariables = Types.Exact<{
  input: Types.UpsertShopChoicesInput;
}>;


export type ShopMenusUpsertShopChoicesMutation = (
  { __typename?: 'mutation_root' }
  & { upsertShopChoices: (
    { __typename?: 'UpsertShopChoicesResult' }
    & Pick<Types.UpsertShopChoicesResult, 'result'>
  ) }
);


export const ShopMenusGetMenusDocument = gql`
    query ShopMenusGetMenus($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    useKd
    shopMenus(
      where: {archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}, menu: {archivedAt: {_is_null: true}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}}
      order_by: {_menuId: asc}
    ) {
      shopId
      menuId: _menuId
      id: menuId
      menu {
        id
        imageUrl
        menuId
        name
        categoryMenus(
          where: {category: {archivedAt: {_is_null: true}}, _not: {category: {menuRecommendationMeta: {}}}}
        ) {
          categoryId: _categoryId
          priority
          category {
            categoryId
            name
            priority
          }
        }
        menuOptions(
          where: {option: {archivedAt: {_is_null: true}}}
          order_by: {option: {name: asc}}
        ) {
          option {
            id
            optionId
            name
            choices(where: {archivedAt: {_is_null: true}}, order_by: {name: asc}) {
              id
              choiceId
              name
              shopChoices(where: {archivedAt: {_is_null: true}, shopId: {_eq: $shopId}}) {
                id
                shopId
                choiceId: _choiceId
                isVisibleForCustomer
                isVisibleForStaff
                stock {
                  id
                  currentStockNum
                }
              }
            }
          }
        }
      }
      isVisibleForCustomer
      isVisibleForStaff
      stock {
        id
        currentStockNum
        dailyStockNum
      }
      shopMenuKitchenRoles {
        role {
          name
          roleId
        }
      }
      dishUpSlipGroupShopMenus {
        dishUpSlipGroup {
          id: serial
          name
        }
      }
      shopMenuKdDisplayTargets {
        kdDisplayTarget {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useShopMenusGetMenusQuery__
 *
 * To run a query within a React component, call `useShopMenusGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopMenusGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopMenusGetMenusQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopMenusGetMenusQuery(baseOptions: Apollo.QueryHookOptions<ShopMenusGetMenusQuery, ShopMenusGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopMenusGetMenusQuery, ShopMenusGetMenusQueryVariables>(ShopMenusGetMenusDocument, options);
      }
export function useShopMenusGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopMenusGetMenusQuery, ShopMenusGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopMenusGetMenusQuery, ShopMenusGetMenusQueryVariables>(ShopMenusGetMenusDocument, options);
        }
export type ShopMenusGetMenusQueryHookResult = ReturnType<typeof useShopMenusGetMenusQuery>;
export type ShopMenusGetMenusLazyQueryHookResult = ReturnType<typeof useShopMenusGetMenusLazyQuery>;
export type ShopMenusGetMenusQueryResult = Apollo.QueryResult<ShopMenusGetMenusQuery, ShopMenusGetMenusQueryVariables>;
export const ShopMenusGetCategoriesDocument = gql`
    query ShopMenusGetCategories($companyId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
  }
}
    `;

/**
 * __useShopMenusGetCategoriesQuery__
 *
 * To run a query within a React component, call `useShopMenusGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopMenusGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopMenusGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useShopMenusGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ShopMenusGetCategoriesQuery, ShopMenusGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopMenusGetCategoriesQuery, ShopMenusGetCategoriesQueryVariables>(ShopMenusGetCategoriesDocument, options);
      }
export function useShopMenusGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopMenusGetCategoriesQuery, ShopMenusGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopMenusGetCategoriesQuery, ShopMenusGetCategoriesQueryVariables>(ShopMenusGetCategoriesDocument, options);
        }
export type ShopMenusGetCategoriesQueryHookResult = ReturnType<typeof useShopMenusGetCategoriesQuery>;
export type ShopMenusGetCategoriesLazyQueryHookResult = ReturnType<typeof useShopMenusGetCategoriesLazyQuery>;
export type ShopMenusGetCategoriesQueryResult = Apollo.QueryResult<ShopMenusGetCategoriesQuery, ShopMenusGetCategoriesQueryVariables>;
export const ShopMenusGetRolesDocument = gql`
    query ShopMenusGetRoles($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    roles {
      roleId
      name
    }
  }
}
    `;

/**
 * __useShopMenusGetRolesQuery__
 *
 * To run a query within a React component, call `useShopMenusGetRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopMenusGetRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopMenusGetRolesQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopMenusGetRolesQuery(baseOptions: Apollo.QueryHookOptions<ShopMenusGetRolesQuery, ShopMenusGetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopMenusGetRolesQuery, ShopMenusGetRolesQueryVariables>(ShopMenusGetRolesDocument, options);
      }
export function useShopMenusGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopMenusGetRolesQuery, ShopMenusGetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopMenusGetRolesQuery, ShopMenusGetRolesQueryVariables>(ShopMenusGetRolesDocument, options);
        }
export type ShopMenusGetRolesQueryHookResult = ReturnType<typeof useShopMenusGetRolesQuery>;
export type ShopMenusGetRolesLazyQueryHookResult = ReturnType<typeof useShopMenusGetRolesLazyQuery>;
export type ShopMenusGetRolesQueryResult = Apollo.QueryResult<ShopMenusGetRolesQuery, ShopMenusGetRolesQueryVariables>;
export const ShopMenusGetDishUpSlipGroupsDocument = gql`
    query ShopMenusGetDishUpSlipGroups($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    dishUpSlipGroups {
      id: serial
      name
    }
  }
}
    `;

/**
 * __useShopMenusGetDishUpSlipGroupsQuery__
 *
 * To run a query within a React component, call `useShopMenusGetDishUpSlipGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopMenusGetDishUpSlipGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopMenusGetDishUpSlipGroupsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopMenusGetDishUpSlipGroupsQuery(baseOptions: Apollo.QueryHookOptions<ShopMenusGetDishUpSlipGroupsQuery, ShopMenusGetDishUpSlipGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopMenusGetDishUpSlipGroupsQuery, ShopMenusGetDishUpSlipGroupsQueryVariables>(ShopMenusGetDishUpSlipGroupsDocument, options);
      }
export function useShopMenusGetDishUpSlipGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopMenusGetDishUpSlipGroupsQuery, ShopMenusGetDishUpSlipGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopMenusGetDishUpSlipGroupsQuery, ShopMenusGetDishUpSlipGroupsQueryVariables>(ShopMenusGetDishUpSlipGroupsDocument, options);
        }
export type ShopMenusGetDishUpSlipGroupsQueryHookResult = ReturnType<typeof useShopMenusGetDishUpSlipGroupsQuery>;
export type ShopMenusGetDishUpSlipGroupsLazyQueryHookResult = ReturnType<typeof useShopMenusGetDishUpSlipGroupsLazyQuery>;
export type ShopMenusGetDishUpSlipGroupsQueryResult = Apollo.QueryResult<ShopMenusGetDishUpSlipGroupsQuery, ShopMenusGetDishUpSlipGroupsQueryVariables>;
export const ShopMenusGetKdDisplayTargetsDocument = gql`
    query ShopMenusGetKdDisplayTargets($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    kdDisplayTargets {
      id
      name
    }
  }
}
    `;

/**
 * __useShopMenusGetKdDisplayTargetsQuery__
 *
 * To run a query within a React component, call `useShopMenusGetKdDisplayTargetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopMenusGetKdDisplayTargetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopMenusGetKdDisplayTargetsQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useShopMenusGetKdDisplayTargetsQuery(baseOptions: Apollo.QueryHookOptions<ShopMenusGetKdDisplayTargetsQuery, ShopMenusGetKdDisplayTargetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopMenusGetKdDisplayTargetsQuery, ShopMenusGetKdDisplayTargetsQueryVariables>(ShopMenusGetKdDisplayTargetsDocument, options);
      }
export function useShopMenusGetKdDisplayTargetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopMenusGetKdDisplayTargetsQuery, ShopMenusGetKdDisplayTargetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopMenusGetKdDisplayTargetsQuery, ShopMenusGetKdDisplayTargetsQueryVariables>(ShopMenusGetKdDisplayTargetsDocument, options);
        }
export type ShopMenusGetKdDisplayTargetsQueryHookResult = ReturnType<typeof useShopMenusGetKdDisplayTargetsQuery>;
export type ShopMenusGetKdDisplayTargetsLazyQueryHookResult = ReturnType<typeof useShopMenusGetKdDisplayTargetsLazyQuery>;
export type ShopMenusGetKdDisplayTargetsQueryResult = Apollo.QueryResult<ShopMenusGetKdDisplayTargetsQuery, ShopMenusGetKdDisplayTargetsQueryVariables>;
export const ShopMenusUpdateShopMenuVisibleForCustomerDocument = gql`
    mutation ShopMenusUpdateShopMenuVisibleForCustomer($shopId: uuid!, $menuId: uuid!, $isVisibleForCustomer: Boolean!) {
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: {isVisibleForCustomer: $isVisibleForCustomer}
  ) {
    affected_rows
  }
}
    `;
export type ShopMenusUpdateShopMenuVisibleForCustomerMutationFn = Apollo.MutationFunction<ShopMenusUpdateShopMenuVisibleForCustomerMutation, ShopMenusUpdateShopMenuVisibleForCustomerMutationVariables>;

/**
 * __useShopMenusUpdateShopMenuVisibleForCustomerMutation__
 *
 * To run a mutation, you first call `useShopMenusUpdateShopMenuVisibleForCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopMenusUpdateShopMenuVisibleForCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopMenusUpdateShopMenuVisibleForCustomerMutation, { data, loading, error }] = useShopMenusUpdateShopMenuVisibleForCustomerMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      menuId: // value for 'menuId'
 *      isVisibleForCustomer: // value for 'isVisibleForCustomer'
 *   },
 * });
 */
export function useShopMenusUpdateShopMenuVisibleForCustomerMutation(baseOptions?: Apollo.MutationHookOptions<ShopMenusUpdateShopMenuVisibleForCustomerMutation, ShopMenusUpdateShopMenuVisibleForCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopMenusUpdateShopMenuVisibleForCustomerMutation, ShopMenusUpdateShopMenuVisibleForCustomerMutationVariables>(ShopMenusUpdateShopMenuVisibleForCustomerDocument, options);
      }
export type ShopMenusUpdateShopMenuVisibleForCustomerMutationHookResult = ReturnType<typeof useShopMenusUpdateShopMenuVisibleForCustomerMutation>;
export type ShopMenusUpdateShopMenuVisibleForCustomerMutationResult = Apollo.MutationResult<ShopMenusUpdateShopMenuVisibleForCustomerMutation>;
export type ShopMenusUpdateShopMenuVisibleForCustomerMutationOptions = Apollo.BaseMutationOptions<ShopMenusUpdateShopMenuVisibleForCustomerMutation, ShopMenusUpdateShopMenuVisibleForCustomerMutationVariables>;
export const ShopMenusUpdateShopMenuVisibleForStaffDocument = gql`
    mutation ShopMenusUpdateShopMenuVisibleForStaff($shopId: uuid!, $menuId: uuid!, $isVisibleForStaff: Boolean!) {
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: {isVisibleForStaff: $isVisibleForStaff}
  ) {
    affected_rows
  }
}
    `;
export type ShopMenusUpdateShopMenuVisibleForStaffMutationFn = Apollo.MutationFunction<ShopMenusUpdateShopMenuVisibleForStaffMutation, ShopMenusUpdateShopMenuVisibleForStaffMutationVariables>;

/**
 * __useShopMenusUpdateShopMenuVisibleForStaffMutation__
 *
 * To run a mutation, you first call `useShopMenusUpdateShopMenuVisibleForStaffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopMenusUpdateShopMenuVisibleForStaffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopMenusUpdateShopMenuVisibleForStaffMutation, { data, loading, error }] = useShopMenusUpdateShopMenuVisibleForStaffMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      menuId: // value for 'menuId'
 *      isVisibleForStaff: // value for 'isVisibleForStaff'
 *   },
 * });
 */
export function useShopMenusUpdateShopMenuVisibleForStaffMutation(baseOptions?: Apollo.MutationHookOptions<ShopMenusUpdateShopMenuVisibleForStaffMutation, ShopMenusUpdateShopMenuVisibleForStaffMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopMenusUpdateShopMenuVisibleForStaffMutation, ShopMenusUpdateShopMenuVisibleForStaffMutationVariables>(ShopMenusUpdateShopMenuVisibleForStaffDocument, options);
      }
export type ShopMenusUpdateShopMenuVisibleForStaffMutationHookResult = ReturnType<typeof useShopMenusUpdateShopMenuVisibleForStaffMutation>;
export type ShopMenusUpdateShopMenuVisibleForStaffMutationResult = Apollo.MutationResult<ShopMenusUpdateShopMenuVisibleForStaffMutation>;
export type ShopMenusUpdateShopMenuVisibleForStaffMutationOptions = Apollo.BaseMutationOptions<ShopMenusUpdateShopMenuVisibleForStaffMutation, ShopMenusUpdateShopMenuVisibleForStaffMutationVariables>;
export const ShopMenusUpdateShopMenuStockDocument = gql`
    mutation ShopMenusUpdateShopMenuStock($shopId: uuid!, $menuId: uuid!, $stockId: uuid!, $stock: stock_insert_input!) {
  insert_stock_one(
    object: $stock
    on_conflict: {constraint: stock_pkey, update_columns: [currentStockNum]}
  ) {
    id
  }
  update_shopMenu(
    where: {shopId: {_eq: $shopId}, menuId: {_eq: $menuId}, archivedAt: {_is_null: true}}
    _set: {stockId: $stockId}
  ) {
    affected_rows
  }
}
    `;
export type ShopMenusUpdateShopMenuStockMutationFn = Apollo.MutationFunction<ShopMenusUpdateShopMenuStockMutation, ShopMenusUpdateShopMenuStockMutationVariables>;

/**
 * __useShopMenusUpdateShopMenuStockMutation__
 *
 * To run a mutation, you first call `useShopMenusUpdateShopMenuStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopMenusUpdateShopMenuStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopMenusUpdateShopMenuStockMutation, { data, loading, error }] = useShopMenusUpdateShopMenuStockMutation({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      menuId: // value for 'menuId'
 *      stockId: // value for 'stockId'
 *      stock: // value for 'stock'
 *   },
 * });
 */
export function useShopMenusUpdateShopMenuStockMutation(baseOptions?: Apollo.MutationHookOptions<ShopMenusUpdateShopMenuStockMutation, ShopMenusUpdateShopMenuStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopMenusUpdateShopMenuStockMutation, ShopMenusUpdateShopMenuStockMutationVariables>(ShopMenusUpdateShopMenuStockDocument, options);
      }
export type ShopMenusUpdateShopMenuStockMutationHookResult = ReturnType<typeof useShopMenusUpdateShopMenuStockMutation>;
export type ShopMenusUpdateShopMenuStockMutationResult = Apollo.MutationResult<ShopMenusUpdateShopMenuStockMutation>;
export type ShopMenusUpdateShopMenuStockMutationOptions = Apollo.BaseMutationOptions<ShopMenusUpdateShopMenuStockMutation, ShopMenusUpdateShopMenuStockMutationVariables>;
export const ShopMenusUpdateShopMenusDocument = gql`
    mutation ShopMenusUpdateShopMenus($input: UpdateShopMenusInput!) {
  updateShopMenus(input: $input)
}
    `;
export type ShopMenusUpdateShopMenusMutationFn = Apollo.MutationFunction<ShopMenusUpdateShopMenusMutation, ShopMenusUpdateShopMenusMutationVariables>;

/**
 * __useShopMenusUpdateShopMenusMutation__
 *
 * To run a mutation, you first call `useShopMenusUpdateShopMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopMenusUpdateShopMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopMenusUpdateShopMenusMutation, { data, loading, error }] = useShopMenusUpdateShopMenusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopMenusUpdateShopMenusMutation(baseOptions?: Apollo.MutationHookOptions<ShopMenusUpdateShopMenusMutation, ShopMenusUpdateShopMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopMenusUpdateShopMenusMutation, ShopMenusUpdateShopMenusMutationVariables>(ShopMenusUpdateShopMenusDocument, options);
      }
export type ShopMenusUpdateShopMenusMutationHookResult = ReturnType<typeof useShopMenusUpdateShopMenusMutation>;
export type ShopMenusUpdateShopMenusMutationResult = Apollo.MutationResult<ShopMenusUpdateShopMenusMutation>;
export type ShopMenusUpdateShopMenusMutationOptions = Apollo.BaseMutationOptions<ShopMenusUpdateShopMenusMutation, ShopMenusUpdateShopMenusMutationVariables>;
export const ShopMenusUpsertShopChoicesDocument = gql`
    mutation ShopMenusUpsertShopChoices($input: UpsertShopChoicesInput!) {
  upsertShopChoices(input: $input) {
    result
  }
}
    `;
export type ShopMenusUpsertShopChoicesMutationFn = Apollo.MutationFunction<ShopMenusUpsertShopChoicesMutation, ShopMenusUpsertShopChoicesMutationVariables>;

/**
 * __useShopMenusUpsertShopChoicesMutation__
 *
 * To run a mutation, you first call `useShopMenusUpsertShopChoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShopMenusUpsertShopChoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shopMenusUpsertShopChoicesMutation, { data, loading, error }] = useShopMenusUpsertShopChoicesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShopMenusUpsertShopChoicesMutation(baseOptions?: Apollo.MutationHookOptions<ShopMenusUpsertShopChoicesMutation, ShopMenusUpsertShopChoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShopMenusUpsertShopChoicesMutation, ShopMenusUpsertShopChoicesMutationVariables>(ShopMenusUpsertShopChoicesDocument, options);
      }
export type ShopMenusUpsertShopChoicesMutationHookResult = ReturnType<typeof useShopMenusUpsertShopChoicesMutation>;
export type ShopMenusUpsertShopChoicesMutationResult = Apollo.MutationResult<ShopMenusUpsertShopChoicesMutation>;
export type ShopMenusUpsertShopChoicesMutationOptions = Apollo.BaseMutationOptions<ShopMenusUpsertShopChoicesMutation, ShopMenusUpsertShopChoicesMutationVariables>;