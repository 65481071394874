import React, { memo } from "react";
import styled from "styled-components";
import { Button, Modal, Typography } from "antd";
import { isEmptyObject } from "util/object";

import { AssignShopCashlessSettingTerminalIdFormValues } from "../useAssignShopCashlessSettingTerminalIdForm";

const Title = styled.h2`
  font-weight: bold;
  font-size: 16px;
`;

type Props = {
  visible: boolean;
  getFieldsValue: () => AssignShopCashlessSettingTerminalIdFormValues;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ReleaseShopCashlessSettingTerminalIdFormConfirmModal = memo<Props>(
  ({ visible, getFieldsValue, onCancel, onConfirm }) => {
    const fieldsValue = getFieldsValue();

    if (!fieldsValue || isEmptyObject(fieldsValue)) return null;

    const { model, serialNumber } = fieldsValue;

    return (
      <Modal
        title={<Title>端末の解除</Title>}
        zIndex={1002}
        centered
        closable={false}
        open={visible}
        footer={
          <>
            <Button onClick={onCancel}>キャンセル</Button>
            <Button type="primary" onClick={onConfirm}>
              解除
            </Button>
          </>
        }
        onCancel={onCancel}
      >
        <Typography.Text>
          端末を解除します。よろしいですか？
          <br />
          解除する端末: {model}-{serialNumber}
        </Typography.Text>
      </Modal>
    );
  },
);
