import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAsyncFn from "react-use/esm/useAsyncFn";
import styled from "styled-components";
import { Alert, Checkbox, TreeSelect } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { RangeValue } from "rc-picker/lib/interface";
import { isNotNullable } from "util/type/primitive";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { useCompany } from "hooks/useCompany";
import { useCorporation } from "hooks/useCorporation";
import { useDinii } from "hooks/useDinii";

import { CsvName } from "../../models/csvName";

import { AggregatedDataForm } from "./AggregatedDataForm";
import { useExportDailyAggregatedDataGetShopsQuery } from "./queries";

const getCondition = ({
  startMoment,
  endMoment,
}: {
  startMoment: dayjs.Dayjs;
  endMoment: dayjs.Dayjs;
}) => {
  const startYear = startMoment.year();
  const startMonth = startMoment.month() + 1;
  const startDate = startMoment.date();

  const endYear = endMoment.year();
  const endMonth = endMoment.month() + 1;
  const endDate = endMoment.date();

  return {
    start: {
      year: startYear,
      month: startMonth,
      date: startDate,
    },
    end: {
      year: endYear,
      month: endMonth,
      date: endDate,
    },
  };
};

const SelectShopsHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SelectShopsTitle = styled.div`
  display: flex;
`;

const StyledCheckbox = styled(Checkbox)`
  align-self: center;
  margin-left: 24px;
`;

const StyledTreeSelect = styled(TreeSelect<string[]>)`
  width: 100%;
  margin-bottom: 16px;
`;

const InputRequiredMark = styled.div`
  color: red;
  margin-right: 4px;
`;

const BATCH_RUN_THRESHOLD = 6;
const BATCH_RUN_CHUNK_SIZE = 5;

const combineData = async (blobs: Blob[]): Promise<Blob> => {
  if (blobs.length === 1 && blobs[0]) return blobs[0];

  const zip = new JSZip();

  for (const blob of blobs) {
    const currentZip = await JSZip.loadAsync(blob);
    currentZip.forEach((path, file) => {
      zip.file(path, file.async("blob"));
    });
  }

  return await zip.generateAsync({ type: "blob" });
};

export const ExportDailyAggregatedDataV2 = () => {
  const [isSelectAllShopsChecked, setIsSelectAllShopsChecked] = useState(true);
  const [selectedShopIds, setSelectedShopIds] = useState<string[]>([]);

  const [dinii, getContext] = useDinii();

  const [corporation] = useCorporation();
  const corporationId = corporation?.corporationId;

  const [company] = useCompany();
  const _companyId = company?.companyId;

  const { data: getShopsData, error } = useExportDailyAggregatedDataGetShopsQuery(
    corporationId ? { variables: { corporationId } } : { skip: true },
  );
  const shops = useMemo(
    () => getShopsData?.corporation[0]?.companies.flatMap(({ shops }) => shops) ?? [],
    [getShopsData],
  );
  const allShopIds = useMemo(() => shops.map(({ shopId }) => shopId), [shops]);

  useEffect(() => {
    setSelectedShopIds(allShopIds);
  }, [allShopIds]);

  const shopTreeData = useMemo(
    () =>
      (
        getShopsData?.corporation[0]?.companies
          .filter(({ shops }) => shops.length > 0)
          .sort((a, b) => a.name.localeCompare(b.name)) ?? []
      ).map((company) => ({
        key: company.id,
        value: company.id,
        title: company.name,
        selectable: true,
        children: company.shops
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((shop) => ({
            key: shop.shopId,
            value: shop.shopId,
            title: shop.name,
            selectable: true,
          })),
      })),
    [getShopsData?.corporation],
  );

  const checkSelectAllShopsBox = useCallback(
    (event: CheckboxChangeEvent) => {
      const value = event.target.checked;

      if (value) {
        setSelectedShopIds(allShopIds);
        setIsSelectAllShopsChecked(true);
      } else {
        setSelectedShopIds([]);
        setIsSelectAllShopsChecked(false);
      }
    },
    [allShopIds],
  );

  const handleShopsSelect = useCallback(
    (shopIds: string[]) => {
      setSelectedShopIds(shopIds);

      if (shopIds.length === allShopIds.length) {
        setIsSelectAllShopsChecked(true);
      } else {
        setIsSelectAllShopsChecked(false);
      }
    },
    [allShopIds.length],
  );

  const [{ loading: isDownloadingAggregatedData }, exportAggregatedData] = useAsyncFn(
    async ({
      dateRange,
      csvNames,
    }: {
      dateRange?: RangeValue<dayjs.Dayjs>;
      csvNames: CsvName[];
    }) => {
      const partialContext = await getContext();

      if (!dateRange || selectedShopIds.length === 0 || csvNames.length === 0 || !partialContext) {
        return;
      }

      const [startMoment, endMoment] = dateRange;
      if (!startMoment || !endMoment) {
        return;
      }

      const condition = getCondition({ startMoment, endMoment });
      if (!condition) return;

      // NOTE: 店舗選択をしていないとリクエストに必要なコンテキストが揃わないので適当に埋める
      // TODO: 本部権限などができたら直したい
      const shopId = selectedShopIds[0];
      const context = partialContext.shopId ? partialContext : { ...partialContext, shopId };

      try {
        const filteredCsvNames = csvNames.filter((name) => name !== "onlinePaymentTransaction");
        const data =
          filteredCsvNames.length !== 0
            ? (
                await dinii.aggregatedData.range.downloadV2(context, {
                  ...condition,
                  shopIds: selectedShopIds,
                  csvNames: filteredCsvNames,
                })
              ).data
            : undefined;

        const gmoTransactionData = csvNames.includes("onlinePaymentTransaction")
          ? await Promise.all(
              selectedShopIds.map(async (shopId) => {
                const response = await dinii.aggregatedData.gmoTransaction.download(context, {
                  ...condition,
                  shopId,
                });
                return response.data;
              }),
            )
          : undefined;
        const combinedData = await combineData([data, gmoTransactionData].filter(isNotNullable));
        saveAs(
          combinedData,
          `${startMoment.format("YYYYMMDD")}_${endMoment.format("YYYYMMDD")}.zip`,
        );

        message.success("ダウンロードが完了しました");
      } catch (err) {
        message.error("不明なエラーが発生しました");
      }
    },
    [dinii.aggregatedData.gmoTransaction, dinii.aggregatedData.range, getContext, selectedShopIds],
  );

  const disabled = selectedShopIds.length === 0;

  return (
    <DashboardLayout title="CSV ダウンロード">
      <PageHeader
        title="CSV ダウンロード"
        description="集計データを CSV 形式でダウンロードできます。"
      />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <FormContent>
        <SelectShopsHeader>
          <SelectShopsTitle>
            <InputRequiredMark>*</InputRequiredMark>
            店舗選択
          </SelectShopsTitle>

          <StyledCheckbox
            defaultChecked
            checked={isSelectAllShopsChecked}
            onChange={checkSelectAllShopsBox}
          >
            全選択
          </StyledCheckbox>
        </SelectShopsHeader>

        <Spacer size={10} />

        <StyledTreeSelect
          treeData={shopTreeData}
          treeDefaultExpandAll
          treeCheckable
          value={selectedShopIds}
          defaultValue={allShopIds}
          onChange={handleShopsSelect}
          maxTagCount={5}
          treeNodeFilterProp="title"
          allowClear
        />

        {_companyId ? (
          <AggregatedDataForm
            isDownloading={isDownloadingAggregatedData}
            onSubmit={exportAggregatedData}
            _companyId={_companyId}
            shops={shops}
            disabled={disabled}
          />
        ) : null}
      </FormContent>
    </DashboardLayout>
  );
};
