import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import { insertWithRelation } from "util/type/insert-with-relation";
import { v4 as uuidv4 } from "uuid";

import { message } from "components/antd/message";
import { PageHeader } from "components/antd/PageHeader";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { useShop } from "hooks/useShop";
import { AddDishUpSlipGroupForm } from "pages/AddDishUpSlipGroup/AddDishUpSlipGroupForm";
import {
  useAddDishUpReceiptGroupGetRolesQuery,
  useAddDishUpReceiptGroupInsertDishUpReceiptGroupMutation,
} from "pages/AddDishUpSlipGroup/queries";
import { DishUpSlipGroupInsertInput, DishUpSlipGroupRolesInsertInput } from "types/graphql";

export const AddDishUpSlipGroup = () => {
  const navigate = useNavigate();
  const [shop] = useShop();
  const shopId = shop?.shopId;
  const [addDishUpSlipGroupMutation, { loading: loadingInsertDishUpSlipGroup }] =
    useAddDishUpReceiptGroupInsertDishUpReceiptGroupMutation();

  const { data: getRolesData, error } = useAddDishUpReceiptGroupGetRolesQuery(
    shopId ? { variables: { shopId } } : { skip: true },
  );
  const roles = getRolesData?.shop_by_pk?.roles ?? [];
  const onSubmit = useCallback(
    async ({
      dishUpSlipGroup: { name },
      dishUpSlipGroupRoles,
    }: {
      dishUpSlipGroup: Omit<DishUpSlipGroupInsertInput, "shopId">;
      dishUpSlipGroupRoles: Omit<
        DishUpSlipGroupRolesInsertInput,
        "dishUpSlipGroupId" | "_dishUpSlipGroupId" | "shopId"
      >[];
    }) => {
      if (!shopId) return;
      try {
        const dishUpSlipGroupId = uuidv4();

        const dishUpGroups = [
          insertWithRelation<["dishUpSlipGroup", "dishUpSlipGroupRoles"]>({
            dishUpSlipGroupId,
            name,
            shopId,
            dishUpSlipGroupRoles: {
              data: dishUpSlipGroupRoles.map(
                (role): Omit<DishUpSlipGroupRolesInsertInput, "shopId" | "_dishUpSlipGroupId"> => ({
                  dishUpSlipGroupId,
                  ...role,
                }),
              ),
            },
          }),
        ];

        const { data: addDishUpSlipGroupData } = await addDishUpSlipGroupMutation({
          variables: { dishUpGroups },
        });

        const dishUpSlipGroupSerialId =
          addDishUpSlipGroupData?.insert_dishUpSlipGroup?.returning?.[0]?.id;

        if (dishUpSlipGroupSerialId === undefined) {
          throw new Error("");
        }

        message.success("作成しました");

        navigate(`/dishUpSlipGroup/${dishUpSlipGroupSerialId}/edit`, { replace: true });
      } catch (err) {
        message.error("作成に失敗しました");
      }
    },
    [addDishUpSlipGroupMutation, navigate, shopId],
  );
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onFormValidationError = useCallback(
    ({ formValidationError: _ }: { formValidationError: ValidateErrorEntity }) => {
      message.error("作成に失敗しました");
    },
    [],
  );

  return (
    <DashboardLayout
      title="デシャップグループを新規作成"
      locationBreadcrumb={{
        showShop: true,
        items: [{ name: "デシャップグループ" }],
      }}
    >
      <PageHeader title="デシャップグループを新規作成" onBack={goBack} />
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      <AddDishUpSlipGroupForm
        onSubmit={onSubmit}
        onClose={goBack}
        onFormValidationError={onFormValidationError}
        roles={roles}
        loading={loadingInsertDishUpSlipGroup}
      />
    </DashboardLayout>
  );
};
