import { CsvNameByShops, CsvNameV2 } from "models/csvName";

export const csvNameMap: Record<CsvNameV2, string> = {
  summary: "売上集計 (日別)",
  orders: "注文一覧 ※集計期間: 最大1ヶ月",
  canceledOrders: "キャンセル注文一覧",
  payments: "支払一覧",
  onlinePaymentTransaction: "オンライン決済取引一覧",
  timeSummary: "売上集計 (会計時間別)",
  visitedTimeSummary: "売上集計 (来店時間別)",
  discounts: "値引・割引一覧",
  withdrawAndDeposit: "入出金一覧",
  orderSummary: "出数集計",
  customerSegment: "来店属性一覧",
  customerInflowSourceTag: "媒体集計",
  kdServedTime: "キッチンディスプレイ提供時間",
  kioskServedTime: "KIOSK提供時間",
  receipt: "領収証発行集計",
  audit: "監査",
  transactions: "取引一覧",
};

export const csvNameByShopsMap: Record<CsvNameByShops, string> = {
  summaryByShops: "日計(日別・店舗統一)",
};

export const csvByShopsDescriptionMap: Record<CsvNameByShops, string> = {
  summaryByShops: "日計集計(日別・店舗統一)を出力します",
};

export const csvByShopsFileNamesMap: Record<CsvNameByShops, string> = {
  summaryByShops: "summaryByShops.csv",
};
