import React, { memo } from "react";
import styled from "styled-components";
import { Col, Row, Switch } from "antd";
import Title from "antd/lib/typography/Title";

import { ExternalLink } from "components/ExternalLink";
import { Spacer } from "components/Spacer";
import { colors } from "constants/colors";
import { tipHelpPageUrl } from "constants/externalLinks";

import { EditShopFormItem } from "../useEditShopForm";

const StyledTitle = styled(Title).attrs({ level: 4 })`
  &.ant-typography {
    margin-bottom: 0px;
  }
`;

const HelperText = styled.span`
  color: ${colors.Text.Secondary};
`;

export const TipConfigFields = memo(() => (
  <div>
    <StyledTitle>チップ設定</StyledTitle>
    <Spacer size={16} />
    <Row justify="start" align="top" gutter={[24, 0]}>
      <Col>
        <EditShopFormItem label="チップ機能を有効にする" name="enableTips" valuePropName="checked">
          <Switch />
        </EditShopFormItem>
        <HelperText>
          チップ機能を有効にすると、モバイルオーダーの会計時に画面に0%、5%、10%、15%、20%、25%のチップ料率が表示され、お客様はその中から任意のチップ料率を選択できます。初めてご利用の際は、
          <ExternalLink externalLinkUrl={tipHelpPageUrl} text="ヘルプサイト" />
          をご確認ください。
        </HelperText>
      </Col>
    </Row>
  </div>
));
