import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const CategoriesGetCategories = gql`
    query CategoriesGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
    plans(order_by: [{priority: asc}, {planId: asc}]) {
      planId
      planName
    }
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      menu {
        menuId
        name
      }
    }
    planMenuCategories(
      where: {plan: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{plan: {priority: asc}}, {_planId: asc}]
    ) {
      plan {
        planId
        planName
      }
    }
  }
}
    `;
export const CategoriesGetCategory = gql`
    query CategoriesGetCategory($companyId: uuid!, $categoryId: Int!) {
  category(
    where: {categoryId: {_eq: $categoryId}, _not: {menuRecommendationMeta: {}}, archivedAt: {_is_null: true}}
  ) {
    categoryId
    name
    shopSideName
    plans(order_by: [{priority: asc}, {planId: asc}]) {
      planId
      planName
    }
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      menu {
        menuId
        name
      }
    }
    planMenuCategories(
      where: {plan: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{plan: {priority: asc}}, {_planId: asc}]
    ) {
      plan {
        planId
        planName
      }
    }
  }
}
    `;
export const CategoriesDeleteCategory = gql`
    mutation CategoriesDeleteCategory($categoryId: Int!, $archivedAt: timestamptz!) {
  update_category(
    _set: {archivedAt: $archivedAt}
    where: {categoryId: {_eq: $categoryId}}
  ) {
    affected_rows
  }
}
    `;
export type CategoriesGetCategoriesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type CategoriesGetCategoriesQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
    & { plans: Array<(
      { __typename?: 'plan' }
      & Pick<Types.Plan, 'planId' | 'planName'>
    )>, categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'menuId' | 'name'>
      ) }
    )>, planMenuCategories: Array<(
      { __typename?: 'planMenuCategory' }
      & { plan: (
        { __typename?: 'plan' }
        & Pick<Types.Plan, 'planId' | 'planName'>
      ) }
    )> }
  )> }
);

export type CategoriesGetCategoryQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  categoryId: Types.Scalars['Int'];
}>;


export type CategoriesGetCategoryQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
    & { plans: Array<(
      { __typename?: 'plan' }
      & Pick<Types.Plan, 'planId' | 'planName'>
    )>, categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'menuId' | 'name'>
      ) }
    )>, planMenuCategories: Array<(
      { __typename?: 'planMenuCategory' }
      & { plan: (
        { __typename?: 'plan' }
        & Pick<Types.Plan, 'planId' | 'planName'>
      ) }
    )> }
  )> }
);

export type CategoriesDeleteCategoryMutationVariables = Types.Exact<{
  categoryId: Types.Scalars['Int'];
  archivedAt: Types.Scalars['timestamptz'];
}>;


export type CategoriesDeleteCategoryMutation = (
  { __typename?: 'mutation_root' }
  & { update_category?: Types.Maybe<(
    { __typename?: 'category_mutation_response' }
    & Pick<Types.CategoryMutationResponse, 'affected_rows'>
  )> }
);


export const CategoriesGetCategoriesDocument = gql`
    query CategoriesGetCategories($companyId: uuid!) {
  category(
    order_by: [{priority: asc}, {categoryId: asc}]
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
  ) {
    categoryId
    name
    shopSideName
    plans(order_by: [{priority: asc}, {planId: asc}]) {
      planId
      planName
    }
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      menu {
        menuId
        name
      }
    }
    planMenuCategories(
      where: {plan: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{plan: {priority: asc}}, {_planId: asc}]
    ) {
      plan {
        planId
        planName
      }
    }
  }
}
    `;

/**
 * __useCategoriesGetCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesGetCategoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCategoriesGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<CategoriesGetCategoriesQuery, CategoriesGetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesGetCategoriesQuery, CategoriesGetCategoriesQueryVariables>(CategoriesGetCategoriesDocument, options);
      }
export function useCategoriesGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesGetCategoriesQuery, CategoriesGetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesGetCategoriesQuery, CategoriesGetCategoriesQueryVariables>(CategoriesGetCategoriesDocument, options);
        }
export type CategoriesGetCategoriesQueryHookResult = ReturnType<typeof useCategoriesGetCategoriesQuery>;
export type CategoriesGetCategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesGetCategoriesLazyQuery>;
export type CategoriesGetCategoriesQueryResult = Apollo.QueryResult<CategoriesGetCategoriesQuery, CategoriesGetCategoriesQueryVariables>;
export const CategoriesGetCategoryDocument = gql`
    query CategoriesGetCategory($companyId: uuid!, $categoryId: Int!) {
  category(
    where: {categoryId: {_eq: $categoryId}, _not: {menuRecommendationMeta: {}}, archivedAt: {_is_null: true}}
  ) {
    categoryId
    name
    shopSideName
    plans(order_by: [{priority: asc}, {planId: asc}]) {
      planId
      planName
    }
    categoryMenus(
      where: {menu: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}]}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      menu {
        menuId
        name
      }
    }
    planMenuCategories(
      where: {plan: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}}}
      order_by: [{plan: {priority: asc}}, {_planId: asc}]
    ) {
      plan {
        planId
        planName
      }
    }
  }
}
    `;

/**
 * __useCategoriesGetCategoryQuery__
 *
 * To run a query within a React component, call `useCategoriesGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesGetCategoryQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useCategoriesGetCategoryQuery(baseOptions: Apollo.QueryHookOptions<CategoriesGetCategoryQuery, CategoriesGetCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoriesGetCategoryQuery, CategoriesGetCategoryQueryVariables>(CategoriesGetCategoryDocument, options);
      }
export function useCategoriesGetCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesGetCategoryQuery, CategoriesGetCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoriesGetCategoryQuery, CategoriesGetCategoryQueryVariables>(CategoriesGetCategoryDocument, options);
        }
export type CategoriesGetCategoryQueryHookResult = ReturnType<typeof useCategoriesGetCategoryQuery>;
export type CategoriesGetCategoryLazyQueryHookResult = ReturnType<typeof useCategoriesGetCategoryLazyQuery>;
export type CategoriesGetCategoryQueryResult = Apollo.QueryResult<CategoriesGetCategoryQuery, CategoriesGetCategoryQueryVariables>;
export const CategoriesDeleteCategoryDocument = gql`
    mutation CategoriesDeleteCategory($categoryId: Int!, $archivedAt: timestamptz!) {
  update_category(
    _set: {archivedAt: $archivedAt}
    where: {categoryId: {_eq: $categoryId}}
  ) {
    affected_rows
  }
}
    `;
export type CategoriesDeleteCategoryMutationFn = Apollo.MutationFunction<CategoriesDeleteCategoryMutation, CategoriesDeleteCategoryMutationVariables>;

/**
 * __useCategoriesDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useCategoriesDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCategoriesDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [categoriesDeleteCategoryMutation, { data, loading, error }] = useCategoriesDeleteCategoryMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      archivedAt: // value for 'archivedAt'
 *   },
 * });
 */
export function useCategoriesDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CategoriesDeleteCategoryMutation, CategoriesDeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CategoriesDeleteCategoryMutation, CategoriesDeleteCategoryMutationVariables>(CategoriesDeleteCategoryDocument, options);
      }
export type CategoriesDeleteCategoryMutationHookResult = ReturnType<typeof useCategoriesDeleteCategoryMutation>;
export type CategoriesDeleteCategoryMutationResult = Apollo.MutationResult<CategoriesDeleteCategoryMutation>;
export type CategoriesDeleteCategoryMutationOptions = Apollo.BaseMutationOptions<CategoriesDeleteCategoryMutation, CategoriesDeleteCategoryMutationVariables>;