import * as Types from '../../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const AddKitchenDisplayDisplayTargetMenuModalGetMenus = gql`
    query AddKitchenDisplayDisplayTargetMenuModalGetMenus($companyId: uuid!, $shopId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
    categoryMenus(
      where: {menu: {shopMenus: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}], archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      menu {
        id
        menuId
        name
      }
    }
  }
}
    `;
export const AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenus = gql`
    mutation AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenus($kdDisplayTargetId: uuid, $kdDisplayTargetMenus: [shopMenuKdDisplayTarget_insert_input!]!) {
  delete_shopMenuKdDisplayTarget(
    where: {kdDisplayTargetId: {_eq: $kdDisplayTargetId}}
  ) {
    affected_rows
  }
  insert_shopMenuKdDisplayTarget(
    objects: $kdDisplayTargetMenus
    on_conflict: {constraint: shopMenuKdDisplayTarget_menuId_kdDisplayTargetId_key, update_columns: []}
  ) {
    affected_rows
    returning {
      kdDisplayTargetId
    }
  }
}
    `;
export type AddKitchenDisplayDisplayTargetMenuModalGetMenusQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
  shopId: Types.Scalars['uuid'];
}>;


export type AddKitchenDisplayDisplayTargetMenuModalGetMenusQuery = (
  { __typename?: 'query_root' }
  & { category: Array<(
    { __typename?: 'category' }
    & Pick<Types.Category, 'categoryId' | 'name' | 'shopSideName'>
    & { categoryMenus: Array<(
      { __typename?: 'categoryMenu' }
      & { menu: (
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'id' | 'menuId' | 'name'>
      ) }
    )> }
  )> }
);

export type AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutationVariables = Types.Exact<{
  kdDisplayTargetId?: Types.InputMaybe<Types.Scalars['uuid']>;
  kdDisplayTargetMenus: Array<Types.ShopMenuKdDisplayTargetInsertInput> | Types.ShopMenuKdDisplayTargetInsertInput;
}>;


export type AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation = (
  { __typename?: 'mutation_root' }
  & { delete_shopMenuKdDisplayTarget?: Types.Maybe<(
    { __typename?: 'shopMenuKdDisplayTarget_mutation_response' }
    & Pick<Types.ShopMenuKdDisplayTargetMutationResponse, 'affected_rows'>
  )>, insert_shopMenuKdDisplayTarget?: Types.Maybe<(
    { __typename?: 'shopMenuKdDisplayTarget_mutation_response' }
    & Pick<Types.ShopMenuKdDisplayTargetMutationResponse, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'shopMenuKdDisplayTarget' }
      & Pick<Types.ShopMenuKdDisplayTarget, 'kdDisplayTargetId'>
    )> }
  )> }
);


export const AddKitchenDisplayDisplayTargetMenuModalGetMenusDocument = gql`
    query AddKitchenDisplayDisplayTargetMenuModalGetMenus($companyId: uuid!, $shopId: uuid!) {
  category(
    where: {companyId: {_eq: $companyId}, archivedAt: {_is_null: true}, _not: {menuRecommendationMeta: {}}}
    order_by: [{priority: asc}, {categoryId: asc}]
  ) {
    categoryId
    name
    shopSideName
    categoryMenus(
      where: {menu: {shopMenus: {shopId: {_eq: $shopId}, archivedAt: {_is_null: true}, shop: {archivedAt: {_is_null: true}}}, _and: [{_not: {giftMeta: {}}}, {_not: {tipMenuMeta: {}}}], archivedAt: {_is_null: true}}}
      order_by: [{priority: asc}, {_menuId: asc}]
    ) {
      menu {
        id
        menuId
        name
      }
    }
  }
}
    `;

/**
 * __useAddKitchenDisplayDisplayTargetMenuModalGetMenusQuery__
 *
 * To run a query within a React component, call `useAddKitchenDisplayDisplayTargetMenuModalGetMenusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddKitchenDisplayDisplayTargetMenuModalGetMenusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddKitchenDisplayDisplayTargetMenuModalGetMenusQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useAddKitchenDisplayDisplayTargetMenuModalGetMenusQuery(baseOptions: Apollo.QueryHookOptions<AddKitchenDisplayDisplayTargetMenuModalGetMenusQuery, AddKitchenDisplayDisplayTargetMenuModalGetMenusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddKitchenDisplayDisplayTargetMenuModalGetMenusQuery, AddKitchenDisplayDisplayTargetMenuModalGetMenusQueryVariables>(AddKitchenDisplayDisplayTargetMenuModalGetMenusDocument, options);
      }
export function useAddKitchenDisplayDisplayTargetMenuModalGetMenusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddKitchenDisplayDisplayTargetMenuModalGetMenusQuery, AddKitchenDisplayDisplayTargetMenuModalGetMenusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddKitchenDisplayDisplayTargetMenuModalGetMenusQuery, AddKitchenDisplayDisplayTargetMenuModalGetMenusQueryVariables>(AddKitchenDisplayDisplayTargetMenuModalGetMenusDocument, options);
        }
export type AddKitchenDisplayDisplayTargetMenuModalGetMenusQueryHookResult = ReturnType<typeof useAddKitchenDisplayDisplayTargetMenuModalGetMenusQuery>;
export type AddKitchenDisplayDisplayTargetMenuModalGetMenusLazyQueryHookResult = ReturnType<typeof useAddKitchenDisplayDisplayTargetMenuModalGetMenusLazyQuery>;
export type AddKitchenDisplayDisplayTargetMenuModalGetMenusQueryResult = Apollo.QueryResult<AddKitchenDisplayDisplayTargetMenuModalGetMenusQuery, AddKitchenDisplayDisplayTargetMenuModalGetMenusQueryVariables>;
export const AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusDocument = gql`
    mutation AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenus($kdDisplayTargetId: uuid, $kdDisplayTargetMenus: [shopMenuKdDisplayTarget_insert_input!]!) {
  delete_shopMenuKdDisplayTarget(
    where: {kdDisplayTargetId: {_eq: $kdDisplayTargetId}}
  ) {
    affected_rows
  }
  insert_shopMenuKdDisplayTarget(
    objects: $kdDisplayTargetMenus
    on_conflict: {constraint: shopMenuKdDisplayTarget_menuId_kdDisplayTargetId_key, update_columns: []}
  ) {
    affected_rows
    returning {
      kdDisplayTargetId
    }
  }
}
    `;
export type AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutationFn = Apollo.MutationFunction<AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation, AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutationVariables>;

/**
 * __useAddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation__
 *
 * To run a mutation, you first call `useAddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation, { data, loading, error }] = useAddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation({
 *   variables: {
 *      kdDisplayTargetId: // value for 'kdDisplayTargetId'
 *      kdDisplayTargetMenus: // value for 'kdDisplayTargetMenus'
 *   },
 * });
 */
export function useAddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation(baseOptions?: Apollo.MutationHookOptions<AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation, AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation, AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutationVariables>(AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusDocument, options);
      }
export type AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutationHookResult = ReturnType<typeof useAddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation>;
export type AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutationResult = Apollo.MutationResult<AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation>;
export type AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutationOptions = Apollo.BaseMutationOptions<AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutation, AddKitchenDisplayDisplayTargetMenuModalInsertKdDisplayTargetMenusMutationVariables>;